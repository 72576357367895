import { gql } from '@apollo/client';
import { FactLigneRmxFields, FactLigneRmxDetailsFields } from 'apollo/fragments';

export const GET_FACTURE_COMPLETE = gql`
    ${FactLigneRmxFields}
    ${FactLigneRmxDetailsFields}

    query GetFacture($reqt_id: Int) {
        facture(reqt_id: $reqt_id) {
            nce
            revision

            FactLignesRmx {
                ...FactLigneRmxFields

                FactLigneRmxDetails {
                    ...FactLigneRmxDetailsFields
                }
            }
        }
    }
`;

export const GET_FACTURE_DAILY_COMPLETE = gql`
    ${FactLigneRmxFields}
    ${FactLigneRmxDetailsFields}

    query GetFacture($reqt_id: Int) {
        facture(reqt_id: $reqt_id) {
            nce
            revision

            FactLignesRmx {
                ...FactLigneRmxFields

                FactLigneRmxDetails {
                    ...FactLigneRmxDetailsFields
                }
            }
        }
    }
`;
