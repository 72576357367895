import React from 'react';
import { TextField } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, FormState, useController, UseControllerProps } from 'react-hook-form';
import moment from 'moment';
import { SQL_DATE } from 'constants/Moment';
import { DatePicker } from '@mui/x-date-pickers';
import { getHelperText } from '.';

interface DateControlProps {
    name: string;
    control: Control<any, any>;
    rules?: UseControllerProps['rules'];
    options?: { label?: string; suffix?: string };
}

export function DateControl({ name, control, rules, options = {} }: DateControlProps) {
    const { label, suffix } = options;

    const {
        field: { onChange, value },
        fieldState: { invalid, error },
    } = useController({ name, control, rules });

    const [formattedValue, setFormattedValue] = useState<Date | string | null>(moment(value, SQL_DATE).toISOString());

    useEffect(() => {
        setFormattedValue(moment(value, SQL_DATE).toISOString());
    }, [value]);

    return (
        <DatePicker
            // views={['day', 'month', 'year']}
            views={['year', 'month', 'day']}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    fullWidth
                    {...options}
                    error={invalid}
                    helperText={getHelperText(error)}
                />
            )}
            value={formattedValue}
            onChange={(newValue) => {
                onChange(moment(newValue)?.format(SQL_DATE) || null);
                setFormattedValue(newValue);
            }}
        />
    );
}
