import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'use-debounce';

// MUI Components
import {
    Breadcrumbs,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    Autocomplete,
    Box,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useMediaQuery } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';

// MUI Grid

// MUI Icons
import { NavigateNext as NavigateNextIcon, Clear as ClearIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import FactSmTabPanel from './FactSmTabPanel/index';
import FactVacTabPanel from './FactVacTabPanel';
import FactRmxTabPanel from './FactRmxTabPanel';
import FactFdTabPanel from './FactFdTabPanel';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { GET_ECS, GET_POSTES, GET_TYPES_SERVICE } from './apollo-queries';

// Utils
import { capitalize } from 'utils';

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { TextControl } from 'components/inputs';
import { useForm, useWatch } from 'react-hook-form';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Réclamations
    </Typography>,
];

const STATUT_PAIEMENT = [
    {
        value: 'canceled',
        nom: 'Annulé',
    },
    {
        value: 'payable',
        nom: 'Payable',
    },
    {
        value: 'paid',
        nom: 'Payé',
    },
];

const inputGridXl = 2.4;
const inputGridLg = 2.4;
const inputGridMd = 4;
const inputGridSm = 6;
const inputGridXs = 12;

export default function FactureList() {
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    const setSearchParamsWrapper = (prop: string, val: any) => {
        if (val) searchParams.set(prop, val.toString());
        else searchParams.delete(prop);

        setSearchParams(searchParams);
    };

    // Form
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            patient_nam: '',
        },
    });

    // * Filtering logic

    // Filter - Created at
    const search_created_at = searchParams.get('created_at');
    const [createdAtFilter, setCreatedAtFilter] = useState<Moment | null>(
        search_created_at ? moment(search_created_at) : null,
    );
    const handleChangeCreatedAt = (value) => {
        setCreatedAtFilter(value);
    };

    // Filter - Date service
    const search_date_service = searchParams.get('date_service');
    const [dateVisiteFilter, setDateVisiteFilter] = useState<Moment | null>(
        search_date_service ? moment(search_date_service) : null,
    );
    const handleChangeDateVisite = (value) => {
        setDateVisiteFilter(value);
        setSearchParamsWrapper('date_service', value);
    };
    const handleClearDateVisite = () => {
        setDateVisiteFilter(null);
        setSearchParamsWrapper('date_service', null);
    };

    // Filter - Patient NAM
    const search_patient_nam = searchParams.get('patient_nam');
    const [patientNamFilter, setPatientNamFilter] = useState<string>(search_patient_nam ? search_patient_nam : '');
    const handleChangePatientNam = (e) => {
        const nam = e.target.value;

        setPatientNamFilter(nam);
    };
    const [patientNamFilterDebounced] = useDebounce(patientNamFilter, 750);
    // useEffect(() => setSearchParamsWrapper('patient_nam', patientNamFilterDebounced), [patientNamFilterDebounced]);

    // Filter - Ec ID
    const search_ec_id = searchParams.get('ec_id');
    const [ecIdFilter, setEcIdFilter] = useState<number | null>(search_ec_id ? parseInt(search_ec_id) : null);
    const handleChangeEcId = (e, value: any) => {
        const ecId = value?.id;

        setEcIdFilter(ecId || null);
        setSearchParamsWrapper('ec_id', ecId);
    };

    // Filter - Poste ID
    const search_poste_id = searchParams.get('poste_id');
    const [posteIdFilter, setPosteIdFilter] = useState<string | null>(search_poste_id ? search_poste_id : null);
    const handleChangePosteId = (e, value: any) => {
        // const posteId = value?.numero;
        const posteId = pstInputValueGetter(value);

        setPosteIdFilter(posteId || null);
        setSearchParamsWrapper('poste_id', posteId);
    };

    // // Filter - Type service
    // const search_type_service_id = searchParams.get('type_service_id');
    // const [typeServiceIdFilter, setTypeServiceIdFilter] = useState<number | null>(
    //     search_type_service_id ? parseInt(search_type_service_id) : null,
    // );
    // const handleChangeTypeServiceId = (e) => {
    //     setTypeServiceIdFilter(e.target.value);
    // };

    // Filter - Paid
    const search_payment_status = searchParams.get('payment_status');
    const [paymentStatusFilter, setPaymentStatusFilter] = useState<string | null>(
        search_payment_status ? search_payment_status : null,
    );
    const handleChangePaymentStatus = (e) => {
        const paymentStatus = e.target.value;

        setPaymentStatusFilter(paymentStatus);
        setSearchParamsWrapper('payment_status', paymentStatus);
    };

    // Queries
    const { loading: ecsLoading, error: ecsError, data: ecsData } = useQuery(GET_ECS);
    const { loading: postesLoading, error: postesError, data: postesData } = useQuery(GET_POSTES);
    const {
        loading: typesServiceLoading,
        error: typesServiceError,
        data: typesServiceData,
    } = useQuery(GET_TYPES_SERVICE);

    // Tabs
    const [tabIndex, setTabIndex] = React.useState('fact-sm');
    const onTabChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    }, []);

    const factSmFilters = useMemo(
        () => ({
            created_at: createdAtFilter,
            date_service: dateVisiteFilter,
            patient_nam: patientNamFilterDebounced,
            ec_id: ecIdFilter,
            pst_id: posteIdFilter,
            payment_status: paymentStatusFilter,
        }),
        [createdAtFilter, dateVisiteFilter, patientNamFilterDebounced, ecIdFilter, posteIdFilter, paymentStatusFilter],
    );

    const factFdFilters = useMemo(
        () => ({
            created_at: createdAtFilter,
            date_service: dateVisiteFilter,
            ec_id: ecIdFilter,
            pst_id: posteIdFilter,
            payment_status: paymentStatusFilter,
        }),
        [createdAtFilter, dateVisiteFilter, ecIdFilter, posteIdFilter, paymentStatusFilter],
    );

    const factVacFilters = useMemo(
        () => ({
            created_at: createdAtFilter,
            date_service: dateVisiteFilter,
            ec_id: ecIdFilter,
            pst_id: posteIdFilter,
            payment_status: paymentStatusFilter,
        }),
        [createdAtFilter, dateVisiteFilter, ecIdFilter, posteIdFilter, paymentStatusFilter],
    );

    const factRmxFilters = useMemo(
        () => ({
            created_at: createdAtFilter,
            date_service: dateVisiteFilter,
            ec_id: ecIdFilter,
            pst_id: posteIdFilter,
            payment_status: paymentStatusFilter,
        }),
        [createdAtFilter, dateVisiteFilter, ecIdFilter, posteIdFilter, paymentStatusFilter],
    );

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Recherche de réclamations
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 0, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <Grid container spacing={3} mb={1.5}>
                {/* <Grid item xs={3}>
                    <TextField
                        value={ecIdFilter || ''}
                        onChange={(e) => setEcIdFilter(e.target.value ? parseInt(e.target.value) : null)}
                        label="ID ec"
                        type="number"
                        size="small"
                    ></TextField>
                </Grid> */}
                {/* <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <DatePicker
                        label="Date de transmission"
                        value={createdAtFilter}
                        onChange={handleChangeCreatedAt}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                    />
                </Grid> */}
                {/* <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateRangePicker
                            startText="Début"
                            endText="Fin"
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} size="small" />
                                    <Box sx={{ mx: 2 }}> à </Box>
                                    <TextField {...endProps} size="small" />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </Grid> */}
                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <DatePicker
                        label={'Date de service'}
                        value={dateVisiteFilter}
                        onChange={handleChangeDateVisite}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                        inputFormat={SQL_DATE}
                        mask="____-__-__"
                        InputProps={{
                            endAdornment: dateVisiteFilter && (
                                <IconButton onClick={handleClearDateVisite} size="small" sx={{ mr: -1 }}>
                                    <ClearIcon />
                                </IconButton>
                            ),
                        }}
                        InputAdornmentProps={{
                            position: 'start',
                        }}
                        // clearable
                    />
                </Grid>
                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <TextField
                        label="NAM patient"
                        value={patientNamFilter}
                        onChange={handleChangePatientNam}
                        size="small"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <Autocomplete
                        // disablePortal
                        options={postesData?.postes || []}
                        loading={postesLoading}
                        getOptionLabel={(option: any) => {
                            const { numero, modulateur, description } = option;

                            let display = true;

                            let numeroProcessed = numero;
                            let descriptionProcessed = description;

                            if (option === posteIdFilter) {
                                const selectedPoste = (postesData?.postes || []).find(
                                    (poste) => pstInputValueGetter(poste) === option,
                                );

                                if (selectedPoste) {
                                    numeroProcessed = selectedPoste.numero;
                                    descriptionProcessed = selectedPoste.description;
                                } else {
                                    display = false;
                                }
                            }

                            return display ? `${descriptionProcessed} (${numeroProcessed}-${modulateur})` : '';
                        }}
                        value={posteIdFilter}
                        onChange={handleChangePosteId}
                        // isOptionEqualToValue={(option: any, value) => option?.numero === value}
                        isOptionEqualToValue={(option: any, value) => pstInputValueGetter(option) === value}
                        renderInput={(params) => <TextField {...params} size="small" fullWidth label="Poste" />}
                    />
                </Grid>
                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <TextField
                        select
                        label="Statut de paiement"
                        value={paymentStatusFilter}
                        onChange={handleChangePaymentStatus}
                        size="small"
                        fullWidth
                    >
                        <MenuItem value={null as any}>Tous</MenuItem>
                        {STATUT_PAIEMENT.map((option, idx) => (
                            <MenuItem key={idx} value={option.value}>
                                {option.nom}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {/* <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <TextField
                        select
                        label="Type de rémunération"
                        value={typeServiceIdFilter}
                        onChange={handleChangeTypeServiceId}
                        size="small"
                        fullWidth
                    >
                        <MenuItem value={null as any}>Tous</MenuItem>
                        {(typesServiceData?.typesService || []).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.nom}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid> */}
                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <Autocomplete
                        // disablePortal
                        options={ecsData?.ecs || []}
                        loading={ecsLoading}
                        getOptionLabel={(option: any) => {
                            const { id, no_paiement, date_ec, date_coupure } = option;

                            if (option === ecIdFilter) {
                                const selectedEc = (ecsData?.ecs || []).find((ec) => ec.id === option);

                                return selectedEc
                                    ? `${moment(selectedEc.date_ec).format(SQL_DATE)}  (OR-${selectedEc.no_paiement})`
                                    : '';
                            } else {
                                return `${moment(date_ec).format(SQL_DATE)}  (OR-${no_paiement})`;
                            }
                        }}
                        value={ecIdFilter}
                        onChange={handleChangeEcId}
                        isOptionEqualToValue={(option: any, value) => option?.id === value}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                fullWidth
                                label="État de compte"
                                helperText="Date du dernier EC dans lequel la facture est apparue"
                            />
                        )}
                        groupBy={(option) => `Année ${moment(option.date_ec).format('YYYY')}`}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <Button
                        onClick={() =>
                            getLots({
                                variables: {
                                    ecId: ecIdFilter,
                                },
                            })
                        }
                    >
                        Fetch
                    </Button>
                </Grid> */}
            </Grid>

            <Grid container spacing={3} mb={1.5}>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle2"
                        sx={(theme) => ({
                            color: theme.palette.info.main,
                        })}
                    >
                        ** Pour afficher le détail d'une facture, veuillez cliquer sur la flèche à la droite du NCE.
                    </Typography>
                    {/* <Typography
                        variant="subtitle2"
                        sx={(theme) => ({
                            color: theme.palette.warning.main,
                        })}
                    >
                        ** L’affichage complet du tarif horaire, salariat et rémunération mixte sont présentement en
                        développement. Merci de votre compréhension.
                    </Typography> */}
                </Grid>
            </Grid>

            <FactTabs
                tabIndex={tabIndex}
                onTabChange={onTabChange}
                factSmFilters={factSmFilters}
                factFdFilters={factFdFilters}
                factVacFilters={factVacFilters}
                factRmxFilters={factRmxFilters}
            />
        </>
    );
}

const FactTabs = React.memo((props: any) => {
    const { tabIndex, onTabChange, factSmFilters, factFdFilters, factVacFilters, factRmxFilters } = props;

    return (
        <TabContext value={tabIndex}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 0 }}>
                <TabList
                    onChange={onTabChange}
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                >
                    <Tab label="Services Médicaux" value="fact-sm" />
                    <Tab label="Frais de déplacement" value="fact-fd" />
                    <Tab label="Vacation / Tarif horaire" value="fact-vac" />
                    <Tab label="Rémunération mixte" value="fact-rmx" />
                </TabList>
            </Box>

            <>
                <FactSmTabPanel tabIndexAsString="fact-sm" filters={factSmFilters} />

                <FactFdTabPanel tabIndexAsString="fact-fd" filters={factFdFilters} />

                <FactVacTabPanel tabIndexAsString="fact-vac" filters={factVacFilters} />

                <FactRmxTabPanel tabIndexAsString="fact-rmx" filters={factRmxFilters} />
            </>
        </TabContext>
    );
});

const pstInputValueGetter = (option) => (option ? `${option.numero}-${option.modulateur}` : null);
