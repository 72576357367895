import React from 'react';
import { TextField } from '@mui/material';
import { Control, FormState, useController } from 'react-hook-form';

interface TextControlProps {
    name: string;
    control: Control<any, any>;
    errors?: FormState<any>['errors'];
    options?: { label?: string; helperText?: string; multiline?: boolean; rows?: number };
}

export function TextControl({ name, control, errors, options = {} }: TextControlProps) {
    let { label, multiline, rows } = options;

    if (rows || rows === 0) {
        multiline = true;
    }

    const {
        field: { value, onChange },
    } = useController({ name, control });

    return (
        <TextField
            label={label || ''}
            size="small"
            fullWidth
            onChange={(e) => {
                const newValue = e.target.value || null;
                onChange(newValue);
            }}
            value={value || ''}
            {...options}
            multiline={multiline}
        />
    );
}
