import { gql } from '@apollo/client';

export const FRAGMENTS = gql`
    fragment FactLigneFields on FactLigne {
        ligneno
        nce
        revision
        rank
        mdgrppstno
        typelmfact
        codefact
        datefact
        coderole
        debutelmfact
        finelmfact
        coderef
        mntprelim
        mntfinal
        statrecv
    }
`;
