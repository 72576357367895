import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';

export const GET_FACT_FDS = gql`
    query GetFactFds($created_at: Date, $date_service: Date, $ec_id: Int, $pst_id: String, $payment_status: String) {
        factFds(
            created_at: $created_at
            date_service: $date_service
            ec_id: $ec_id
            pst_id: $pst_id
            payment_status: $payment_status
        ) {
            created_at
            nce
            nci
            revision
            reqt_id
            licence
            statut_recv
            is_canceled
            lot_id
            fmp_ec_id
            ec_id

            dh_depart
            pst_depart_type_id
            pst_depart_id
            dh_arrivee
            pst_arrivee_type_id
            pst_arrivee_id

            date_service_min
            date_service_max
            date_service_agg

            line_count
            total_prelim
            total_final
            total_diff

            date_dernier_paiement

            payment_status

            PAYMENT_STATUS_NAME
        }
    }
`;
