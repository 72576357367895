import React from 'react';
import { Routes, Route, Link, useLocation, Location } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers/LocalizationProvider';
import { useNavigate, Navigate, NavigateFunction } from 'react-router';

import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles';
import { green, purple } from '@mui/material/colors';
import moment from 'moment-timezone';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/fr';
import { LicenseInfo } from '@mui/x-license-pro';

// Components

// Redux
import { Provider as ReduxProvider } from 'react-redux';
import store from 'redux/store';
import { useAppSelector, useAppDispatch } from 'redux/hooks';

// Contexts
import { useAuth } from 'providers/AuthProvider/context';

// Services
import { Api, Auth } from 'services';

// Providers
import AuthProvider from 'providers/AuthProvider';

// Router
import Router, { LOGIN_ROUTE } from 'router';
import Graphql from 'services/Graphql';

declare module '@mui/material/styles' {
    interface Palette {
        badge: Palette['primary'];

        blue1: Palette['primary'];
        blue1Dark: Palette['primary'];
        blue1Light: Palette['primary'];

        pink1: Palette['primary'];
        pink1Dark: Palette['primary'];
        pink1Light: Palette['primary'];

        beige1: Palette['primary'];
        beige1Dark: Palette['primary'];
        beige1Light: Palette['primary'];

        green1: Palette['primary'];
        green1Dark: Palette['primary'];
        green1Light: Palette['primary'];

        yellow1: Palette['primary'];
        yellow1Dark: Palette['primary'];
        yellow1Light: Palette['primary'];

        red1: Palette['primary'];
        red1Dark: Palette['primary'];
        red1Light: Palette['primary'];

        gray1: Palette['primary'];
        gray1Dark: Palette['primary'];
        gray1Light: Palette['primary'];
    }
    interface PaletteOptions {
        badge?: PaletteOptions['primary'];

        blue?: PaletteOptions['primary'];
        pink?: PaletteOptions['primary'];

        blue1?: PaletteOptions['primary'];
        blue1Dark?: PaletteOptions['primary'];
        blue1Light?: PaletteOptions['primary'];

        pink1?: PaletteOptions['primary'];
        pink1Dark?: PaletteOptions['primary'];
        pink1Light?: PaletteOptions['primary'];

        beige1?: PaletteOptions['primary'];
        beige1Dark?: PaletteOptions['primary'];
        beige1Light?: PaletteOptions['primary'];

        green1?: PaletteOptions['primary'];
        green1Dark?: PaletteOptions['primary'];
        green1Light?: PaletteOptions['primary'];

        yellow1?: PaletteOptions['primary'];
        yellow1Dark?: PaletteOptions['primary'];
        yellow1Light?: PaletteOptions['primary'];

        red1?: PaletteOptions['primary'];
        red1Dark?: PaletteOptions['primary'];
        red1Light?: PaletteOptions['primary'];

        gray1?: PaletteOptions['primary'];
        gray1Dark?: PaletteOptions['primary'];
        gray1Light?: PaletteOptions['primary'];
    }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        blue1: true;
    }
}

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true; // removes the `xs` breakpoint
        sm: true;
        md: true;
        lg: true;
        xl: true;
        mobile: true; // adds the `mobile` breakpoint
        tablet: true;
        laptop: true;
        desktop: true;
    }
}

// declare module '@mui/material/SvgIcon' {
//     interface SvgIconPropsColorOverrides {
//         ferubbgureioubiuoergabigeraub: string;
//     }
// }

// declare module '@mui/material/Icon' {
//     interface IconPropsColorOverrides {
//         ferubbgureioubiuoergabigeraub: string;
//     }
// }

// declare module '@mui/material/IconButton' {
//     interface IconButtonPropsColorOverrides {
//         ferubbgureioubiuoergabigeraub: string;
//     }
// }

const LIGHT_BLUE_DEFAULT = 'rgb(240,240,245)';
const LIGHT_BLUE_PAPER = 'rgb(245,245,250)';

const LIGHT_BLUE_TEXT_PRIMARY = 'rgb(70,70,80, 0.87)';
const LIGHT_BLUE_TEXT_SECONDARY = 'rgb(70,70,80, 0.6)';
const LIGHT_BLUE_TEXT_DISABLED = 'rgb(70,70,80, 0.38)';

export const THEME: ThemeOptions = {
    typography: {
        // Tell MUI what's the font-size on the html element is.
        // htmlFontSize: 16,
        fontSize: 12.5,
        h4: {
            fontSize: '1.35rem',
        },
        h5: {
            fontSize: '1.05rem',
        },
        h6: {
            fontSize: '0.95rem',
        },
    },
    palette: {
        // mode: 'light',

        primary: {
            // Green FMParadis
            main: '#84bc00',
            contrastText: '#ffffff',

            // Beige
            // main: '#bf7a4c',
            // contrastText: '#ffffff',

            // Green v2
            // main: '#83bf4c',
            // contrastText: '#ffffff',

            // Blue
            // main: '#4caabf',
            // contrastText: '#ffffff',

            // Yellow/Orange
            // main: '#CC5500',
            // contrastText: '#ffffff',
        },
        secondary: {
            light: '#0066ff',

            // main: '#0044ff',
            // contrastText: '#ffcc00',

            // Blue
            // main: '#4caabf',
            // contrastText: '#ffffff',

            // Yellow/Orange
            // main: '#bf9a4c',
            // contrastText: '#ffffff',

            // Beige
            // main: '#bf7a4c',
            // contrastText: '#ffffff',

            // Black
            main: 'rgb(30,30,30)',
            contrastText: '#ffffff',

            // dark: will be calculated from palette.secondary.main,
        },
        warning: {
            // main: '#ffc107',
            main: '#bf9a4c',
        },
        error: {
            main: '#dc3545',
        },
        success: {
            main: '#28a745',
        },
        info: {
            main: '#17a2b8',
        },

        badge: { main: 'rgb(45,45,45)' },

        blue: { main: '#4caabf', contrastText: 'white' },

        pink: { main: '#bf4c6d', contrastText: 'white' },

        blue1: { main: '#c4f1fc' },
        blue1Dark: { main: '#4caabf', contrastText: 'white' },
        blue1Light: { main: '#def4f9' },

        pink1: { main: '#f9c2d2' },
        // https://mui.com/material-ui/customization/color/#playground
        pink1Dark: { main: '#bf4c6d', dark: '#85354c', light: '#cb6f8a', contrastText: 'white' },
        pink1Light: { main: '#fce0e8' },

        beige1: { main: '#f7d6c0' },
        beige1Dark: { main: '#bf7a4c' },
        beige1Light: { main: '#f9e9de' },

        green1: { main: '#daf7c0' },
        green1Dark: { main: '#83bf4c' },
        green1Light: { main: '#ebf9de' },

        yellow1: { main: '#f7e6c0' },
        yellow1Dark: { main: '#bf9a4c' },
        yellow1Light: { main: '#f9f1de' },

        red1: { main: '#f7c0c0' },
        red1Dark: { main: '#c44e4e' },
        red1Light: { main: '#f9dede' },

        gray1: { main: '#e2e2e2' },
        gray1Dark: { main: '#8c8c8c' },
        gray1Light: { main: '#eaeaea' },

        background: {
            default: LIGHT_BLUE_DEFAULT,
            paper: LIGHT_BLUE_PAPER,
        },
        text: {
            primary: LIGHT_BLUE_TEXT_PRIMARY,
            secondary: LIGHT_BLUE_TEXT_SECONDARY,
            disabled: LIGHT_BLUE_TEXT_DISABLED,
        },

        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
        },
    },
};

function initializeServices(navigate: NavigateFunction, location: Location) {
    // Rest API
    Api.initialize();

    // Graphql API
    const graphqlClient = Graphql.initialize(navigate, location);

    // Moment.js
    moment.locale('fr');
    moment.tz.setDefault('America/Montreal');

    // MUI Pro
    LicenseInfo.setLicenseKey(
        '3ca61947a13d977357b9bc0b87bcaabbTz00NTIwOCxFPTE2ODYxNTkwMDQyODQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
    );

    return { graphqlClient };
}

function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const { graphqlClient } = initializeServices(navigate, location);

    return (
        <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {/* <LocalizationProviderPro dateAdapter={AdapterMoment}> */}
                <ApolloProvider client={graphqlClient}>
                    <AuthProvider>
                        <AppContent />
                    </AuthProvider>
                </ApolloProvider>
                {/* </LocalizationProviderPro> */}
            </LocalizationProvider>
        </ReduxProvider>
    );
}

function AppContent() {
    const general = useAppSelector((state) => state.general);

    const theme = React.useMemo(
        () =>
            createTheme({
                ...THEME,
                palette: {
                    ...THEME.palette,
                    mode: general.isThemeDark ? 'dark' : 'light',
                },
                components: {
                    /**
                     * Necessary if we want to vertically align the no rows and no results overlays in the center of the DataGrid (it's a bug that only happens when the table has a horizontal scroller)
                     *
                     * See: https://github.com/mui/mui-x/issues/3229#issuecomment-1019297419
                     */
                    //
                    MuiGrid: {
                        styleOverrides: {
                            root: {
                                '.MuiDataGrid-overlay': {
                                    height: 'auto !important',
                                },
                            },
                        },
                    },
                },
            }),
        [general],
    );

    return (
        <ThemeProvider theme={theme}>
            <Router />
        </ThemeProvider>
    );
}

export default App;
