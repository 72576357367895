import { gql } from '@apollo/client';
import { PstReportFields } from 'apollo/fragments';

export const GET_EC_OVERVIEW = gql`
    query GetEcOverview($id: Int) {
        ecOverview(id: $id) {
            id
            licence
            grp
            ec_id
            fmp_id
            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement
            grp_nom
            is_grp_inc
            total_brut
            total_deduction
            total_cotisation
            total_cotisation_cum
            total_transfert_grp
            interet_sur_recouvrement
            interet_sur_avance
            avances_remises
            avances_recouvrement
            interet_sur_solde_neg
            solde_reporte
            solde_a_reporte
            modif_de_solde
            paiement_recu_solde
            debut_interet_sur_solde_neg
            montant_net
            no_cheque_ou_virement
            mode_paiement
        }
    }
`;

export const GET_PST_REPORTS = gql`
    ${PstReportFields}

    query GetPstReports($ec_md_grp_id: Int!) {
        pstReports(ec_md_grp_id: $ec_md_grp_id) {
            ...PstReportFields

            FILE_URL
        }
    }
`;
