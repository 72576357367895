import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { claimsData, IClaim } from 'redux/data';

export type ClaimsState = ({} & IClaim)[];

const initialState: ClaimsState = claimsData;

const claimsSlice = createSlice({
    name: 'claims',
    initialState,
    reducers: {
        addClaim(state, action: PayloadAction<ClaimsState[0]>) {
            state.push(action.payload);
        },
        updateClaim(state, action: PayloadAction<ClaimsState[0]>) {
            let existingClaimIdx = state.findIndex((claim) => {
                return claim.id === action.payload.id;
            });
            if (existingClaimIdx !== undefined) {
                state[existingClaimIdx] = action.payload;
            }
        },
        deleteClaim(state, action: PayloadAction<ClaimsState[0]['id']>) {
            return state.filter((claim) => claim.id !== action.payload);
        },
    },
});

export const { addClaim, updateClaim, deleteClaim } = claimsSlice.actions;

export default claimsSlice.reducer;
