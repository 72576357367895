import { configureStore } from '@reduxjs/toolkit';
import claimsReducer from './features/claims/claimsSlice';
import workshiftsReducer from './features/workshifts/workshiftsSlice';
import generalReducer from './features/general/generalSlice';
import actsReducer from './features/acts/actsSlice';
import activeClaimReducer from './features/activeClaim/activeClaimSlice';
import notificationsReducer from './features/notifications/notificationsSlice';
import doctorsReducer from './features/doctors/doctorsSlice';

const store = configureStore({
    reducer: {
        claims: claimsReducer,
        workshifts: workshiftsReducer,
        general: generalReducer,
        acts: actsReducer,
        activeClaim: activeClaimReducer,
        notifications: notificationsReducer,
        doctors: doctorsReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
