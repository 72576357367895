import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import FileSaver from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import { Breadcrumbs, Button, Typography, useMediaQuery } from '@mui/material';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getEcListColumns } from './grid-columns';

// MUI Icons
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DataGridLoader from 'components/DataGridLoader';
import EcDialog from './EcDialog';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_EC_OVERVIEWS } from './apollo-queries';

// Redux

// Services
import { Api } from 'services';

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        EC
    </Typography>,
];

export default function Ec() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    // Apollo queries
    const { loading: eOverviewsLoading, error: eOverviewsError, data: eOverviewsData } = useQuery(GET_EC_OVERVIEWS, {});

    // Ec Dialog
    const [selectedEcMdGrpId, setSelectedEcMdGrpId] = useState<number | null>(null);

    const handleOpenEcDialog = useCallback((ec_md_grp_id: number) => {
        setSelectedEcMdGrpId(ec_md_grp_id);
    }, []);
    const handleCloseEcDialog = useCallback(() => {
        setSelectedEcMdGrpId(null);
    }, []);

    // EC files download
    const [ecMdGrpIdsOfCurrentFileDownloads, setEcMdGrpIdsOfCurrentFileDownloads] = useState<number[]>([]);
    const handleDownloadEcFiles = useCallback(
        async (ec_md_grp_id: number, date_ec: string, no_paiement: number, grp: number) => {
            setEcMdGrpIdsOfCurrentFileDownloads([...ecMdGrpIdsOfCurrentFileDownloads, ec_md_grp_id]);

            try {
                const res = await Api.get('storage/ec-files', {
                    responseType: 'blob',
                    timeout: 1000 * 20, // 20 seconds
                    params: {
                        ec_md_grp_id: ec_md_grp_id,
                    },
                });

                const filename = `${moment(date_ec).year()}-${no_paiement}__${grp}.zip`;
                FileSaver.saveAs(res.data, filename);
            } catch (e) {
            } finally {
                setEcMdGrpIdsOfCurrentFileDownloads(
                    ecMdGrpIdsOfCurrentFileDownloads.filter((item) => item === ec_md_grp_id),
                );
            }
        },
        [setEcMdGrpIdsOfCurrentFileDownloads, ecMdGrpIdsOfCurrentFileDownloads],
    );

    // Columns
    const ecListColumns = useMemo(
        () => getEcListColumns(handleOpenEcDialog, handleDownloadEcFiles, ecMdGrpIdsOfCurrentFileDownloads, isMobile),
        [handleOpenEcDialog, handleDownloadEcFiles, ecMdGrpIdsOfCurrentFileDownloads, isMobile],
    );
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                États de comptes
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 0.5, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={eOverviewsData?.ecOverviews || []}
                columns={ecListColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                pinnedColumns={{ right: ['actions'], ...(isLaptop ? { left: ['no_paiement'] } : {}) }}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'date_ec', sort: 'desc' },
                            { field: 'grp', sort: 'desc' },
                        ],
                    },
                }}
                loading={eOverviewsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />

            <EcDialog ecMdGrpId={selectedEcMdGrpId} handleCloseEcDialog={handleCloseEcDialog} />
        </>
    );
}
