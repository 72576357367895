import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { Button, ButtonProps, IconButton, Typography, Tooltip } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import SexIcon from 'components/SexIcon';
import ColoredNumber from 'components/ColoredNumber';

// Constants
import {
    DATE_COL_DEFAULTS,
    NCE_COL_DEFAULTS,
    NAM_WITH_ICON_COL_DEFAULTS,
    POSTE_NAME_WITH_ID_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
    PAYMENT_STATUS_COL_DEFAULTS,
    LINE_COUNT_COL_DEFAULTS,
    TYPE_SERVICE_NAME_COL_DEFAULTS,
    NO_DEMANDE_COL_DEFAULTS,
    NO_ATTESTATION_COL_DEFAULTS,
    MD_FULL_NAME_WITH_TITLE_COL_DEFAULTS,
    DATETIME_COL_DEFAULTS,
    LOCALITE_COL_DEFAULT,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { fmpHoursToRamqHours, formatNumber } from 'utils';

export const getFactureListColumns = (isMobile): GridColDefPro[] => [
    // Main info
    {
        ...NCE_COL_DEFAULTS,
        field: 'nce',
        headerName: 'NCE',
        valueGetter: ({ value, row }) => `${value}__${row.revision}`,
    },
    // {
    //     field: 'created_at',
    //     headerName: 'Transmis le',
    //     valueGetter: ({ value }) => moment(value).format(SQL_DATE),
    //     ...DATE_COL_DEFAULTS,
    // },
    {
        ...DATE_COL_DEFAULTS,
        field: 'debut_periode',
        headerName: 'Début periode',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'fin_periode',
        headerName: 'Fin periode',
    },
    {
        ...POSTE_NAME_WITH_ID_COL_DEFAULTS,
        field: 'pst_name',
        headerName: 'Poste',
        valueGetter: ({ value, row }) => value && `${value} (${row.pst_no})`,
    },
    {
        field: 'hrs_mins_activite_reclame',
        headerName: 'H activité réclamées',
        valueGetter: ({ row }) => `${fmpHoursToRamqHours(row.hrs_activite, row.mins_activite)} h`,
        width: 130,
    },
    {
        field: 'hrs_activite_payes',
        headerName: 'H activité payées',
        valueFormatter: ({ value }) => `${(value || 0).toFixed(2)} h`,
        width: 130,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_remu_activite',
        headerName: 'Activité payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
        width: 130,
    },

    {
        field: 'hrs_mins_depl_reclame',
        headerName: 'H dépl. réclamées',
        valueGetter: ({ row }) => `${fmpHoursToRamqHours(row.hrs_depl, row.mins_depl)} h`,
        width: 130,
    },
    {
        field: 'hrs_depl_payes',
        headerName: 'H dépl. payées',
        valueFormatter: ({ value }) => `${(value || 0).toFixed(2)} h`,
        width: 130,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_remu_temps_depl',
        headerName: 'Déplacement payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
        width: 130,
    },

    {
        field: 'kms_depl',
        headerName: 'Kms réclamés',
        valueGetter: ({ value }) => `${value || 0} km`,
        width: 100,
    },
    {
        field: 'kms_transport_payes',
        headerName: 'Kms payés',
        valueFormatter: ({ value }) => `${value || 0} km`,
        width: 100,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montant_transport_reclame',
        headerName: 'Transport réclamé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
        width: 140,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_remu_cout_transport',
        headerName: 'Transport payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
        width: 140,
    },

    {
        ...AMT_COL_DEFAULTS,
        field: 'total_remu',
        headerName: 'Rémunération totale',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
        width: 140,
    },

    // Less important info
    {
        field: 'cs1',
        headerName: 'CS1',
    },
    {
        field: 'cs2',
        headerName: 'CS2',
    },
    {
        ...MD_FULL_NAME_WITH_TITLE_COL_DEFAULTS,
        field: 'nom_mand',
        headerName: 'Nom mandataire',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'date_mand',
        headerName: 'Date mand.',
    },
    {
        ...MD_FULL_NAME_WITH_TITLE_COL_DEFAULTS,
        field: 'nom_sign',
        headerName: 'Nom signataire',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'date_sign',
        headerName: 'Date sign.',
    },
    {
        ...NO_DEMANDE_COL_DEFAULTS,
        field: 'once',
        headerName: 'Once',
    },
    {
        ...NO_ATTESTATION_COL_DEFAULTS,
        field: 'attestation',
        headerName: '# Attestation',
    },

    // Déplacement
    {
        ...LOCALITE_COL_DEFAULT,
        field: 'pst_depart',
        headerName: 'Poste départ',
    },
    {
        ...LOCALITE_COL_DEFAULT,
        field: 'pst_arrivee',
        headerName: 'Poste arrivée',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'dh_arrivee',
        headerName: 'Arrivée à',
    },

    {
        field: 'PAYMENT_STATUS_NAME',
        headerName: 'Statut paiement',
        ...PAYMENT_STATUS_COL_DEFAULTS,
        renderCell: ({ value, row }) => {
            let buttonColor: ButtonProps['color'] = 'success';
            switch (row.payment_status) {
                case 'canceled':
                    buttonColor = 'error';
                    break;
                case 'payable':
                    buttonColor = 'warning';
                    break;
                case 'paid':
                    buttonColor = 'success';
                    break;
            }

            let buttonText = value;

            return (
                <Button variant="text" color={buttonColor}>
                    {buttonText}
                </Button>
            );
        },
    },
    // {
    //     field: 'LAST_PAYMENT_DATE',
    //     headerName: 'Dernier paiement',
    //     valueGetter: ({ value }) => value && moment(value).format(SQL_DATE),
    //     width: 150,
    // },
    {
        field: 'line_count',
        headerName: 'Nb. lignes',
        ...LINE_COUNT_COL_DEFAULTS,
    },
    // {
    //     field: 'is_canceled',
    //     headerName: 'Statut',
    //     headerAlign: 'center',
    //     align: 'center',
    //     width: SMALL_STRING_COLUMN_WIDTH,
    //     renderCell: ({ value }) => <BooleanIcon value={!value} />,
    // },
    {
        field: 'actions',
        headerName: 'Actions',
        width: isMobile ? 60 : 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { nce, revision } = row;

            return (
                <>
                    <Tooltip
                        title="La page du détaillé complet d'une réclamation sera disponible au courant des prochaines semaines!"
                        disableInteractive
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            // component={RouterLink}
                            // to={`/factures/${nce}/${revision}`}
                            // target="_blank"
                            onClick={() => null}
                        >
                            <OpenInNewIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];
