import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notificationsData, INotification } from 'redux/data';

export type NotificationsState = ({} & INotification)[];

const initialState: NotificationsState = notificationsData;

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification(state, action: PayloadAction<NotificationsState[0]>) {
            state.push(action.payload);
        },
        updateNotification(state, action: PayloadAction<NotificationsState[0]>) {
            let existingNotificationIdx = state.findIndex((notification) => {
                return notification.id === action.payload.id;
            });
            if (existingNotificationIdx !== undefined) {
                state[existingNotificationIdx] = action.payload;
            }
        },
        setViewed(state, action: PayloadAction<Pick<NotificationsState[0], 'id' | 'viewed'>>) {
            let existingNotificationIdx = state.findIndex((notification) => {
                return notification.id === action.payload.id;
            });
            if (existingNotificationIdx !== undefined) {
                state[existingNotificationIdx].viewed = action.payload.viewed;
            }
        },
    },
});

export const { addNotification, updateNotification, setViewed } = notificationsSlice.actions;

export default notificationsSlice.reducer;
