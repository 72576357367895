import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';

export const GET_CLAIM_CALENDAR_EVENTS = gql`
    query getClaimCalendarEvents($date_service_start: Date, $date_service_end: Date) {
        claimCalendarEvents(date_service_start: $date_service_start, date_service_end: $date_service_end) {
            date_service
            poste_id
            poste_nom
            patients_count
            total_prelim
            total_final
        }
    }
`;

export const GET_CALENDAR_VAC_EVENTS = gql`
    query getCalendarVacEvents($date_service_start: Date, $date_service_end: Date) {
        calendarVacEvents(date_service_start: $date_service_start, date_service_end: $date_service_end) {
            reqt_id
            once
            date_service
            pst_no
            pst_mod
            pst_name
            hours
        }
    }
`;

export const GET_ECS = gql`
    query GetEcs($date_ec_less_or_equals_to: Date) {
        ecs(date_ec_less_or_equals_to: $date_ec_less_or_equals_to) {
            id
            created_at
            updated_at
            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement
            fmp_id
        }
    }
`;
