import moment from 'moment';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { getRandomImageUrl } from 'utils';

export const dxsCategoriesData = [
    {
        id: 1,
        name: 'Cardiac',
    },
    {
        id: 2,
        name: 'Respiratory',
    },
    {
        id: 3,
        name: 'Digestive',
    },
    {
        id: 4,
        name: 'Others',
    },
];

export const dxsData = [
    {
        id: 1,
        name: 'Heart attack',
        code: '0826',
        category_id: 1,
    },
    {
        id: 2,
        name: 'Stroke',
        code: '0827',
        category_id: 1,
    },
    {
        id: 3,
        name: 'Respiratory disease',
        code: '0828',
        category_id: 2,
    },
    {
        id: 4,
        name: 'Appendicitis',
        code: '0829',
        category_id: 3,
    },
    {
        id: 5,
        name: 'Anemia',
        code: '0830',
        category_id: 4,
    },
    {
        id: 6,
        name: 'Diabetes',
        code: '0831',
        category_id: 4,
    },
];

export const actsCategoriesData = [
    {
        id: 1,
        name: 'Exams',
    },
    {
        id: 2,
        name: 'Fractures',
    },
    {
        id: 3,
        name: 'Drainages',
    },
];

export const actsData = [
    {
        id: 1,
        name: 'Principal exam',
        desc:
            "La consultation principale est l'examen complet ou principal effectué conformément aux exigences générales de la consultation.",
        code: '19911',
        category_id: 1,
        fields: [],
    },
    {
        id: 2,
        name: 'Ordinary exam',
        desc:
            "La consultation ordinaire est l'examen complet ou principal effectué conformément aux exigences générales de la consultation.",
        code: '19918',
        category_id: 1,
        fields: [],
    },
    {
        id: 3,
        name: 'Complex situation',
        desc:
            "La consultation complexe est l'examen complet ou principal effectué conformément aux exigences générales de la consultation.",
        code: '19926',
        category_id: 1,
        fields: [
            {
                id: 1,
                inputType: 'string',
                label: 'Beginning of procedure',
                value: '03/07/2021 20h32',
            },
            {
                id: 2,
                inputType: 'string',
                label: 'Duration of procedure',
                value: '45m',
            },
        ],
    },
    {
        id: 4,
        name: 'Face bandage',
        desc: "Installation d'un pansement de x cm au niveau du visage.",
        code: '811',
        category_id: 2,
        fields: [
            {
                id: 1,
                inputType: 'string',
                label: 'Size of the wound',
                value: '3cm',
            },
        ],
    },
    {
        id: 5,
        name: 'Arm cast',
        desc: "Installation d'un plâtre au niveau du bras.",
        code: '326',
        category_id: 2,
        fields: [
            {
                id: 1,
                inputType: 'string',
                label: 'Length of the cast',
                value: '15cm',
            },
        ],
    },
    {
        id: 6,
        name: 'Cyst drainage',
        desc: "Drainage d'un kyste.",
        code: '958',
        category_id: 3,
        fields: [
            {
                id: 1,
                inputType: 'string',
                label: 'Size of the cyst',
                value: '3cm',
            },
        ],
    },
];

export const contextsData = [
    { id: 1, code: '0201', name: 'Contexte 1', desc: '', categoryName: 'Context group A' },
    { id: 2, code: '0202', name: 'Contexte 2', desc: '', categoryName: 'Context group B' },
    { id: 3, code: '0203', name: 'Contexte 3', desc: '', categoryName: 'Context group C' },
    { id: 4, code: '0204', name: 'Context 4', desc: '', categoryName: 'Context group B' },
    { id: 5, code: '0205', name: 'Context 5', desc: '', categoryName: 'Context group C' },
    { id: 6, code: '0206', name: 'Context 6', desc: '', categoryName: 'Context group D' },
    { id: 7, code: '0207', name: 'Context 7', desc: '', categoryName: 'Context group F' },
    { id: 8, code: '0208', name: 'Context 8', desc: '', categoryName: 'Context group E' },
    { id: 9, code: '0209', name: 'Context 9', desc: '', categoryName: 'Context group D' },
    { id: 10, code: '02010', name: 'Context 10', desc: '', categoryName: 'Context group A' },
    { id: 11, code: '02011', name: 'Context 11', desc: '', categoryName: 'Context group B' },
    { id: 12, code: '02012', name: 'Context 12', desc: '', categoryName: 'Context group C' },
    { id: 13, code: '02013', name: 'Context 13', desc: '', categoryName: 'Context group C' },
    { id: 14, code: '02014', name: 'Context 14', desc: '', categoryName: 'Context group C' },
    { id: 15, code: '02015', name: 'Context 15', desc: '', categoryName: 'Context group D' },
];

export const facilitiesCategoriesData = [
    {
        id: 1,
        name: 'Hull',
    },
    {
        id: 2,
        name: 'Montréal',
    },
];

export const facilitiesData = [
    {
        id: 1,
        name: 'Hôpital de Hull (Urgence)',
        code: '01261-01',
        category_id: 1,
    },
    {
        id: 2,
        name: 'CHUM (Général)',
        code: '01592-05',
        category_id: 2,
    },
    {
        id: 3,
        name: 'Hôpital Notre-Dame (Général)',
        code: '01592-05',
        category_id: 2,
    },
];

export const patientsCategoriesData = [
    {
        id: 1,
        name: 'Seen on 07-09-2021',
    },
    {
        id: 2,
        name: 'Seen on 06-09-2021',
    },
];

export const patientsData = [
    {
        id: 1,
        first_name: 'John',
        last_name: 'Doe',
        nam: 'DOEJ12060399',
        category_id: 1,
    },
    {
        id: 2,
        first_name: 'Michael',
        last_name: 'Vanna',
        nam: 'VANM69120324',
        category_id: 2,
    },
    {
        id: 3,
        first_name: 'Eddie',
        last_name: 'Bravo',
        nam: 'BRAE74110284',
        category_id: 2,
    },
];

export interface IDoctor {
    id: number;
    created_at: string;
    joined_on: string;
    first_name: string;
    last_name: string;
    practice_number: string;
    domain_category_id: number;
    status: 'active' | 'inactive';
    // retired: false;
    assigned_to: IDoctorAssignedTo[];
}

export interface IDoctorAssignedTo {
    id: number;
    name: string;
    image_url: string;
}

export const doctorsData: IDoctor[] = [
    {
        id: 1,
        created_at: '2021-07-03 20:29:20',
        joined_on: '2021-07-01',
        first_name: 'Andrew',
        last_name: 'Huberman',
        practice_number: '1029026',
        domain_category_id: 1,
        status: 'active',
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 6,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
        ],
    },
    {
        id: 2,
        created_at: '2021-07-03 20:29:20',
        joined_on: '2021-07-01',
        first_name: 'Christiane',
        last_name: 'Dubeau',
        practice_number: '1039028',
        domain_category_id: 2,
        status: 'inactive',
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
        ],
    },
    {
        id: 3,
        created_at: '2021-07-03 20:29:20',
        joined_on: '2021-07-01',
        first_name: 'George',
        last_name: 'Dubé',
        practice_number: '1040034',
        domain_category_id: 3,
        status: 'active',
        assigned_to: [
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
        ],
    },
    {
        id: 4,
        created_at: '2021-07-03 20:29:20',
        joined_on: '2021-07-01',
        first_name: 'Matthew',
        last_name: 'Walker',
        practice_number: '9287452',
        domain_category_id: 4,
        status: 'active',
        assigned_to: [
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 4,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 5,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
        ],
    },
];

export interface IClaim {
    id: number;
    created_at: string;
    date: string;
    doctor_id: number;
    patient_id: number;
    facility_id: number;
    assigned_to: IClaimAssignedTo[];
    dx_id: number;
    acts: IClaimAct[];
    referring_physician_practice_number: string | null;
    total: number;
    status: 'Draft' | 'Sent' | 'Processed' | 'Paid' | 'Problematic';
    note: string;
    history?: {
        date: string;
        data: IClaimHistory[];
    }[];
    chats?: IClaimChats[];
}

export interface IClaimAssignedTo {
    id: number;
    name: string;
    image_url: string;
}

export interface IClaimAct {
    id: number;
    act_id: number;
    act_contexts: { id: number; context_id: number }[];
    status: 'Draft' | 'Sent' | 'Processed' | 'Paid' | 'Problematic';
    total: number;
    fields: {
        inputType: string;
        label: string;
        value: string;
    }[];
}

export interface IClaimHistory {
    id: number;
    created_at: string;
    type: any;
    payload: {
        by?: string;
        user_group?: 'fmp-team' | 'dr-team';
        estimated_total?: number;
        total?: number;
    };
}

export interface IClaimChats {
    id: number;
    users: { id: number; name: string; image_url: string }[];
    last_message: {
        created_at: string;
        user: { id: number; name: string; image_url: string };
        content: string;
        read: boolean;
    };
}

export const claimsData: IClaim[] = [
    {
        id: 1,
        created_at: '2021-05-10 14:32:04',
        date: '2021-05-10',
        doctor_id: 1,
        patient_id: 1,
        facility_id: 1,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 4,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 5,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
            {
                id: 6,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),
            },
        ],
        dx_id: 1,
        acts: [
            {
                id: 1,
                act_id: 1,
                act_contexts: [{ id: 1, context_id: 1 }],
                status: 'Processed',
                total: 202.92,
                fields: [],
            },
            {
                id: 2,
                act_id: 2,
                act_contexts: [
                    { id: 1, context_id: 1 },
                    { id: 2, context_id: 2 },
                    { id: 3, context_id: 3 },
                ],
                status: 'Processed',
                total: 202.92,
                fields: [],
            },
            {
                id: 3,
                act_id: 3,
                act_contexts: [
                    { id: 1, context_id: 1 },
                    { id: 2, context_id: 2 },
                ],
                status: 'Problematic',
                total: 202.92,
                fields: [],
            },
            {
                id: 4,
                act_id: 4,
                act_contexts: [
                    { id: 1, context_id: 1 },
                    { id: 2, context_id: 2 },
                    { id: 3, context_id: 4 },
                    { id: 4, context_id: 2 },
                ],
                status: 'Draft',
                total: 202.92,
                fields: [],
            },
            {
                id: 5,
                act_id: 5,
                act_contexts: [],
                status: 'Sent',
                total: 202.92,
                fields: [],
            },
            {
                id: 6,
                act_id: 6,
                act_contexts: [{ id: 2, context_id: 2 }],
                status: 'Paid',
                total: 202.92,
                fields: [],
            },
            {
                id: 7,
                act_id: 1,
                act_contexts: [{ id: 1, context_id: 1 }],
                status: 'Processed',
                total: 202.92,
                fields: [],
            },
            {
                id: 8,
                act_id: 1,
                act_contexts: [],
                status: 'Problematic',
                total: 202.92,
                fields: [],
            },
            {
                id: 9,
                act_id: 1,
                act_contexts: [
                    { id: 1, context_id: 1 },
                    { id: 2, context_id: 3 },
                ],
                status: 'Problematic',
                total: 202.92,
                fields: [],
            },
        ],
        referring_physician_practice_number: null,
        total: 189.73,
        status: 'Draft',
        note: '',
        history: [
            {
                date: '2021-10-01',
                data: [
                    {
                        id: 1,
                        created_at: '2021-10-15 20:29:05',
                        type: 'Drafted',
                        payload: {
                            estimated_total: 202.45,
                        },
                    },
                ],
            },
            {
                date: '2021-10-16',
                data: [
                    {
                        id: 6,
                        created_at: '2021-10-16 18:12:57',
                        type: 'Modified',
                        payload: {
                            by: 'You',
                            user_group: 'dr-team',
                        },
                    },
                    {
                        id: 6,
                        created_at: '2021-10-16 18:38:57',
                        type: 'Modified',
                        payload: {
                            by: 'Sandra G. (Receptionist)',
                            user_group: 'dr-team',
                        },
                    },
                    {
                        id: 2,
                        created_at: '2021-10-16 19:02:40',
                        type: 'Sent',
                        payload: {
                            by: 'Sandra G. (Receptionist)',
                            user_group: 'dr-team',
                        },
                    },
                ],
            },
            {
                date: '2021-10-17',
                data: [
                    {
                        id: 3,
                        created_at: '2021-10-17 08:45:30',
                        type: 'Problematic',
                        payload: {
                            by: 'Nancy Roy (FMP admin)',
                            user_group: 'fmp-team',
                        },
                    },
                    {
                        id: 6,
                        created_at: '2021-10-17 16:15:40',
                        type: 'Modified',
                        payload: {
                            by: 'France Paradis (FMP admin)',
                            user_group: 'dr-team',
                        },
                    },
                    {
                        id: 4,
                        created_at: '2021-10-17 16:17:30',
                        type: 'Processed',
                        payload: {
                            by: 'France Paradis (FMP admin)',
                            user_group: 'fmp-team',
                        },
                    },
                ],
            },
            {
                date: '2021-10-18',
                data: [
                    {
                        id: 5,
                        created_at: '2021-10-18 18:00:00',
                        type: 'Paid',
                        payload: {
                            total: 220.32,
                        },
                    },
                ],
            },
        ],
        chats: [
            {
                id: 1,
                users: [
                    {
                        id: 1,
                        name: 'France Paradis',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 2,
                        name: 'Nancy Roy',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 9999,
                        name: 'Clovis Leblond',
                        image_url: getRandomImageUrl(),
                    },
                ],
                last_message: {
                    created_at: moment().subtract(45, 'days').format(SQL_DATETIME),
                    user: {
                        id: 1,
                        name: 'France Paradis',
                        image_url: getRandomImageUrl(),

                        // image_url: "https://ui-avatars.com/api/?name=John+Doe",
                    },
                    content: 'Successfully launched the app on the simulator',
                    read: true,
                },
            },
            {
                id: 2,
                users: [
                    {
                        id: 5,
                        name: 'Johanne Tremblay',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 9999,
                        name: 'Clovis Leblond',
                        image_url: getRandomImageUrl(),
                    },
                ],
                last_message: {
                    // created_at: "2021-10-12 13:54:22",
                    created_at: moment().subtract(4, 'days').format(SQL_DATETIME),
                    user: {
                        id: 2,
                        name: 'Johanne Tremblay',
                        image_url: getRandomImageUrl(),

                        // image_url: "https://ui-avatars.com/api/?name=John+Doe",
                    },
                    content: 'Delta compression using up to 12 threads',
                    read: false,
                },
            },
            {
                id: 3,
                users: [
                    {
                        id: 1,
                        name: 'France Paradis',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 3,
                        name: 'Dr. Andrew Huberman',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 4,
                        name: 'Dr. Matthew Walker',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 9999,
                        name: 'Clovis Leblond',
                        image_url: getRandomImageUrl(),
                    },
                ],
                last_message: {
                    created_at: moment().subtract(6, 'hours').format(SQL_DATETIME),
                    user: {
                        id: 9999,
                        name: 'Clovis Leblond',
                        image_url: getRandomImageUrl(),

                        // image_url: "https://ui-avatars.com/api/?name=John+Doe",
                    },
                    content: 'Counting objects 100% (128/128), done.',
                    read: false,
                },
            },
            {
                id: 4,
                users: [
                    {
                        id: 2,
                        name: 'France Paradis',
                        image_url: getRandomImageUrl(),
                    },
                    {
                        id: 9999,
                        name: 'Clovis Leblond',
                        image_url: getRandomImageUrl(),
                    },
                ],
                last_message: {
                    created_at: moment().subtract(2, 'hours').format(SQL_DATETIME),
                    user: {
                        id: 9999,
                        name: 'Clovis Leblond',
                        image_url: getRandomImageUrl(),

                        // image_url: "https://ui-avatars.com/api/?name=John+Doe",
                    },
                    content: 'Resolving deltas: 100% (30/30), completed with 25 local objects.',
                    read: false,
                },
            },
        ],
    },
    {
        id: 2,
        created_at: '2021-05-11 14:32:04',
        date: '2021-05-11',
        doctor_id: 2,
        patient_id: 2,
        facility_id: 2,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 2,
        acts: [],
        referring_physician_practice_number: null,
        total: 249.17,
        status: 'Sent',
        note: '',
    },
    {
        id: 3,
        created_at: '2021-05-12 14:32:04',
        date: '2021-05-12',
        doctor_id: 3,
        patient_id: 3,
        facility_id: 3,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 3,
        acts: [],
        referring_physician_practice_number: null,
        total: 111.59,
        status: 'Processed',
        note: '',
    },
    {
        id: 4,
        created_at: '2021-05-13 14:32:04',
        date: '2021-05-13',
        doctor_id: 1,
        patient_id: 1,
        facility_id: 1,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 3,
        acts: [],
        referring_physician_practice_number: null,
        total: 432.95,
        status: 'Paid',
        note: '',
    },
    {
        id: 5,
        created_at: '2021-05-14 14:32:04',
        date: '2021-05-14',
        doctor_id: 1,
        patient_id: 1,
        facility_id: 1,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 3,
        acts: [],
        referring_physician_practice_number: null,
        total: 242.22,
        status: 'Problematic',
        note: '',
    },
    {
        id: 6,
        created_at: '2021-05-14 14:32:04',
        date: '2021-05-14',
        doctor_id: 1,
        patient_id: 1,
        facility_id: 1,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 3,
        acts: [],
        referring_physician_practice_number: null,
        total: 242.22,
        status: 'Problematic',
        note: '',
    },
    {
        id: 7,
        created_at: '2021-05-14 14:32:04',
        date: '2021-05-14',
        doctor_id: 1,
        patient_id: 1,
        facility_id: 1,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 3,
        acts: [],
        referring_physician_practice_number: null,
        total: 242.22,
        status: 'Problematic',
        note: '',
    },
    {
        id: 8,
        created_at: '2021-05-14 14:32:04',
        date: '2021-05-14',
        doctor_id: 1,
        patient_id: 1,
        facility_id: 1,
        assigned_to: [
            {
                id: 1,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 2,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
            {
                id: 3,
                name: 'France Paradis',
                image_url: getRandomImageUrl(),

                // image_url: "https://ui-avatars.com/api/?name=John+Doe",
            },
        ],
        dx_id: 3,
        acts: [],
        referring_physician_practice_number: null,
        total: 242.22,
        status: 'Problematic',
        note: '',
    },
];

export const workshiftsTypes = [
    {
        id: 1,
        name: 'De jour',
    },
    {
        id: 2,
        name: 'Au-delà de 00:00 - Médecin 1',
    },
    {
        id: 3,
        name: 'Au-delà de 00:00 - Médecin 2',
    },
];

export const workshiftsData = [
    {
        id: 1,
        start_date: '2021-09-18 08:00:00',
        end_date: '2021-09-18 16:30:00',
        workshift_type_id: 1,
        status: 'Draft',
    },
    {
        id: 2,
        start_date: '2021-09-19 00:00:00',
        end_date: '2021-09-19 10:00:00',
        workshift_type_id: 2,
        status: 'Sent',
    },
    {
        id: 3,
        start_date: '2021-09-20 23:00:00',
        end_date: '2021-09-21 07:00:00',
        workshift_type_id: 3,
        status: 'Processed',
    },
    {
        id: 4,
        start_date: '2021-09-22 17:00:00',
        end_date: '2021-09-21 22:00:00',
        workshift_type_id: 3,
        status: 'Problematic',
    },
    {
        id: 5,
        start_date: '2021-09-24 08:00:00',
        end_date: '2021-09-24 16:00:00',
        workshift_type_id: 3,
        status: 'Paid',
    },
];

export const domainCategoriesData = [
    {
        id: 1,
        name: 'Neurologie',
    },
    {
        id: 2,
        name: 'Obstétrique',
    },
    {
        id: 3,
        name: 'Système nerveux',
    },
    {
        id: 4,
        name: 'Dentaire',
    },
];

export const referringPhysiciansData = [
    {
        id: 1,
        first_name: 'Andrew',
        last_name: 'Huberman',
        practice_number: '1029026',
        category_id: 1,
    },
    {
        id: 2,
        first_name: 'Michael',
        last_name: 'Vanna',
        practice_number: '1039028',
        category_id: 2,
    },
    {
        id: 3,
        first_name: 'Eddie',
        last_name: 'Bravo',
        practice_number: '1040034',
        category_id: 2,
    },
    {
        id: 4,
        first_name: 'Matthew',
        last_name: 'Walker',
        practice_number: '9287452',
        category_id: 3,
    },
];

export const notificationsTypes = ['claim.status.updated', 'message.created', 'payment.created'];

export interface INotificationBasic {
    id: number;
    created_at: string;
    viewed: boolean;
    clicked: boolean;
}

export type INotification =
    | (INotificationBasic & {
          type: 'claim.status.updated';
          payload: {
              claim_id: number;
              new_status: 'processed' | 'problematic';
              by: string;
              user_group: string;
          };
      })
    | (INotificationBasic & {
          type: 'message.created';
          payload: {
              by: string;
              user_group: string;
          };
      })
    | (INotificationBasic & {
          type: 'payment.created';
          payload: {
              total: number;
          };
      });

export const notificationsData: INotification[] = [
    // {
    //     id: 1,
    //     created_at: "2021-10-14 20:29:05",
    //     type: "claim.status.updated",
    //     payload: {
    //         claim_id: 2,
    //         new_status: "processed",
    //     },
    //     viewed: true,
    //     clicked: true,
    // },
    {
        id: 2,
        created_at: moment().subtract(7, 'day').format(SQL_DATETIME),
        type: 'message.created',
        payload: {
            by: 'François Tessier (FMP admin)',
            user_group: 'dr-team',
        },
        viewed: false,
        clicked: false,
    },
    {
        id: 3,
        created_at: moment().subtract(4, 'days').format(SQL_DATETIME),
        type: 'payment.created',
        payload: {
            total: 3224.92,
        },
        viewed: true,
        clicked: true,
    },
    {
        id: 4,
        created_at: moment().subtract(3, 'days').format(SQL_DATETIME),
        type: 'claim.status.updated',
        payload: {
            claim_id: 4,
            new_status: 'problematic',
            by: 'Marc Leblond (FMP admin)',
            user_group: 'fmp-team',
        },
        viewed: false,
        clicked: false,
    },
    {
        id: 5,
        created_at: moment().subtract(2, 'days').format(SQL_DATETIME),
        type: 'message.created',
        payload: {
            by: 'Sandra Gendron (Receptionist)',
            user_group: 'dr-team',
        },
        viewed: true,
        clicked: true,
    },
    {
        id: 6,
        created_at: moment().subtract(4, 'hours').format(SQL_DATETIME),
        type: 'claim.status.updated',
        payload: {
            claim_id: 3,
            new_status: 'problematic',
            by: 'France Paradis (FMP admin)',
            user_group: 'fmp-team',
        },
        viewed: true,
        clicked: true,
    },
    {
        id: 7,
        created_at: moment().subtract(3, 'hours').format(SQL_DATETIME),
        type: 'payment.created',
        payload: {
            total: 2236.48,
        },
        viewed: false,
        clicked: false,
    },
    {
        id: 8,
        created_at: moment().subtract(32, 'days').format(SQL_DATETIME),
        type: 'message.created',
        payload: {
            by: 'Mélissa Jacques (FMP admin)',
            user_group: 'dr-team',
        },
        viewed: true,
        clicked: true,
    },
    {
        id: 9,
        created_at: moment().subtract(35, 'days').format(SQL_DATETIME),
        type: 'claim.status.updated',
        payload: {
            claim_id: 2,
            new_status: 'problematic',
            by: 'Nancy Roy (FMP admin)',
            user_group: 'fmp-team',
        },
        viewed: true,
        clicked: true,
    },
    {
        id: 10,
        created_at: moment().subtract(456, 'days').format(SQL_DATETIME),
        type: 'payment.created',
        payload: {
            total: 5823.29,
        },
        viewed: false,
        clicked: false,
    },
];
