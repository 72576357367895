import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

interface DataGridLoaderProps {
    loadingText?: string;
}

export default function DataGridLoader(props: DataGridLoaderProps) {
    const { loadingText } = props;

    return (
        <Box display="flex" justifyContent="center" alignItems="center" pt={4}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={20} sx={{ mr: 2 }} />
                <Typography>{loadingText ?? 'Chargement...'}</Typography>
            </Box>
        </Box>
    );
}
