import React from 'react';
import { IconProps, SxProps, Typography, TypographyProps } from '@mui/material';
import { Check as CheckIcon, Clear as ClearIcon } from '@mui/icons-material';
import { formatNumber } from 'utils';

interface ColoredNumberProps {
    value: number;
    cents?: boolean;
    variant?: TypographyProps['variant'];
    sx?: SxProps;
}

export default function ColoredNumber(props: ColoredNumberProps) {
    let { value, cents, variant, sx } = props;

    const sxProps = sx || {};

    if (!variant) {
        variant = 'body2';
    }

    let textColor = '';

    if (value > 0) textColor = 'success.main';
    else if (value == 0) textColor = 'warning.main';
    else if (value < 0) textColor = 'error.main';
    else textColor = 'warning.main';

    return (
        <Typography variant={variant} sx={{ ...sxProps, color: textColor }}>
            {formatNumber(value as number, { cents: cents })} $
        </Typography>
    );
}
