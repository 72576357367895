import { Box, Typography } from '@mui/material';

export const NewsItem2 = () => {
    return (
        <Box>
            <Paragraph>
                Nous sommes heureux de vous annoncer officiellement la nomination de madame Claudie Vachon au poste de
                Directrice de Facturation médicale Paradis inc. Claudie s’est jointe à notre équipe en mars dernier et
                certains d’entre vous ont déjà pu échanger avec elle au cours des dernières semaines.
            </Paragraph>

            <Paragraph>
                Par son leadership, Claudie a su, au cours de sa carrière, diriger plusieurs équipes multidisciplinaires
                et prendre part à différents projets de développement, restructuration et gestion du changement qui lui
                a permis d’amener ses équipes vers des avenues florissantes.
            </Paragraph>

            <Paragraph>
                Avec le support et la complicité de notre équipe de professionnelles, Claudie a été mandaté, entre
                autres, pour assurer une qualité exceptionnelle à notre service de facturation et un suivi rigoureux et
                continu au niveau du service à la clientèle. Elle a également reçu le mandat de développer, avec l’aide
                de notre équipe technique, de nouveaux outils informatiques qui seront mis à votre disposition au cours
                de l’année 2022 incluant un nouveau portail et une application mobile vous permettant d’accéder à vos
                données de facturation à partir de votre cellulaire.
            </Paragraph>

            <Subtitle>Nouveau portail</Subtitle>

            <Paragraph>
                Nous sommes également très heureux de vous annoncer la venue d’un tout nouveau portail en ligne que nous
                mettrons à la disposition de notre clientèle au courant de la prochaine semaine. Notre équipe technique
                a travaillé très fort au cours des derniers mois et nous tenons à les remercier grandement. Les
                différentes sections du portail seront déployées successivement au cours des prochaines semaines et
                permettra à notre clientèle de consulter et valider la facturation quotidienne en ligne incluant les
                forfaits, bonifications et suppléments, les états de compte de la RAMQ, les sommaires de facturation et
                bien d’autres utilitaires.
            </Paragraph>

            <Paragraph>
                Un des objectifs visés avec ce nouveau portail est de vous permettre d’accéder et consulter l’ensemble
                de votre facturation via un guichet unique sécurisé. Nous vous invitons d’ailleurs à bien vouloir nous
                soumettre vos idées, préoccupations, conseils et suggestions qui nous permettra d’améliorer
                continuellement cet outil en fonction de vos besoins.
            </Paragraph>

            <Paragraph>
                À noter que d’autres communiqués vous seront transmis au courant de la semaine prochaine afin de vous
                tenir au courant de la progression du déploiement du nouveau portail et de vous permettre d’y accéder
                avec de nouveaux accès sécurisés.
            </Paragraph>

            <Box mt={4}></Box>

            <Paragraph>
                En terminant, nous tenons à vous remercier grandement pour votre compréhension, votre patience et votre
                fidèle soutien au cours de la dernière année.
            </Paragraph>

            <Typography mt={2}>
                <i>Marc Leblond, président</i>
            </Typography>
            <Typography mt={0.4}>
                <i>Mélissa Jacques, vice-présidente</i>
            </Typography>
        </Box>
    );
};

function Subtitle({ children }) {
    return (
        <Typography variant="h6" mt={3} mb={1}>
            {children}
        </Typography>
    );
}

function Paragraph({ children }) {
    return <Typography mb={2}>{children}</Typography>;
}
