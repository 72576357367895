import { gql } from '@apollo/client';
import {
    FactLigneFields,
    CodeFactFields,
    FactLigneElmContxFields,
    ElementContxFields,
    FactLigneElmMesureFields,
    ElementMesureFields,
    FactLigneFdFields,
    FactLigneFdFraisFields,
} from 'apollo/fragments';

export const GET_FACTURE_COMPLETE = gql`
    ${FactLigneFields}
    ${CodeFactFields}
    ${FactLigneElmContxFields}
    ${ElementContxFields}
    ${FactLigneElmMesureFields}
    ${ElementMesureFields}
    ${FactLigneFdFields}
    ${FactLigneFdFraisFields}

    query GetFacture($reqt_id: Int) {
        facture(reqt_id: $reqt_id) {
            nce
            revision

            FactLignes {
                ...FactLigneFields

                CodeFact {
                    ...CodeFactFields
                }

                FactLigneElmContx {
                    ...FactLigneElmContxFields

                    ElementContx {
                        ...ElementContxFields
                    }
                }

                FactLigneElmMesure {
                    ...FactLigneElmMesureFields

                    ElementMesure {
                        ...ElementMesureFields
                    }
                }

                FactLigneFd {
                    ...FactLigneFdFields
                }

                FactLigneFdFrais {
                    ...FactLigneFdFraisFields
                }
            }
        }
    }
`;

export const GET_FACTURE_DAILY_COMPLETE = gql`
    ${FactLigneFields}
    ${CodeFactFields}
    ${FactLigneElmContxFields}
    ${ElementContxFields}
    ${FactLigneElmMesureFields}
    ${ElementMesureFields}
    ${FactLigneFdFields}
    ${FactLigneFdFraisFields}

    query GetFacture($reqt_id: Int) {
        facture(reqt_id: $reqt_id) {
            nce
            revision

            FactLignes {
                ...FactLigneFields

                CodeFact {
                    ...CodeFactFields
                }

                FactLigneElmContx {
                    ...FactLigneElmContxFields

                    ElementContx {
                        ...ElementContxFields
                    }
                }

                FactLigneElmMesure {
                    ...FactLigneElmMesureFields

                    ElementMesure {
                        ...ElementMesureFields
                    }
                }

                FactLigneFd {
                    ...FactLigneFdFields
                }

                FactLigneFdFrais {
                    ...FactLigneFdFraisFields
                }
            }
        }
    }
`;
