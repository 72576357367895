import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';

export const GET_MD_EARNINGS_GRAPH_DATA = gql`
    query getMdEarningsGraphData($start_date: Date, $end_date: Date) {
        mdEarningsGraphData(start_date: $start_date, end_date: $end_date) {
            label
            date_ec
            value
        }
    }
`;

export const GET_MD_PAYMENTS = gql`
    query getMdPayments {
        mdPayments {
            id
            created_at
            updated_at
            md_id
            txn_recorded_at
            method
            ref_no
            amt

            METHOD_NAME
        }
    }
`;

export const GET_MD_EC_STATS = gql`
    query getMdEcStats($which: String) {
        mdEcStats(which: $which) {
            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement
            date_paiement
            total_prelim
            total_final
            facture_count
            patient_count
            days_billed
        }
    }
`;
