import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdGrpFields } from 'apollo/fragments';

export const GET_POSTES = gql`
    query GetPostes {
        postes(only_own: true) {
            numero
            modulateur
            description
        }
    }
`;

export const GET_MD_GRPS = gql`
    ${MdGrpFields}

    query GetMdGrps {
        md {
            MdGrps {
                ...MdGrpFields
            }
        }
    }
`;
