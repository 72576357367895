import { gql } from '@apollo/client';

export const GET_EC_OVERVIEWS = gql`
    query GetEcOverviews {
        ecOverviews {
            id
            licence
            grp
            ec_id
            fmp_id
            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement
            grp_nom
            is_grp_inc
            total_brut
            total_deduction
            total_cotisation
            total_cotisation_cum
            total_transfert_grp
            interet_sur_recouvrement
            interet_sur_avance
            avances_remises
            avances_recouvrement
            interet_sur_solde_neg
            solde_reporte
            solde_a_reporte
            modif_de_solde
            paiement_recu_solde
            debut_interet_sur_solde_neg
            montant_net
            no_cheque_ou_virement
            mode_paiement
        }
    }
`;
