import React, { useEffect } from 'react';
import { TextField } from '@mui/material';
import { Control, FormState, useController } from 'react-hook-form';

interface SelectControlProps {
    name: string;
    control: Control<any, any>;
    errors?: FormState<any>['errors'];
    options?: { label?: string; helperText?: string };
    dropdownOptions: React.ReactElement[];
}

export function SelectControl({ name, control, errors, options = {}, dropdownOptions }: SelectControlProps) {
    const { label } = options;

    const {
        field: { value, onChange },
    } = useController({ name, control });

    return (
        <TextField
            label={label || ''}
            size="small"
            fullWidth
            select
            onChange={(e) => {
                const newValue = e.target.value || null;
                onChange(newValue);
            }}
            value={value || ''}
            {...options}
        >
            {dropdownOptions}
        </TextField>
    );
}
