import * as React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Outlet, useNavigate } from 'react-router';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';

// MUI Components
import {
    Avatar,
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    Box,
    Drawer as MuiDrawer,
    Badge,
    Chip,
    ChipProps,
    ListSubheader,
    Menu,
    MenuItem,
    Toolbar,
    List,
    CssBaseline,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
    useMediaQuery,
} from '@mui/material';

// MUI Icons
import {
    Analytics as AnalyticsIcon,
    Mail as MailIcon,
    Inbox as InboxIcon,
    DashboardOutlined as DashboardOutlinedIcon,
    Storage as StorageIcon,
    Notifications as NotificationsIcon,
    AccountBalance as AccountBalanceIcon,
    BarChart as BarChartIcon,
    BusinessOutlined as BusinessOutlinedIcon,
    Person as PersonIcon,
    PieChart as PieChartIcon,
    TimelineOutlined as TimelineOutlinedIcon,
    Receipt as ReceiptIcon,
    Chat as ChatIcon,
    DashboardCustomize as DashboardCustomizeIcon,
    AssignmentTurnedIn as AssignmentTurnedInIcon,
    FormatListNumbered as FormatListNumberedIcon,
    ContentPaste as ContentPasteIcon,
    Brightness4 as Brightness4Icon,
    Brightness7 as Brightness7Icon,
    AccountCircle as AccountCircleIcon,
    More as MoreIcon,
    ReplyAll as ReplyAllIcon,
    DocumentScanner as DocumentScannerIcon,
    Summarize as SummarizeIcon,
    Event as EventIcon,
    GroupsOutlined as GroupsOutlinedIcon,
    DateRange as DateRangeIcon,
    AttachMoney as AttachMoneyIcon,
    ReceiptLongOutlined as ReceiptLongOutlinedIcon,
    ManageAccountsOutlined as ManageAccountsOutlinedIcon,
    LocalHospital as LocalHospitalIcon,
    TopicOutlined as TopicOutlinedIcon,
    RequestQuoteOutlined as RequestQuoteOutlinedIcon,
    CalendarMonthOutlined as CalendarMonthOutlinedIcon,
    Work as WorkIcon,
    CasesOutlined as CasesOutlinedIcon,
    HeartBrokenOutlined as HeartBrokenOutlinedIcon,
    DescriptionOutlined as DescriptionOutlinedIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon,
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    SearchOutlined as SearchOutlinedIcon,
} from '@mui/icons-material';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { LOGOUT_MD } from './apollo-queries';

// Redux
import { useAppSelector, useAppDispatch } from 'redux/hooks';
import { setIsThemeDark } from 'redux/features/general/generalSlice';

// Services
import { Auth } from 'services';
import { useAuth } from 'providers/AuthProvider/context';
import { LOGIN_ROUTE } from 'router';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    // [theme.breakpoints.down('sm')]: {
    //     width: `calc(${theme.spacing(8)} + 1px)`,
    //     paddingLeft: theme.spacing(0.5),
    // },
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(8)} + 1px)`,
    paddingLeft: theme.spacing(0.5),
    [theme.breakpoints.down('tablet')]: {
        width: `0px`,
        padding: '0px',
    },
    [theme.breakpoints.up('tablet')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const drawerItems = [
    // {
    //     subheader: {
    //         title_fr: 'Gestion',
    //         title_en: 'Gestion',
    //     },
    //     items: [
    //         {
    //             title_fr: 'États compte',
    //             title_en: 'États compte',
    //             icon: <DashboardIcon />,
    //             navigateTo: 'home',
    //         },
    //         {
    //             title_fr: 'Rapports',
    //             title_en: 'Rapports',
    //             icon: <TimelineOutlinedIcon />,
    //             navigateTo: 'home',
    //         },
    //         {
    //             title_fr: 'Alertes',
    //             title_en: 'Alertes',
    //             icon: <NotificationsIcon />,
    //             navigateTo: 'home',
    //         },
    //         {
    //             title_fr: 'Calendrier réclamations',
    //             title_en: 'Calendrier réclamations',
    //             icon: <EventIcon />,
    //             navigateTo: 'home',
    //         },
    //     ],
    // },
    {
        subheader: {
            title_fr: '',
            title_en: '',
        },
        items: [
            {
                title_fr: 'Tableau de bord',
                title_en: 'Tableau de bord',
                icon: <DashboardOutlinedIcon />,
                navigateTo: 'tableau-de-bord',
            },
            {
                title_fr: 'Statistiques',
                title_en: 'Statistiques',
                icon: <TimelineOutlinedIcon />,
                navigateTo: 'ec',
                disabled: true,
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Facturation RAMQ',
            title_en: 'Facturation RAMQ',
        },
        items: [
            {
                title_fr: 'États de comptes',
                title_en: 'États de comptes',
                icon: <RequestQuoteOutlinedIcon />,
                navigateTo: 'etats-de-compte',
            },
            {
                title_fr: 'Calendrier réclamations',
                title_en: 'Calendrier réclamations',
                icon: <CalendarMonthOutlinedIcon />,
                navigateTo: 'calendrier-reclamations',
            },
            {
                title_fr: 'Recherche réclamations',
                title_en: 'Recherche réclamations',
                icon: <SearchOutlinedIcon />,
                navigateTo: 'demandes',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Rapports',
            title_en: 'Rapports',
        },
        items: [
            {
                title_fr: 'Rapports',
                title_en: 'Rapports',
                icon: <DescriptionOutlinedIcon />,
                navigateTo: 'rapports',
                isNew: false,
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Compte',
            title_en: 'Compte',
        },
        items: [
            {
                title_fr: 'Profil',
                title_en: 'Profil',
                icon: <ManageAccountsOutlinedIcon />,
                navigateTo: '/profil/informations-personnelles',
                disabled: true,
            },
            {
                title_fr: 'Factures',
                title_en: 'Factures',
                icon: <ReceiptLongOutlinedIcon />,
                navigateTo: 'factures',
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Patients',
            title_en: 'Patients',
        },
        items: [
            {
                title_fr: 'Patients',
                title_en: 'Patients',
                icon: <GroupsOutlinedIcon />,
                navigateTo: 'mds',
                disabled: true,
            },
            {
                title_fr: 'Vulnérabilités',
                title_en: 'Vulnérabilités',
                icon: <HeartBrokenOutlinedIcon />,
                navigateTo: 'mds',
                disabled: true,
            },
        ],
    },
    {
        subheader: {
            title_fr: 'Affectations',
            title_en: 'Affectations',
        },
        items: [
            {
                title_fr: 'Groupes',
                title_en: 'Groupes',
                icon: <CasesOutlinedIcon />,
                navigateTo: 'groupes',
                disabled: true,
            },
            {
                title_fr: 'Établissements',
                title_en: 'Établissements',
                icon: <BusinessOutlinedIcon />,
                navigateTo: 'postes',
                disabled: true,
            },
        ],
    },
    // {
    //     subheader: {
    //         title_fr: 'Administration clinique',
    //         title_en: 'Administration clinique',
    //     },
    //     items: [
    //         {
    //             title_fr: 'Utilisateurs',
    //             title_en: 'Utilisateurs',
    //             icon: <StorageIcon />,
    //             navigateTo: 'admin',
    //         },
    //         {
    //             title_fr: 'Rôles & perms.',
    //             title_en: 'Roles & perms.',
    //             icon: <StorageIcon />,
    //             navigateTo: 'home',
    //         },
    //     ],
    // },
];

export default function MiniDrawer() {
    const auth = useAuth();
    const theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();

    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(isLaptop);
    }, [isLaptop]);

    const general = useAppSelector((state) => state.general);
    const dispatch = useAppDispatch();

    // const { loading: medecinLoading, error: medecinError, data: medecinData } = useQuery(GET_MEDECIN, {});

    // const [logoutMd, { loading: logoutMdLoading, error: logoutMdError, data: logoutMdData }] = useLazyQuery(LOGOUT_MD, {
    //     onCompleted: ({ logoutMd }) => {
    //         Auth.logout();
    //         navigate('/login');
    //     },
    // });

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleLogout = () => {
        logoutMd();
    };

    const [logoutMd] = useLazyQuery(LOGOUT_MD, {
        onCompleted: async () => {
            await auth.logout();
            // navigate(LOGIN_ROUTE);
        },
    });

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose} disabled>
                <Avatar sx={{ width: 30, height: 30, mr: 2 }} />
                Mon compte
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Déconnexion
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMenuClose} disabled>
                <Avatar sx={{ width: 30, height: 30, mr: 2 }} />
                Mon compte
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Déconnexion
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        {/* Bonjour {auth.drPrefix} {auth.md!.last_name} */}
                        Facturation Médicale Paradis
                    </Typography>
                    {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
                    </Search> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} color="error">
                                <MailIcon />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                            <Badge badgeContent={17} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        {/* <IconButton
                            sx={{ ml: 1 }}
                            onClick={() => dispatch(setIsThemeDark(!general.isThemeDark))}
                            color="inherit"
                        >
                            {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton> */}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircleIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}

            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    {/* <Box display="flex" justifyContent="flex-start" alignItems="center" py={2} px={1}>
                        <Typography variant="h6">FMPARADIS</Typography>
                    </Box> */}
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider sx={{ mb: 2 }} />

                {open && (
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={4}>
                        {/* <Avatar src="" sx={{ width: 60, height: 60 }}></Avatar> */}
                        <Typography fontWeight="500" sx={{ mt: 2 }}>
                            {auth.drPrefix} {auth.md!.first_name} {auth.md!.last_name}
                        </Typography>
                        <Typography fontWeight="500" sx={{ mt: 0.5 }}>
                            {auth.md!.id}
                        </Typography>
                    </Box>
                )}

                {drawerItems.map((drawerItem, drawerItemIdx) => {
                    const {
                        subheader: { title_fr },
                        items,
                    } = drawerItem;

                    return (
                        <List key={drawerItemIdx} sx={{ mt: 0 }}>
                            {open && <ListSubheader>{title_fr}</ListSubheader>}
                            {items.map((item, itemIdx) => {
                                const { title_fr, icon, navigateTo, disabled, isNew } = item;

                                const isSelected = location.pathname.split('/')[1] === navigateTo;

                                return (
                                    <ListItemButton
                                        key={itemIdx}
                                        component={NavLink}
                                        to={navigateTo}
                                        selected={isSelected}
                                        disabled={process.env.NODE_ENV !== 'development' && disabled ? true : false}
                                        onClick={() => setOpen(isLaptop ? open : false)}
                                        // disabled={ disabled}
                                    >
                                        <ListItemIcon sx={{ minWidth: 50 }}>{icon}</ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    {title_fr}
                                                    {isNew && (
                                                        <Chip
                                                            label="Nouveautés"
                                                            size="small"
                                                            variant="filled"
                                                            sx={(theme) => ({
                                                                backgroundColor: theme.palette.blue1.main,
                                                                ml: 1.5,
                                                                fontSize: 10,
                                                                py: 0,
                                                                pointerEvents: 'none',
                                                            })}
                                                        />
                                                    )}
                                                </Typography>
                                            }
                                        />
                                    </ListItemButton>
                                );
                            })}
                            {/* <Divider /> */}
                        </List>
                    );
                })}
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
                <DrawerHeader />

                <Outlet />

                {/* <Typography paragraph>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
                    enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
                    imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
                    Convallis convallis tellus id interdum velit laoreet id donec ultrices.
                    Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
                    adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
                    nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
                    leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
                    feugiat vivamus at augue. At augue eget arcu dictum varius duis at
                    consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
                    sapien faucibus et molestie ac.
                </Typography>
                <Typography paragraph>
                    Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
                    eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
                    neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
                    tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
                    sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
                    tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
                    gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
                    et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
                    tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
                    eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
                    posuere sollicitudin aliquam ultrices sagittis orci a.
                </Typography> */}
            </Box>
        </Box>
    );
}
