import React, { useState, useMemo } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

// MUI Components
import { Avatar, Box, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { useTheme, darken, lighten } from '@mui/material/styles';

// MUI Grid

// MUI Icons
import {
    SettingsOutlined as SettingsOutlinedIcon,
    PersonOutlined as PersonOutlinedIcon,
    PaymentOutlined as PaymentOutlinedIcon,
    LockOutlined as LockOutlinedIcon,
} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import PersonalInfo from './PersonalInfo';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_MD } from './apollo-queries';
import { useNavigate, Outlet } from 'react-router';

// Redux

// Services
import { useAuth } from 'providers/AuthProvider/context';

// Constants

// Utils

const LIST_ITEMS = [
    {
        title: 'Informations personnelles',
        icon: <PersonOutlinedIcon fontSize="small" />,
        content: <PersonalInfo />,
        navigateTo: '/profil/informations-personnelles',
    },
    {
        title: 'Préférences paiements',
        icon: <PaymentOutlinedIcon fontSize="small" />,
        content: <PersonalInfo />,
        navigateTo: '/profil/preferences-paiements',
    },
    {
        title: 'Mot de passe',
        icon: <LockOutlinedIcon fontSize="small" />,
        content: <PersonalInfo />,
        navigateTo: '/profil/mot-de-passe',
    },
    {
        title: 'Paramètres',
        icon: <SettingsOutlinedIcon fontSize="small" />,
        content: <PersonalInfo />,
        navigateTo: '/profil/parametres',
    },
];

export default function Profile() {
    const auth = useAuth();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    // Apollo queries
    // const { loading: mdLoading, error: mdError, data: mdData } = useQuery(GET_MD, {});
    // const md = mdData?.md || {};

    return (
        <Grid container spacing={3}>
            <Grid item xs={2.7}>
                <Paper sx={{ p: 2 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={4}>
                        <Avatar src="" sx={{ width: 70, height: 70 }}></Avatar>

                        <Typography variant="h5" fontWeight="500" sx={{ mt: 2 }}>
                            {auth.drPrefix} {auth.md!.first_name} {auth.md!.last_name}
                        </Typography>
                        <Typography variant="h6" fontWeight="500" sx={{ mt: 0.5 }}>
                            Omnipraticien
                        </Typography>
                        {/* <Typography fontWeight="500" sx={{ mt: 0.5 }}>
                            {md.id}
                        </Typography> */}
                    </Box>

                    <List>
                        {LIST_ITEMS.map((item, idx) => {
                            const { title, icon, navigateTo } = item;

                            const isSelected = location.pathname === navigateTo;

                            return (
                                <ListItemButton
                                    key={idx}
                                    component={Link}
                                    to={navigateTo}
                                    // onClick={() => navigate(navigateTo, { replace: false })}
                                    selected={isSelected}
                                >
                                    <ListItemIcon>{icon}</ListItemIcon>
                                    <ListItemText>{title}</ListItemText>
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Paper>
            </Grid>

            <Grid item xs={9.3}>
                <Outlet />
            </Grid>
        </Grid>
    );
}
