import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { workshiftsData } from 'redux/data';

export type WorkshiftsState = ({} & typeof workshiftsData[0])[];

const initialState: WorkshiftsState = workshiftsData;

const workshiftsSlice = createSlice({
    name: 'workshifts',
    initialState,
    reducers: {
        addWorkshift(state, action: PayloadAction<WorkshiftsState[0]>) {
            state.push(action.payload);
        },
        updateWorkshift(state, action: PayloadAction<WorkshiftsState[0]>) {
            let existingWorkshiftIdx = state.findIndex((workshift) => {
                return workshift.id === action.payload.id;
            });
            if (existingWorkshiftIdx !== undefined) {
                state[existingWorkshiftIdx] = action.payload;
            }
        },
        deleteWorkshift(state, action: PayloadAction<WorkshiftsState[0]['id']>) {
            return state.filter((workshift) => workshift.id !== action.payload);
        },
    },
});

export const { addWorkshift, updateWorkshift, deleteWorkshift } = workshiftsSlice.actions;

export default workshiftsSlice.reducer;
