import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Container,
    useTheme,
    lighten,
    Alert,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useForm, Controller, useController } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

// Services
import { Auth } from 'services';

// Providers
import { useAuth } from 'providers/AuthProvider/context';

// Router
import { FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE, MAIN_PAGE_WHEN_AUTHENTICATED } from 'router';

// Components
import GuestLayout from 'components/GuestLayout';

const LOGIN_MD = gql`
    mutation LoginMd($licence: Int!, $password: String!) {
        loginMd(licence: $licence, password: $password) {
            type
            token
            expiresAt
            expiresIn
        }
    }
`;

export default function Login() {
    const navigate = useNavigate();
    const auth = useAuth();
    const theme = useTheme();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            licence: '',
            password: '',
        },
    });

    const [loginMd, { loading: loginMdLoading, error: loginMdError, data: loginMdData }] = useMutation(LOGIN_MD, {
        onCompleted: async ({ loginMd }) => {
            if (loginMd?.token) {
                await auth.login(loginMd.token);
                // navigate(LOGIN_ROUTE);
            }
        },
    });

    if (loginMdError) {
        console.log(loginMdError);
        console.log(loginMdError.graphQLErrors);
        console.log(loginMdError.graphQLErrors[0].extensions.code);
    }

    const onSubmit = async (data) => {
        loginMd({
            variables: {
                licence: parseInt(data.licence) || 0,
                password: data.password,
            },
        });
    };

    return (
        <GuestLayout>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Box display="flex" justifyContent="center">
                            <img
                                src="/logo-blanc.jpg"
                                alt=""
                                style={{
                                    display: 'block',
                                    height: '70px',
                                }}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} mb={4}>
                        <Typography variant="h6" textAlign="center">
                            Bienvenue sur le portail sécurisé!
                        </Typography>

                        <Typography variant="body1" textAlign="center" mt={1}>
                            Veuillez vous connecter pour continuer.
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Alert severity="info">
                            Pour votre première connexion, veuillez dans un premier temps réinitialiser votre mot de
                            passe en cliquant sur le lien sécurisé reçu par courriel le 20 juin.
                        </Alert>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Controller
                            name="licence"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    label="Licence à 7 chiffres"
                                    size="small"
                                    fullWidth
                                    type="number"
                                    onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                                <TextField label="Mot de passe" size="small" fullWidth {...field} type="password" />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <FormControlLabel control={<Checkbox />} label="Se souvenir de moi" />
                    </Grid>

                    {loginMdError && (
                        <Grid item xs={12} mt={2}>
                            <Typography color="red" textAlign="center">
                                {loginMdError.message}
                            </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12} mt={2}>
                        <Button type="submit" variant="contained" fullWidth disabled={loginMdLoading}>
                            Connexion
                        </Button>
                    </Grid>

                    <Grid item xs={12} mt={0} container justifyContent="center">
                        <Button variant="text" component={RouterLink} to={FORGOT_PASSWORD_ROUTE}>
                            J'ai oublié mon mot de passe
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </GuestLayout>
    );
}
