// MUI Components
import { Breadcrumbs, Grid, Typography } from '@mui/material';

// MUI Grid

// MUI Icons
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import RapportCalculLoyerForm from './RapportCalculLoyerForm';
import RapportFraisFacturationForm from './RapportFraisFacturationForm';
import RapportRevenusForm from './RapportRevenusForm';
import RapportVisitesPeriodiquesForm from './RapportVisitesPeriodiquesForm';
import RapportDiabeteTrimestrielForm from './RapportDiabeteTrimestrielForm';
import RapportDiabeteAnnuelForm from './RapportDiabeteAnnuelForm';

// Apollo

// Redux

// Utils

// Constants

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Autre
    </Typography>,
    <Typography key="1" fontSize={15}>
        Rapports
    </Typography>,
];

export default function ReportList() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h5" fontWeight="600">
                    Rapports
                </Typography>
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    sx={{ mt: 0.5, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
                >
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>

            <Grid container rowSpacing={8}>
                <Grid item xs={12}>
                    <RapportCalculLoyerForm />
                </Grid>

                <Grid item xs={12}>
                    <RapportVisitesPeriodiquesForm />
                </Grid>

                <Grid item xs={12}>
                    <RapportFraisFacturationForm />
                </Grid>

                <Grid item xs={12}>
                    <RapportRevenusForm />
                </Grid>

                <Grid item xs={12}>
                    <RapportDiabeteTrimestrielForm />
                </Grid>

                <Grid item xs={12}>
                    <RapportDiabeteAnnuelForm />
                </Grid>
            </Grid>
        </Grid>
    );
}
