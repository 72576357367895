import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tab,
} from '@mui/material';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { ArrowForward as ArrowForwardIcon, Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_FACTURES_DAILY } from './apollo-queries';

// Redux

// Utils
import { formatNumber } from 'utils';
import { Paper } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ForfaitTabPanel from './ForfaitTabPanel';
import FacturationTabPanel from './FacturationTabPanel';

// Constants
interface EventDialogProps {
    date_service: Moment | null;
    poste_id: string | null;
    patients_count: number;
    total_prelim: number;
    total_final: number;
    handleCloseDialog: () => void;
}

interface EventDialogContentProps {
    factures: any;
    date_service: Moment;
    poste_id: string | null;
    patients_count: number;
    total_prelim: number;
    total_final: number;
    handleCloseDialog: () => void;
}

export default function EventDialog({
    date_service,
    poste_id,
    patients_count,
    total_prelim,
    total_final,
    handleCloseDialog,
}: EventDialogProps) {
    // Apollo queries
    const {
        loading: facturesLoading,
        error: facturesError,
        data: facturesData,
    } = useQuery(GET_FACTURES_DAILY, {
        variables: { date_service: date_service, poste_id: poste_id },
        skip: !date_service || !poste_id,
    });

    const factures = facturesData?.facturesDaily;

    const isDialogOpen = date_service && poste_id ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'xl'} open={isDialogOpen} onClose={handleCloseDialog}>
            {facturesLoading || !date_service ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                    <Loading />
                </Box>
            ) : (
                <EventDialogContent
                    factures={factures}
                    date_service={date_service}
                    poste_id={poste_id}
                    patients_count={patients_count}
                    total_prelim={total_prelim}
                    total_final={total_final}
                    handleCloseDialog={handleCloseDialog}
                />
            )}
        </Dialog>
    );
}

const EventDialogContent = ({
    factures,
    date_service,
    poste_id,
    patients_count,
    total_prelim,
    total_final,
    handleCloseDialog,
}: EventDialogContentProps) => {
    const [tabIndex, setTabIndex] = React.useState('facturation');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    if (!factures) return <></>;

    return (
        <TabContext value={tabIndex}>
            <DialogTitle>
                Détails de facturation du {moment(date_service).format('LL')} (Poste #{poste_id})
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 4 }}>
                    <TabList
                        onChange={onTabChange}
                        variant="scrollable"
                        // scrollButtons
                        allowScrollButtonsMobile
                    >
                        <Tab label="Réclamations" value="facturation" />
                        <Tab label="Détails forfaits" value="forfait" />
                        {/* <Tab label="Vue horaire" value="horaire" />
                        <Tab label="Détails patients" value="patient" /> */}
                    </TabList>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={{}}>
                        <FacturationTabPanel
                            tabIndexAsString="facturation"
                            factures={factures}
                            patients_count={patients_count}
                            total_prelim={total_prelim}
                            total_final={total_final}
                            date_service={date_service}
                        />
                        <ForfaitTabPanel tabIndexAsString="forfait" factures={factures} />
                        <TabPanel value="horaire">TODO HORAIRE</TabPanel>
                        <TabPanel value="patient">TODO PATIENT</TabPanel>

                        {/*<BillingTabPanel tabIndexAsString="facturation-interne" medecin={medecin} />
                            <ContactTabPanel tabIndexAsString="adresses" medecin={medecin} />
                            <RamqTabPanel tabIndexAsString="facturation-ramq" medecin={medecin} />
                            <GrpTabPanel tabIndexAsString="groupes" medecin={medecin} />
                            <GrpPstTabPanel tabIndexAsString="postes" medecin={medecin} /> */}
                        {/* <GmfTabPanel tabIndexAsString="gmf" medecin={medecin} /> */}
                        {/* <PstCaTabPanel tabIndexAsString="pstca" medecin={medecin} /> */}
                        {/* <PratiqueGroupeMdTabPanel tabIndexAsString="groupes-pratique" medecin={medecin} />
                            <DmeSiteMdTabPanel tabIndexAsString="dme" medecin={medecin} />
                            <IdentifiantUtilProfTabPanel tabIndexAsString="idutil" medecin={medecin} /> */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Fermer</Button>
            </DialogActions>
        </TabContext>
    );
};
