import React from 'react';
import { IconProps, SxProps } from '@mui/material';
import { Male as MaleIcon, Female as FemaleIcon } from '@mui/icons-material';

interface SexIconProps {
    nam: string | null | undefined;
    fontSize?: IconProps['fontSize'];
    sx?: SxProps;
}

export default function SexIcon(props: SexIconProps) {
    let { nam, fontSize, sx } = props;

    fontSize = fontSize || 'small';

    const sxProps = sx || {};

    if (nam) {
        if (parseInt(nam.substring(6, 8)) > 12) {
            return (
                <FemaleIcon
                    fontSize={fontSize}
                    sx={{
                        color: 'pink.main',
                        ...sxProps,
                    }}
                />
            );
        } else {
            return (
                <MaleIcon
                    fontSize={fontSize}
                    sx={{
                        color: 'blue.main',
                        ...sxProps,
                    }}
                />
            );
        }
    } else {
        return <></>;
    }
}
