import React from 'react';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface CheckboxControlProps {
    name: string;
    control: Control<any, any>;
    options?: { label?: string };
}

export function CheckboxControl({ name, control, options = {} }: CheckboxControlProps) {
    const { label } = options;

    const {
        field: { onChange, value },
    } = useController({ name, control });

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value === undefined ? null : value}
                    onChange={(e) => onChange(e.target.checked)}
                    size="small"
                />
            }
            label={label || ''}
        />
    );
}
