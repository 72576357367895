import { gql } from '@apollo/client';
import { FactLigneVacFields, FactLigneVacDetailsFields } from 'apollo/fragments';

export const GET_FACTURE_COMPLETE = gql`
    ${FactLigneVacFields}
    ${FactLigneVacDetailsFields}

    query GetFacture($reqt_id: Int) {
        facture(reqt_id: $reqt_id) {
            nce
            revision

            FactLignesVac {
                ...FactLigneVacFields

                FactLigneVacDetails {
                    ...FactLigneVacDetailsFields
                }
            }
        }
    }
`;

export const GET_FACTURE_DAILY_COMPLETE = gql`
    ${FactLigneVacFields}
    ${FactLigneVacDetailsFields}

    query GetFacture($reqt_id: Int) {
        facture(reqt_id: $reqt_id) {
            nce
            revision

            FactLignesVac {
                ...FactLigneVacFields

                FactLigneVacDetails {
                    ...FactLigneVacDetailsFields
                }
            }
        }
    }
`;
