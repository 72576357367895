import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';

export const GET_FACT_VACS = gql`
    query GetFactVacs($created_at: Date, $date_service: Date, $ec_id: Int, $pst_id: String, $payment_status: String) {
        factVacs(
            created_at: $created_at
            date_service: $date_service
            ec_id: $ec_id
            pst_id: $pst_id
            payment_status: $payment_status
        ) {
            created_at
            nce
            revision
            reqt_id
            licence
            pst_no
            pst_mod
            pst_name
            statut_recv
            is_canceled
            lot_id
            fmp_ec_id
            ec_id

            debut_periode
            fin_periode
            hrs_activite
            mins_activite
            cs1
            cs2
            nom_mand
            date_mand
            nom_sign
            date_sign
            once
            attestation

            pst_depart
            pst_arrivee
            dh_arrivee
            hrs_depl
            mins_depl
            kms_depl
            montant_transport_reclame

            date_service_min
            date_service_max
            date_service_agg

            payment_status

            ec_1717ig_agg

            hrs_activite_payes
            total_remu_activite
            hrs_depl_payes
            total_remu_temps_depl
            kms_transport_payes
            total_remu_cout_transport
            total_remu

            line_count

            PAYMENT_STATUS_NAME
        }
    }
`;
