import { IClaim, IDoctor } from 'redux/data';
import { Theme } from '@mui/material/styles';
import Decimal from 'decimal.js';

export const capitalize = (string: string | null) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

interface FormatNumberOptionsProp {
    cents?: boolean;
}
export const formatNumber = (number: number, options?: FormatNumberOptionsProp) => {
    if (number === null || number === undefined) number = 0;

    return (options?.cents ? number / 100 : number)
        .toLocaleString('fr-CA', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        .replace(',', '.');
};

export const roundNumber = (number: number, decimalPlaces: number = 2) => {
    // return Math.round((number + Number.EPSILON) * 100) / 100;
    // return +(Math.round(number + 'e+2') + 'e-2');
    // return Number(number.toFixed(2));

    const value = Number(
        Math.round((Math.abs(number) + 'e' + decimalPlaces) as unknown as number) + 'e-' + decimalPlaces,
    );

    return number < 0 ? -value : value;
};

export const getRandomNumberBetween = (min: number, max: number, fractionDigits = 0, inclusive = true) => {
    const precision = Math.pow(10, Math.max(fractionDigits, 0));
    const scaledMax = max * precision;
    const scaledMin = min * precision;
    const offset = inclusive ? 1 : 0;
    const num = Math.floor(Math.random() * (scaledMax - scaledMin + offset)) + scaledMin;

    return num / precision;
};

export const getRandomImageUrl = () => {
    return 'https://i.pravatar.cc/100?u=' + getRandomNumberBetween(1, 10000);
};

export const getBadgeBackgroundColorFromStatus = (theme: Theme, status: IClaim['status']) => {
    const DARK_THEME_BACKGROUND_COLOR = theme.palette.badge.main;

    if (theme.palette.mode === 'dark') {
        return DARK_THEME_BACKGROUND_COLOR;
    }

    switch (status) {
        case 'Draft':
            return theme.palette.gray1Light.main;
        case 'Sent':
            return theme.palette.yellow1Light.main;
        case 'Processed':
            return theme.palette.blue1Light.main;
        case 'Paid':
            return theme.palette.green1Light.main;
        case 'Problematic':
            return theme.palette.red1Light.main;
        default:
            return theme.palette.primary.main;
    }
};

export const getBadgeTextColorFromStatus = (theme: Theme, status: IClaim['status']) => {
    switch (status) {
        case 'Draft':
            return theme.palette.gray1Dark.main;
        case 'Sent':
            return theme.palette.yellow1Dark.main;
        case 'Processed':
            return theme.palette.blue1Dark.main;
        case 'Paid':
            return theme.palette.green1Dark.main;
        case 'Problematic':
            return theme.palette.red1Dark.main;
        default:
            return theme.palette.primary.main;
    }
};

export const getStatusAbbreviation = (status: IClaim['status']) => {
    switch (status) {
        case 'Draft':
            return 'Draft';
        case 'Sent':
            return 'Sent';
        case 'Processed':
            return 'Proc.';
        case 'Paid':
            return 'Paid';
        case 'Problematic':
            return 'Prob.';
        default:
            return '...';
    }
};

export const getBadgeBackgroundColorFromDoctorStatus = (theme: Theme, status: IDoctor['status']) => {
    const DARK_THEME_BACKGROUND_COLOR = theme.palette.badge.main;

    if (theme.palette.mode === 'dark') {
        return DARK_THEME_BACKGROUND_COLOR;
    }

    switch (status) {
        case 'active':
            return theme.palette.green1Light.main;
        case 'inactive':
            return theme.palette.red1Light.main;
        default:
            return theme.palette.primary.main;
    }
};

export const getBadgeTextColorFromDoctorStatus = (theme: Theme, status: IDoctor['status']) => {
    switch (status) {
        case 'active':
            return theme.palette.green1Dark.main;
        case 'inactive':
            return theme.palette.red1Dark.main;
        default:
            return theme.palette.primary.main;
    }
};

export const getDoctorStatusName = (status: IDoctor['status'], la: 'fr' | 'en') => {
    switch (status) {
        case 'active':
            return la === 'fr' ? 'Actif' : 'Active';
        case 'inactive':
            return la === 'fr' ? 'Inactif' : 'Inactive';
        default:
            return '';
    }
};

export const getScanStatusColor = (theme: Theme, status: 'todo' | 'done') => {
    switch (status) {
        case 'todo':
            return { bgcolor: theme.palette.red1Light.main, color: theme.palette.red1Dark.main };
        case 'done':
            return { bgcolor: theme.palette.green1Light.main, color: theme.palette.green1Dark.main };
        default:
            return { bgcolor: theme.palette.primary.main, color: theme.palette.primary.contrastText };
    }
};

export const getScanStatusText = (status) => {
    if (status == 'todo') {
        return 'À faire';
    } else if (status == 'done') {
        return 'Facturé';
    } else {
        return '';
    }
};

export const getErrorMsg = (error: any): string => {
    if (!error) return '';

    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        // jsxErrors = error.graphQLErrors.map((item, idx) => {
        //     return (
        //         <Typography key={idx} color="red" textAlign="center">
        //             {`${item.extensions.code}: ${item.message}`}
        //         </Typography>
        //     );
        // });

        const firstError = error.graphQLErrors[0];
        const { extensions, message } = firstError;
        const { code, exception } = extensions;

        if (exception && exception.messages) {
            // Adonis JS validation errors

            const { messages } = exception;

            console.log('Adonis JS errors', messages);

            /**
             * Messages object is like so: {fieldName1: ['msg...', 'msg...', 'msg...'], fieldName2: ['msg...', 'msg...', 'msg...']}
             */

            const errorsJoined = Object.keys(messages).reduce((prevValue, key, idx) => {
                const fieldMsg = messages[key][0] + ` on '${key}'`;

                return (prevValue += `${fieldMsg}${idx + 1 < Object.keys(messages).length ? ' && ' : ''}`);
            }, 'Erreur de validation: ');
            return errorsJoined;
        }

        return `${code}: ${message}`;
    }

    return error.message;
};

export const fmpHoursToRamqHours = (hours: number | null, mins: number | null) =>
    new Decimal(hours || 0).add((mins || 0) / 100).toFixed(2);
