import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useLazyQuery, FetchResult } from '@apollo/client';
import { useNavigate } from 'react-router';

import { AuthContext } from './context';

import { Auth } from 'services';
import { Md } from 'types';

const GET_MD = gql`
    query GetMd {
        md {
            id
            created_at
            updated_at
            licence_sm
            email
            password
            first_name
            last_name
            first_name_smchar
            last_name_smchar
            sex
            retraite_on
            avatar_url
            is_still_client
            can_login
            address1
            address2
            city
            zip
            phone_mobile
            phone_home
            phone_work
            joined_on
            left_on
            balance
        }
    }
`;

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    const [md, setMd] = useState<Md | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        getMd();
    }, []);

    let [
        getMd,
        { loading: getMdLoading, error: getMdError, data: getMdData, called, previousData, refetch: refetchMd },
    ] = useLazyQuery(GET_MD, {
        onCompleted: ({ md }) => {
            setMd(md);
            setLoading(false);
        },
        onError: () => {
            setMd(null);
            setLoading(false);
        },
    });

    let login = async (token: string) => {
        Auth.login(token);

        setLoading(true);
        const res = await refetchMd();
        setMd(res.data.md);
        setLoading(false);

        return true;
    };

    let logout = async () => {
        Auth.logout();
        setMd(null);

        return true;
    };

    const drPrefix = md ? (md.sex === 1 ? 'Dr' : 'Dre') : '';

    let value = {
        md: md,
        error: getMdError,
        isLoading: loading,
        drPrefix,
        login,
        logout,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
