import { gql } from '@apollo/client';
import { EcReportFields } from 'apollo/fragments';

export const GET_EC_REPORT = gql`
    ${EcReportFields}

    query GetEcReport($ec_md_grp_id: Int!) {
        ecReport(ec_md_grp_id: $ec_md_grp_id) {
            ...EcReportFields

            FILE_URL
        }
    }
`;
