import moment from 'moment';

// MUI Components
import { IconButton, Tooltip, Box, CircularProgress } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { Visibility as VisibilityIcon, Download as DownloadIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    DATE_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber } from 'utils';

export const getEcListColumns = (
    handleOpenEcDialog,
    handleDownloadEcFiles,
    ecMdGrpIdsOfCurrentFileDownloads,
    isMobile,
): GridColDefPro[] => [
    {
        field: 'no_paiement',
        headerName: 'No. OR',
        valueGetter: ({ value }) => `OR-${value}`,
        width: 70,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'date_ec',
        headerName: 'Date EC',
        valueGetter: ({ value }) => moment(value).format(SQL_DATE),
        ...DATE_COL_DEFAULTS,
    },
    {
        field: 'date_coupure',
        headerName: 'Date coupure',
        valueGetter: ({ value }) => moment(value).format(SQL_DATE),
        ...DATE_COL_DEFAULTS,
    },
    {
        field: 'grp',
        headerName: 'Groupe',
        width: 100,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'grp_nom',
        headerName: 'Nom Groupe',
        minWidth: DENOMINATION_COLUMN_WIDTH,
        flex: 1,
    },
    {
        field: 'montant_net',
        headerName: 'Montant net versé',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
        headerAlign: 'center',
        align: 'right',
        width: 125,
    },
    {
        field: 'total_brut',
        headerName: 'Montant brut',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
        headerAlign: 'center',
        align: 'right',
        width: 125,
    },
    {
        field: 'total_deduction',
        headerName: 'Déductions',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
        headerAlign: 'center',
        align: 'right',
        width: 125,
    },
    {
        field: 'total_cotisation',
        headerName: 'Cotisations',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
        headerAlign: 'center',
        align: 'right',
        width: 125,
    },
    {
        field: 'total_transfert_grp',
        headerName: 'Transferts groupe',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
        headerAlign: 'center',
        align: 'right',
        width: 125,
    },
    {
        field: 'no_cheque_ou_virement',
        headerName: 'No. paiement',
        width: 100,
        align: 'center',
    },
    {
        field: 'mode_paiement',
        headerName: 'Mode paiement',
        valueGetter: ({ value }) => (value === 'V' ? 'Virement' : value === 'C' ? 'Chèque' : 'N/A'),
        width: 130,
        align: 'center',
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: isMobile ? 60 : 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { id, ec_id, date_ec, no_paiement, grp } = row;

            const isFileDownloadButtonLoading = ecMdGrpIdsOfCurrentFileDownloads.includes(id);

            return (
                <Box display="flex" alignItems="center">
                    {/* <IconButton
                        size="medium"
                        color="primary"
                        onClick={() => {
                            handleOpenEcDialog(id, ec_id);
                        }}
                    >
                        <VisibilityIcon fontSize="small" />
                    </IconButton> */}
                    {isFileDownloadButtonLoading ? (
                        <IconButton size="medium" color="primary" disabled={true}>
                            <CircularProgress size={14} />
                        </IconButton>
                    ) : (
                        <Tooltip
                            title="Télécharger les documents de l'état de compte."
                            placement="left"
                            disableInteractive
                        >
                            <IconButton
                                size="medium"
                                color="primary"
                                onClick={() => {
                                    handleDownloadEcFiles(id, date_ec, no_paiement, grp);
                                }}
                            >
                                <DownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {!isMobile && (
                        <Tooltip
                            title="L'état de compte intéractif sera disponible au courant des prochaines semaines!"
                            disableInteractive
                        >
                            <IconButton
                                size="medium"
                                color="info"
                                // disabled
                                onClick={() => {
                                    return;
                                }}
                            >
                                <VisibilityIcon sx={{ fontSize: 15, mt: -0.3 }} />
                            </IconButton>
                        </Tooltip>
                        // <Tooltip title="Visualiser l'état de compte sous un format interactif." disableInteractive>
                        //     <IconButton
                        //         size="medium"
                        //         color="info"
                        //         // disabled
                        //         onClick={() => {
                        //             handleOpenEcDialog(id);
                        //         }}
                        //     >
                        //         <VisibilityIcon sx={{ fontSize: 15, mt: -0.3 }} />
                        //     </IconButton>
                        // </Tooltip>
                    )}
                </Box>
            );
        },
    },
];
