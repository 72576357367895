import Auth from 'services/Auth';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export default class Api {
    static instance: AxiosInstance = axios;

    static initialize() {
        const axiosConfig: AxiosRequestConfig = {
            baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/md/`,
            timeout: 1000,
        };

        const axiosInstance = axios.create(axiosConfig);

        axiosInstance.interceptors.request.use(
            (config) => {
                if (config?.headers) {
                    config.headers['Authorization'] = `Bearer ${Auth.getAuthToken()}`;
                }

                return config;
            },
            (error) => Promise.reject(error),
        );

        this.instance = axiosInstance;
    }

    static get(url: Parameters<typeof axios['get']>[0], config: Parameters<typeof axios['get']>[1] = {}) {
        return this.instance.get(url, config);
    }
}
