import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';

export const GET_ECS = gql`
    query GetEcs {
        ecs {
            id
            created_at
            updated_at
            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement
            fmp_id
        }
    }
`;

export const GET_POSTES = gql`
    query GetPostes {
        postes(only_own: true) {
            numero
            description
            modulateur
        }
    }
`;

export const GET_TYPES_SERVICE = gql`
    query GetTypesService {
        typesService {
            id
            nom
            nom_abbrev
        }
    }
`;
