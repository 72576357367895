export default class Auth {
    static AUTH_TOKEN_STORAGE_PATH = 'auth-token-md';

    static login(token: string): void {
        this.setAuthToken(token);
    }

    static logout(): void {
        this.removeAuthToken();
    }

    static getAuthToken(): string | null {
        return localStorage.getItem(this.AUTH_TOKEN_STORAGE_PATH);
    }

    static setAuthToken(token: string): void {
        localStorage.setItem(this.AUTH_TOKEN_STORAGE_PATH, token);
    }

    static removeAuthToken(): void {
        localStorage.removeItem(this.AUTH_TOKEN_STORAGE_PATH);
    }
}
