import { useQuery } from '@apollo/client';
import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    lighten,
    TableCell,
    Typography,
    IconButton,
    TableBody,
    Button,
    Tooltip,
    SxProps,
    TypographyProps,
} from '@mui/material';
import Loading from 'components/Loading';
import { SQL_TIME } from 'constants/Moment';
import moment, { Moment } from 'moment';
import { formatNumber } from 'utils';
import { GET_FACTURE_COMPLETE, GET_FACTURE_DAILY_COMPLETE } from './apollo-queries';
import { Help as HelpIcon } from '@mui/icons-material';

export const DETAIL_PANEL_TH_HEIGHT = 33.92 + 55.84;
export const DETAIL_PANEL_TR_HEIGHT = 48.75;
export const DETAIL_PANEL_PY_HEIGHT = 16 * 2;
export const DETAIL_PANEL_PB_BUFFER_HEIGHT = 20;

interface FactFdGridDetailPanelProps {
    daily?: boolean;
    date_service?: Moment;
    params: any;
    containerSx?: SxProps;
    tableRowSx?: SxProps;
    tableRowTextColor?: TypographyProps['color'];
}

export default function FactFdGridDetailPanel({
    daily,
    date_service,
    params: { row },
    containerSx = {},
    tableRowSx = {},
    tableRowTextColor,
}: FactFdGridDetailPanelProps) {
    const { reqt_id } = row;

    // Apollo queries
    const {
        loading: factureLoading,
        error: factureError,
        data: factureData,
    } = useQuery(daily ? GET_FACTURE_DAILY_COMPLETE : GET_FACTURE_COMPLETE, {
        variables: GET_FACTURE_DAILY_COMPLETE ? { reqt_id, date_service } : { reqt_id },
    });

    // const data = daily ? factureData?.factureDaily : factureData?.facture;
    const data = daily ? factureData?.facture : factureData?.facture;

    if (factureLoading || !data) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" pt={3}>
                <Loading />
            </Box>
        );
    } else {
        const { nce, revision } = data;

        return (
            <Box
                sx={{
                    p: 2,
                    pl: 4,
                    pr: 3,
                    height: '100%',
                    ...containerSx,
                }}
            >
                {/* <Typography sx={{ color: 'primary.main' }} mb={1}>
                    ** Pour afficher la description d'un code d'acte, de contexte ou d'élément de mesure, veuillez
                    positionner votre curseur sur le code en question.
                </Typography> */}

                <TableContainer
                    component={Paper}
                    // sx={{ borderRadius: 0 }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ ...tableRowSx }}>
                                <TableCell colSpan={15}>
                                    <Typography
                                        variant="body1"
                                        color={tableRowTextColor || undefined}
                                        fontWeight={500}
                                    >{`#${nce}__${revision}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={60}>Rank</TableCell>
                                <TableCell width={100}>No. ligne</TableCell>

                                <TableCell width={60} align="center">
                                    Acte
                                </TableCell>
                                {/* <TableCell>Date fact</TableCell> */}
                                {/* <TableCell>Rôle</TableCell> */}

                                <TableCell width={300}>Type</TableCell>

                                <TableCell width={300}>Méthode transport</TableCell>

                                <TableCell width={100}>KMs aller</TableCell>

                                <TableCell width={100}>KMs retour</TableCell>

                                <TableCell width={100}>Hres déplace.</TableCell>

                                <TableCell width={100}>Hres attente</TableCell>

                                {/* <TableCell width={80}>Frais</TableCell> */}

                                <TableCell width={280}>Contextes</TableCell>

                                <TableCell width={280}>
                                    <Box display="flex" alignItems="center">
                                        Mesures
                                        <Tooltip title="Mesure ou quantité de l'élément facturé" placement="top">
                                            <IconButton size="small">
                                                <HelpIcon color="info" sx={{ fontSize: 16 }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </TableCell>

                                {/* <TableCell>Code ref</TableCell> */}
                                {/* <TableCell>Codes</TableCell> */}
                                <TableCell width={120} align="right">
                                    Montant prélim
                                </TableCell>
                                <TableCell width={120} align="right">
                                    Montant final
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...data.FactLignes]
                                .sort((a, b) => a.rank - b.rank)
                                .map((factLigne, idx) => {
                                    const {
                                        ligneno,
                                        nce,
                                        revision,
                                        rank,
                                        mdgrppstno,
                                        typelmfact,
                                        codefact,
                                        datefact,
                                        coderole,
                                        debutelmfact,
                                        finelmfact,
                                        coderef,
                                        mntprelim,
                                        mntfinal,
                                        statrecv,

                                        CodeFact,
                                        FactLigneElmContx,
                                        FactLigneElmMesure,
                                        FactLigneFd,
                                        FactLigneFdFrais,
                                    } = factLigne;
                                    const {
                                        codefactno,
                                        entente,
                                        code,
                                        description,
                                        typecode,
                                        debut,
                                        fin,
                                        descriptionsa,
                                    } = CodeFact || {};

                                    const {
                                        // ligneno
                                        typefd,
                                        codetransport,
                                        nbrkmaller,
                                        nbrkmretour,
                                        nbrhrsdeplacement,
                                        nbrhrsattente,

                                        TYPE_FD,
                                        METHODE_TRANSPORT,
                                    } = FactLigneFd || {};

                                    const {
                                        // ligneno,
                                        // rank,
                                        typefrais,
                                        frais,
                                    } = FactLigneFdFrais || {};

                                    return (
                                        <TableRow
                                            key={idx}
                                            sx={{
                                                '&:last-child td, &:last-child th': {
                                                    border: 0,
                                                },
                                            }}
                                            hover={true}
                                        >
                                            <TableCell>{rank}</TableCell>
                                            <TableCell>{ligneno}</TableCell>

                                            <TableCell align="center">
                                                <Tooltip title={description} arrow placement="top" disableInteractive>
                                                    <Button variant="text" size="small" color="primary">
                                                        {codefact}
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>

                                            {/* <TableCell>{datefact}</TableCell> */}
                                            {/* <TableCell>{coderole}</TableCell> */}

                                            <TableCell>{TYPE_FD}</TableCell>

                                            <TableCell>{METHODE_TRANSPORT}</TableCell>

                                            <TableCell>{nbrkmaller}</TableCell>

                                            <TableCell>{nbrkmretour}</TableCell>

                                            <TableCell>{(nbrhrsdeplacement / 100).toFixed(2)}</TableCell>

                                            <TableCell>{(nbrhrsattente / 100).toFixed(2)}</TableCell>

                                            {/* <TableCell>
                                                <Tooltip
                                                    title={
                                                        <>
                                                            <Typography>{typefrais}</Typography>
                                                            <Typography>{frais}</Typography>
                                                        </>
                                                    }
                                                    arrow
                                                    placement="top"
                                                    disableInteractive
                                                >
                                                    <Button variant="text" size="small">
                                                        Infos
                                                    </Button>
                                                </Tooltip>
                                            </TableCell> */}

                                            <TableCell>
                                                {FactLigneElmContx.map((ctx, idx) => {
                                                    const { ligneno, codeelmcontx, ElementContx } = ctx;
                                                    const {
                                                        elementno,
                                                        entente,
                                                        codeelement,
                                                        texte,
                                                        textesa,
                                                        niveau,
                                                        debut,
                                                        fin,
                                                    } = ElementContx || {};

                                                    return (
                                                        <Tooltip
                                                            key={idx}
                                                            title={texte}
                                                            arrow
                                                            placement="top"
                                                            disableInteractive
                                                        >
                                                            <Button variant="text" size="small">
                                                                {codeelmcontx}
                                                            </Button>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </TableCell>

                                            <TableCell>
                                                {FactLigneElmMesure.map((mesure, idx) => {
                                                    const { ligneno, codeelmmesure, valmesure, ElementMesure } = mesure;
                                                    const { code, nom, typeunite } = ElementMesure || {};

                                                    return (
                                                        <Tooltip
                                                            key={idx}
                                                            title={`${nom}: ${valmesure} ${typeunite}`}
                                                            arrow
                                                            placement="top"
                                                            disableInteractive
                                                        >
                                                            <Button variant="text" size="small">
                                                                {codeelmmesure}
                                                            </Button>
                                                        </Tooltip>
                                                    );
                                                })}
                                            </TableCell>

                                            {/* <TableCell>{coderef}</TableCell> */}
                                            {/* <TableCell component="th" scope="row">
                                        {['test1', 'test2'].join(', ')}
                                    </TableCell> */}
                                            <TableCell align="right">{formatNumber(mntprelim)} $</TableCell>
                                            <TableCell align="right">{formatNumber(mntfinal)} $</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
}
