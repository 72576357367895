import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';

export const GET_FACT_SMS = gql`
    query GetFactSms(
        $created_at: Date
        $date_service: Date
        $patient_nam: String
        $ec_id: Int
        $pst_id: String
        $payment_status: String
    ) {
        factSms(
            created_at: $created_at
            date_service: $date_service
            patient_nam: $patient_nam
            ec_id: $ec_id
            pst_id: $pst_id
            payment_status: $payment_status
        ) {
            created_at
            nce
            nci
            revision
            reqt_id
            licence
            pst_no
            pst_mod
            pst_name
            jeton
            statut_recv
            is_canceled
            lot_id
            ec_id

            date_service_min
            date_service_max
            date_service_agg

            line_count
            total_prelim
            total_final
            total_diff

            date_dernier_paiement

            payment_status

            patient_nam

            PAYMENT_STATUS_NAME
        }
    }
`;
