import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actsData } from 'redux/data';

export type ActsState = ({} & typeof actsData[0])[];

const initialState: ActsState = actsData;

const actsSlice = createSlice({
    name: 'acts',
    initialState,
    reducers: {},
});

export default actsSlice.reducer;
