import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme, lighten } from '@mui/material/styles';
import { Document, Page } from 'react-pdf';
import { useElementSize } from 'usehooks-ts';
import FileSaver from 'file-saver';

import './index.css';

// MUI Components
import { Box, Chip, Fab, Grid, Paper, Typography, useMediaQuery, Tooltip, Button, Alert } from '@mui/material';
import { LoadingButton, TabPanel } from '@mui/lab';

// MUI Grid
import {} from '@mui/x-data-grid-pro';

// MUI Icons
import { Download as DownloadIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_EC_REPORT } from './apollo-queries';

// Redux

// Utils

// Constants

interface EcReportTabPanelProps {
    tabIndexAsString: string;
    ecMdGrpId: number;
}

export default function EcReportTabPanel({ tabIndexAsString, ecMdGrpId }: EcReportTabPanelProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    // Apollo queries
    const {
        loading: ecReportLoading,
        error: ecReportError,
        data: ecReportData,
    } = useQuery(GET_EC_REPORT, {
        variables: { ec_md_grp_id: ecMdGrpId },
        skip: !ecMdGrpId,
    });
    const ecReport = ecReportData?.ecReport;

    // PDF Viewer
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    const [ref, { width: pdfWrapperWidth, height: pdfWrapperHeight }] = useElementSize();

    const pdfViewerWidth = useMemo(() => {
        if (isLaptop) {
            return 1000;
        } else {
            return pdfWrapperWidth;
        }
    }, [pdfWrapperWidth, isLaptop]);

    // EC files download
    const [downloadLoading, setIsDownloadLoading] = useState<boolean>(false);
    const handleOnClickDownload = useCallback(async () => {
        setIsDownloadLoading(true);

        try {
            const filename = `Rapport EC.pdf`;
            FileSaver.saveAs(ecReport.FILE_URL, filename);
        } catch (e) {
        } finally {
            setIsDownloadLoading(false);
        }
    }, [setIsDownloadLoading, ecReport]);

    return (
        <TabPanel value={tabIndexAsString}>
            <Grid container spacing={4} alignItems="flex-start">
                {ecReportLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: '300px', width: '100%' }}
                    >
                        <Loading />
                    </Box>
                ) : !ecReport ? (
                    <Alert severity="warning">
                        Le fichier EC n'est malheureusement pas disponible. Veuillez contacter notre équipe à l'adresse
                        courriel it@fmparadis.com au besoin.
                    </Alert>
                ) : (
                    <Box
                        ref={ref}
                        sx={{
                            width: '100%',
                            height: '100%',
                            overflow: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Document
                            file={ecReport.FILE_URL}
                            onLoadSuccess={onDocumentLoadSuccess}
                            loading={
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ minHeight: '300px', width: '100%' }}
                                >
                                    <Loading />
                                </Box>
                            }
                        >
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={pdfViewerWidth}
                                    className="pdf-viewer-page"
                                />
                            ))}
                        </Document>
                    </Box>
                )}
            </Grid>

            {ecReport && (
                <Box position="fixed" bottom={50} right={50}>
                    <Tooltip title="Télécharger le rapport EC sous format PDF." placement="top" disableInteractive>
                        <LoadingButton
                            variant="contained"
                            endIcon={<DownloadIcon fontSize="small" />}
                            color="info"
                            onClick={handleOnClickDownload}
                            loading={downloadLoading}
                        >
                            Télécharger le rapport
                        </LoadingButton>
                    </Tooltip>
                </Box>
            )}
        </TabPanel>
    );
}
