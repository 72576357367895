import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useTheme, Theme, lighten, alpha, darken } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import { AppointmentsProps, ViewState } from '@devexpress/dx-react-scheduler';
import { Scheduler, MonthView, Appointments, Toolbar, DateNavigator } from '@devexpress/dx-react-scheduler-material-ui';
import { styled } from '@mui/material/styles';

// MUI Components
import {
    Breadcrumbs,
    Button,
    Grid,
    Paper,
    Typography,
    Box,
    ButtonBase,
    CssBaselineProps,
    SxProps,
    IconButton,
    Popover,
    TextField,
    Divider,
    useMediaQuery,
    Stack,
    Chip,
} from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';

// MUI Grid

// MUI Icons
import { ArrowBackIos as ArrowBackIosIcon, ArrowForwardIos as ArrowForwardIosIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import EventDialog from './EventDialog/index';
import { FactVacDialog } from 'components/FactVacDialog';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_CLAIM_CALENDAR_EVENTS, GET_CALENDAR_VAC_EVENTS, GET_ECS } from './apollo-queries';

// Redux

// Services
import { Api } from 'services';

// Constants
import { SQL_DATE } from 'constants/Moment';

// Utils
import { capitalize, formatNumber } from 'utils';

const tableBorderColor = 'rgb(224, 224, 224)';
const tableTdBgHoverColor = 'rgba(0, 0, 0, 0.02)';
const tableTdOtherMonthBgColor = 'rgba(0, 0, 0, 0.04)';
// const tableTdOtherMonthBgHoverColor = 'rgba(0, 0, 0, 0.08)';

const borderStyleProps = { border: `1px ${tableBorderColor} solid`, borderCollapse: 'collapse' as const };

const SMALL_PADDING = 1;

interface SmEvent {
    date_service: Moment;
    poste_id: string;
    poste_nom: string;
    patients_count: number;
    total_prelim: number;
    total_final: number;
}

type SmEventWithType = SmEvent & { type: 'sm' };

interface VacEvent {
    reqt_id: number;
    once: number;
    date_service: Moment;
    pst_no: string;
    pst_mod: number;
    pst_name: string;
    hours: number;
}

type VacEventWithType = VacEvent & { type: 'vac' };

interface CalendarDate {
    date: Moment;
    firstCalendarDayDate: Moment;
    lastCalendarDayDate: Moment;
}

type onClickEventFunction = (event: SmEvent | VacEvent, type: 'sm' | 'vac') => void;

interface CalendarComponentProps {
    calendarDate: CalendarDate;
    setCalendarDate: React.Dispatch<React.SetStateAction<CalendarDate>>;
    smEvents: SmEvent[];
    vacEvents: VacEvent[];
    ecs: any[];
    loading: boolean;
    minDate: Moment;
    maxDate: Moment;
    onChangeDate: (date: Moment, firstDay: Moment, lastDay: Moment) => void;
    onClickEvent: onClickEventFunction;
}
const getFirstCalendarDay = (date: Moment) => date.clone().startOf('month').day(0);
const getLastCalendarDay = (date: Moment) => getFirstCalendarDay(date).add(numberOfWeeksToShowInCalendar * 7, 'days');

const numberOfWeeksToShowInCalendar = 5;

function CalendarComponent(props: CalendarComponentProps) {
    const {
        calendarDate,
        setCalendarDate,
        smEvents,
        vacEvents,
        ecs,
        loading,
        minDate,
        maxDate,
        onChangeDate,
        onClickEvent,
    } = props;

    const firstCalendarDay = getFirstCalendarDay(calendarDate.date);

    let weeks: any[] = [];
    for (let i = 0; i < numberOfWeeksToShowInCalendar; i++) {
        weeks.push(i);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <CalendarHeaderComponent
                    calendarDate={calendarDate}
                    setCalendarDate={setCalendarDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChangeDate={onChangeDate}
                />
            </Grid>

            <Grid item xs={12}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" pt={4} pb={8}>
                        <Loading />
                    </Box>
                ) : (
                    <table style={{ ...borderStyleProps, width: '100%', tableLayout: 'fixed' }}>
                        <WeekHeaderComponent />
                        <tbody>
                            {weeks.map((week, idx) => {
                                return (
                                    <WeekComponent
                                        key={idx}
                                        calendarDate={calendarDate}
                                        firstCalendarDay={firstCalendarDay}
                                        week={week}
                                        smEvents={smEvents}
                                        vacEvents={vacEvents}
                                        ecs={ecs}
                                        onClickEvent={onClickEvent}
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </Grid>
        </Grid>
    );
}

interface CalendarHeaderComponentProps {
    calendarDate: CalendarDate;
    setCalendarDate: React.Dispatch<React.SetStateAction<CalendarDate>>;
    minDate: Moment;
    maxDate: Moment;
    onChangeDate: CalendarComponentProps['onChangeDate'];
}
function CalendarHeaderComponent(props: CalendarHeaderComponentProps) {
    const { calendarDate, setCalendarDate, minDate, maxDate, onChangeDate } = props;

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const isDatePopoverOpen = Boolean(anchorEl);
    const handleClickDateButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const isPrevMonthButtonDisabled = calendarDate.date.clone().subtract(1, 'month').isBefore(minDate, 'month');
    const isNextMonthButtonDisabled = calendarDate.date.clone().add(1, 'month').isAfter(maxDate, 'month');

    const handleClickPrevMonthButton = () => {
        const newDate = calendarDate.date.clone().subtract(1, 'month');
        changeDate(newDate);
    };
    const handleClickNextMonthButton = () => {
        const newDate = calendarDate.date.clone().add(1, 'month');
        changeDate(newDate);
    };
    const handleChangeDate = (date: Moment | null) => {
        if (date) {
            changeDate(date);
        }
    };

    const changeDate = (date: Moment) => {
        const firstDay = getFirstCalendarDay(date);
        const lastDay = getLastCalendarDay(date);

        setCalendarDate({ date: date, firstCalendarDayDate: firstDay, lastCalendarDayDate: lastDay });
        onChangeDate(date, firstDay, lastDay);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" pt={2} pl={2}>
                    <IconButton
                        color="primary"
                        disabled={isPrevMonthButtonDisabled}
                        onClick={handleClickPrevMonthButton}
                    >
                        <ArrowBackIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>

                    <IconButton
                        color="primary"
                        disabled={isNextMonthButtonDisabled}
                        onClick={handleClickNextMonthButton}
                    >
                        <ArrowForwardIosIcon sx={{ fontSize: 14 }} />
                    </IconButton>

                    <Button variant="text" onClick={handleClickDateButton} size="large">
                        {capitalize(calendarDate.date.format('MMMM YYYY'))}
                    </Button>

                    <Popover
                        open={isDatePopoverOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            openTo="year"
                            value={calendarDate.date}
                            views={['year', 'month']}
                            label="Year and Month"
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={handleChangeDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Popover>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box pt={0} pl={3} pb={2} pr={3}>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end" mb={2}>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Box sx={{ width: 16, height: 16, bgcolor: 'primary.main', borderRadius: 1 }}></Box>
                            <Typography>Services médicaux</Typography>
                        </Stack>
                        {/* <Stack spacing={1} direction="row" alignItems="center">
                            <Box sx={{ width: 16, height: 16, bgcolor: 'info.main', borderRadius: 1 }}></Box>
                            <Typography>Frais de déplacement</Typography>
                        </Stack> */}
                        <Stack spacing={1} direction="row" alignItems="center">
                            <Box sx={{ width: 16, height: 16, bgcolor: 'pink1Dark.main', borderRadius: 1 }}></Box>
                            <Typography>Vacation / Tarif horaire</Typography>
                        </Stack>
                        {/* <Stack spacing={1} direction="row" alignItems="center">
                            <Box sx={{ width: 16, height: 16, bgcolor: 'warning.main', borderRadius: 1 }}></Box>
                            <Typography>Rémunération mixte</Typography>
                        </Stack> */}
                    </Stack>

                    {/* <Typography
                        variant="subtitle2"
                        sx={(theme) => ({
                            // color: theme.palette.info.main,
                            mb: 1,
                        })}
                    >
                        ** Veuillez cliquer sur une case pour afficher le détail de facturation.
                    </Typography> */}
                    {/* <Typography
                        variant="subtitle2"
                        sx={(theme) => ({
                            color: theme.palette.warning.main,
                        })}
                    >
                        ** L’affichage complet du tarif horaire, salariat et rémunération mixte sont présentement en
                        développement. Merci de votre compréhension.
                    </Typography> */}
                </Box>
            </Grid>
        </Grid>
    );
}

function WeekHeaderComponent() {
    return (
        <thead>
            <tr style={{ ...borderStyleProps }}>
                {[...Array(7).keys()].map((i, idx) => {
                    return (
                        <th key={idx} style={{ padding: '5px 0' }}>
                            <Typography variant="body2" fontWeight={500}>
                                {capitalize(moment.weekdays(i))}
                            </Typography>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}
interface WeekComponentProps {
    calendarDate: CalendarDate;
    firstCalendarDay: Moment;
    week: number;
    smEvents: SmEvent[];
    vacEvents: VacEvent[];
    ecs: any[];
    onClickEvent: CalendarComponentProps['onClickEvent'];
}

type DayEvent = SmEventWithType | VacEventWithType;

function WeekComponent(props: WeekComponentProps) {
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { calendarDate, firstCalendarDay, week, smEvents, vacEvents, ecs, onClickEvent } = props;

    const firstDayOfWeek = firstCalendarDay.clone().add(week, 'week');
    let cells: any[] = [];
    for (let i = 0; i < 7; i++) {
        const cellDate = firstDayOfWeek.clone().add(i, 'day');

        let additionalDateStyles: SxProps = {
            height: 25,
            borderRadius: 12.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };

        const isToday = cellDate.isSame(moment(), 'day');
        if (isToday) {
            additionalDateStyles = {
                ...additionalDateStyles,
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                width: 25,
            };
        }

        const daySmEvents = smEvents
            .filter((event) => moment(event.date_service).isSame(cellDate, 'day'))
            .map((item) => ({ ...item, type: 'sm' })) as SmEventWithType[];

        const dayVacEvents = vacEvents
            .filter((event) => moment(event.date_service).isSame(cellDate, 'day'))
            .map((item) => ({ ...item, type: 'vac' })) as VacEventWithType[];

        // const dayEvents: DayEvent[] = [...daySmEvents, ...dayVacEvents];
        const dayEvents: DayEvent[] = [...daySmEvents, ...dayVacEvents];

        // const ecDebutRelatedToDay = ecs.find((ec) => moment(ec.dd_periode_fact).isSame(cellDate, 'day'));
        const ecFinRelatedToDay = ecs.find((ec) => moment(ec.date_coupure).isSame(cellDate, 'day'));

        const ecDateEcRelatedToDay = ecs.find((ec) => moment(ec.date_ec).isSame(cellDate, 'day'));

        const isOtherMonthThanMainMonth = !cellDate.isSame(calendarDate.date, 'month');

        cells.push(
            <td key={i} style={{ ...borderStyleProps, height: 1 }}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: isOtherMonthThanMainMonth ? tableTdOtherMonthBgColor : '',
                        '&:hover': {
                            backgroundColor:
                                dayEvents.length === 0 && !isOtherMonthThanMainMonth ? tableTdBgHoverColor : '',
                        },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingY: 1,
                            paddingX: SMALL_PADDING,
                            minHeight: 100,
                        }}
                    >
                        <Typography variant="body2" sx={{ marginBottom: 1.5, ...additionalDateStyles }}>
                            {/* {cellDate.date() === 1 ? capitalize(cellDate.format('Do MMM')) : cellDate.format('D')} */}
                            {isToday ? cellDate.format('D') : capitalize(cellDate.format('Do MMM'))}
                        </Typography>

                        {/* {ecDebutRelatedToDay && (
                            <Chip
                                label={`Début OR-${ecDebutRelatedToDay.no_paiement}`}
                                size="small"
                                variant="filled"
                                color="info"
                                sx={{ mb: 2 }}
                            />
                        )} */}

                        {ecFinRelatedToDay && (
                            <Chip
                                label={`Coupure OR-${ecFinRelatedToDay.no_paiement}`}
                                size="small"
                                variant="filled"
                                color="info"
                                sx={{ mb: 2, borderRadius: 1 }}
                            />
                        )}

                        {ecDateEcRelatedToDay && (
                            <Chip
                                label={`Résultat OR-${ecDateEcRelatedToDay.no_paiement}`}
                                size="small"
                                variant="filled"
                                color="secondary"
                                sx={{ mb: 2, borderRadius: 1 }}
                            />
                        )}

                        {dayEvents.map((event, eventIdx) => {
                            switch (event.type) {
                                case 'sm':
                                    return (
                                        <SmEventComponent
                                            event={event}
                                            eventIdx={eventIdx}
                                            onClickEvent={onClickEvent}
                                        />
                                    );
                                case 'vac':
                                    return (
                                        <VacEventComponent
                                            event={event}
                                            eventIdx={eventIdx}
                                            onClickEvent={onClickEvent}
                                        />
                                    );
                                default:
                                    return <></>;
                            }
                        })}
                    </Box>
                </Box>
            </td>,
        );
    }

    return <tr>{cells}</tr>;
}

interface SmEventProps {
    event: SmEvent;
    eventIdx: number;
    onClickEvent: CalendarComponentProps['onClickEvent'];
}
const SmEventComponent = (props: SmEventProps) => {
    const theme = useTheme();

    const { event, eventIdx, onClickEvent } = props;

    return (
        <ButtonBase
            // key={eventIdx}
            onClick={() => onClickEvent(event, 'sm')}
            sx={{
                width: '100%',
                marginTop: eventIdx > 0 ? SMALL_PADDING : 0,
                borderRadius: 1,
                // backgroundColor: alpha(theme.palette.primary.main, 0.2),
            }}
        >
            <Paper
                sx={{
                    width: '100%',
                    backgroundColor: theme.palette.primary.main,
                    padding: 1,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                    },
                    color: theme.palette.primary.contrastText,
                }}
                elevation={1}
            >
                <Typography variant="body2" textAlign="center" fontWeight="bold" mb={1}>
                    {event.poste_nom} ({event.poste_id})
                </Typography>

                <Typography variant="body1" textAlign="center" sx={{ mb: 1.5 }}>
                    {event.patients_count} patient{event.patients_count > 1 ? 's' : ''}
                </Typography>
                {/* <Divider
                    sx={{ backgroundColor: theme.palette.primary.contrastText, mt: 1, mb: 2 }}
                /> */}
                {/* <Typography variant="body2" textAlign="left">
                    Nombre patients: {event.patients_count} patient
                    {event.patients_count > 1 ? 's' : ''}
                </Typography> */}
                <Typography variant="body2" textAlign="left">
                    Montant demandé: {formatNumber(event.total_prelim)} $
                </Typography>
                <Typography variant="body2" textAlign="left">
                    Montant reçu: {formatNumber(event.total_final)} $
                </Typography>
            </Paper>
        </ButtonBase>
    );
};

interface VacEventProps {
    event: VacEvent;
    eventIdx: number;
    onClickEvent: CalendarComponentProps['onClickEvent'];
}
const VacEventComponent = (props: VacEventProps) => {
    const theme = useTheme();

    const { event, eventIdx, onClickEvent } = props;

    return (
        <ButtonBase
            // key={eventIdx}
            onClick={() => onClickEvent(event, 'vac')}
            sx={{
                width: '100%',
                marginTop: eventIdx > 0 ? SMALL_PADDING : 0,
                borderRadius: 1,
                // backgroundColor: alpha(theme.palette.primary.main, 0.2),
            }}
        >
            <Paper
                sx={{
                    width: '100%',
                    backgroundColor: theme.palette.pink1Dark.main,
                    padding: 1,
                    '&:hover': {
                        backgroundColor: theme.palette.pink1Dark.dark,
                    },
                    color: theme.palette.pink1Dark.contrastText,
                }}
                elevation={1}
            >
                <Typography variant="body2" textAlign="center" fontWeight="bold" mb={1}>
                    {event.pst_name} ({event.pst_no})
                </Typography>

                <Typography variant="body1" textAlign="center" sx={{ mb: 1.5 }}>
                    {event.hours} heure{event.hours > 1 ? 's' : ''}
                </Typography>

                <Typography variant="body2" textAlign="center">
                    Demande #{event.once}
                </Typography>

                {/* <Divider
                    sx={{ backgroundColor: theme.palette.primary.contrastText, mt: 1, mb: 2 }}
                /> */}
                {/* <Typography variant="body2" textAlign="left">
                    Nombre patients: {event.patients_count} patient
                    {event.patients_count > 1 ? 's' : ''}
                </Typography> */}
            </Paper>
        </ButtonBase>
    );
};
export default function Calendar() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    const [selectedSmEvent, setSelectedSmEvent] = useState<SmEvent | null>(null);
    const [selectedVacEvent, setSelectedVacEvent] = useState<VacEvent | null>(null);

    const initialCalendarDate = moment();
    const [calendarDate, setCalendarDate] = useState<CalendarDate>({
        date: initialCalendarDate,
        firstCalendarDayDate: getFirstCalendarDay(initialCalendarDate),
        lastCalendarDayDate: getLastCalendarDay(initialCalendarDate),
    });

    // Apollo queries
    const {
        loading: claimCalendarEventsLoading,
        error: claimCalendarEventsError,
        data: claimCalendarEventsData,
    } = useQuery(GET_CLAIM_CALENDAR_EVENTS, {
        variables: {
            date_service_start: calendarDate.firstCalendarDayDate,
            date_service_end: calendarDate.lastCalendarDayDate,
        },
    });
    const {
        loading: calendarVacEventsLoading,
        error: calendarVacEventsError,
        data: calendarVacEventsData,
    } = useQuery(GET_CALENDAR_VAC_EVENTS, {
        variables: {
            date_service_start: calendarDate.firstCalendarDayDate,
            date_service_end: calendarDate.lastCalendarDayDate,
        },
    });
    const {
        loading: ecsLoading,
        error: ecsError,
        data: ecsData,
    } = useQuery(GET_ECS, {
        variables: {
            date_ec_less_or_equals_to: moment().add(2, 'months').format(SQL_DATE),
        },
    });

    const onChangeDate = (date: Moment, firstDay: Moment, lastDay: Moment) => {};

    const onClickEvent = (event: SmEvent | VacEvent, type: 'sm' | 'vac') => {
        switch (type) {
            case 'sm':
                setSelectedSmEvent(event as SmEvent);
                break;
            case 'vac':
                setSelectedVacEvent(event as VacEvent);
                break;
        }
    };

    const handleCloseSmDialog = () => {
        setSelectedSmEvent(null);
    };

    const handleCloseVacDialog = () => {
        setSelectedVacEvent(null);
    };

    return (
        <>
            <EventDialog
                date_service={selectedSmEvent?.date_service || null}
                poste_id={selectedSmEvent?.poste_id || null}
                patients_count={selectedSmEvent?.patients_count || 0}
                total_prelim={selectedSmEvent?.total_prelim || 0}
                total_final={selectedSmEvent?.total_final || 0}
                handleCloseDialog={handleCloseSmDialog}
            />

            <FactVacDialog reqt_id={selectedVacEvent?.reqt_id || null} handleCloseDialog={handleCloseVacDialog} />

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        {isMobile ? (
                            <Box padding={3}>
                                <Typography variant="h5" fontWeight={500}>
                                    Oups!
                                </Typography>

                                <Typography mt={3}>
                                    Le calendrier des réclamations n'est malheureusement pas adapté pour mobile.
                                </Typography>

                                <Typography mt={2}>
                                    Nous travaillons actuellement sur la version mobile (app IOS et Android) qui vous
                                    permettra d'avoir accès à cette page et bien plus directement sur votre appareil
                                    mobile!
                                </Typography>

                                <Typography mt={2}>
                                    Vous pouvez entre-temps utiliser une tablette ou un PC pour accéder à cette page.
                                </Typography>
                            </Box>
                        ) : (
                            <CalendarComponent
                                loading={claimCalendarEventsLoading}
                                smEvents={claimCalendarEventsData?.claimCalendarEvents || []}
                                vacEvents={calendarVacEventsData?.calendarVacEvents || []}
                                // vacEvents={[]}
                                ecs={ecsData?.ecs || []}
                                minDate={moment('2016-10-01')}
                                maxDate={moment()}
                                onChangeDate={onChangeDate}
                                onClickEvent={onClickEvent}
                                calendarDate={calendarDate}
                                setCalendarDate={setCalendarDate}
                            />
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}
