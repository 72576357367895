import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import moment, { Moment } from 'moment';

// MUI Components
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Typography,
    Tooltip,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tab,
    SxProps,
    lighten,
    Stack,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { ArrowForward as ArrowForwardIcon, Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import BooleanIcon from 'components/BooleanIcon';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_FACT_VAC } from './apollo';

// Redux

// Utils
import { formatNumber, fmpHoursToRamqHours } from 'utils';

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

interface FactVacDialogProps {
    reqt_id: number | null;
    handleCloseDialog: () => void;
}

export const FactVacDialog = (props: FactVacDialogProps) => {
    const { reqt_id, handleCloseDialog } = props;

    // Apollo queries
    const {
        loading: factVacLoading,
        error: factVacError,
        data: factVacData,
    } = useQuery(GET_FACT_VAC, {
        variables: { reqt_id: reqt_id },
        skip: !reqt_id,
    });

    const factVac = factVacData?.factVac;

    const isDialogOpen = reqt_id ? true : false;

    return (
        <Dialog fullWidth={true} maxWidth={'xl'} open={isDialogOpen} onClose={handleCloseDialog}>
            {factVacLoading || !reqt_id ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                    <Loading />
                </Box>
            ) : (
                <FactVacDialogContent factVac={factVac} handleCloseDialog={handleCloseDialog} />
            )}
        </Dialog>
    );
};

interface FactVacDialogContentProps {
    factVac: any;
    handleCloseDialog: () => void;
}

const FactVacDialogContent = (props: FactVacDialogContentProps) => {
    const { factVac, handleCloseDialog } = props;

    const [tabIndex, setTabIndex] = React.useState('facturation');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    if (!factVac) return <></>;

    const {
        created_at,
        nce,
        revision,
        reqt_id,
        licence,
        pst_no,
        pst_mod,
        pst_name,
        statut_recv,
        is_canceled,
        lot_id,
        fmp_ec_id,
        ec_id,

        debut_periode,
        fin_periode,
        hrs_activite,
        mins_activite,
        cs1,
        cs2,
        nom_mand,
        date_mand,
        nom_sign,
        date_sign,
        once,
        attestation,

        pst_depart,
        pst_arrivee,
        dh_arrivee,
        hrs_depl,
        mins_depl,
        kms_depl,
        montant_transport_reclame,

        date_service_min,
        date_service_max,
        date_service_agg,

        payment_status,

        ec_1717ig_agg,

        hrs_activite_payes,
        total_remu_activite,
        hrs_depl_payes,
        total_remu_temps_depl,
        kms_transport_payes,
        total_remu_cout_transport,
        total_remu,

        line_count,

        PAYMENT_STATUS_NAME,

        FactLignesVac,
    } = factVac;

    return (
        <TabContext value={tabIndex}>
            <DialogTitle>
                Demande #{once} (No. Attestation : {attestation})
            </DialogTitle>
            <DialogContent>
                <Grid container rowSpacing={4} mt={0}>
                    <Grid item xs={12}>
                        <Typography variant="h5" fontWeight={600} textAlign="center">
                            Semaine du {moment(debut_periode).format(SQL_DATE)} au{' '}
                            {moment(fin_periode).format(SQL_DATE)}
                        </Typography>

                        <Typography variant="body2" textAlign="center" mt={1}>
                            {pst_name} ({pst_no})
                        </Typography>
                    </Grid>

                    {/* <Grid item xs={12}>
                        <Typography variant="h6">Autre:</Typography>

                        <Typography>Numéro de contrôle externe: {once}</Typography>
                        <Typography>Numéro d'attestation: {attestation}</Typography>
                    </Grid> */}
                </Grid>

                <Grid container mt={5}>
                    <Grid item xs={12}>
                        <TableContainer
                            component={Paper}
                            sx={{ borderBottom: 0, borderColor: 'primary.main' }}
                            elevation={3}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={(theme) => ({
                                            bgcolor: lighten(theme.palette.primary.main, 0.2),
                                        })}
                                    >
                                        <TableCell colSpan={16} align="center">
                                            <Typography variant="body1" fontWeight={500} color="primary.contrastText">
                                                Activités réclamées
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center" colSpan={2}></TableCell>
                                        <TableCell align="center" colSpan={4} sx={{ ...leftBorder }}>
                                            Période
                                        </TableCell>
                                        <TableCell align="center" colSpan={3} sx={{ ...leftBorder }}>
                                            Activité 1
                                        </TableCell>
                                        <TableCell align="center" colSpan={3} sx={{ ...leftBorder }}>
                                            Activité 2
                                        </TableCell>
                                        <TableCell align="center" colSpan={3} sx={{ ...leftBorder }}>
                                            Activité 3
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell width={80}>Date</TableCell>

                                        <TableCell width={40} align="center" sx={{ ...leftBorder }}>
                                            Mode rému.
                                        </TableCell>

                                        <TableCell width={40} align="center" sx={{ ...leftBorder }}>
                                            AM
                                        </TableCell>

                                        <TableCell width={40} align="center">
                                            PM
                                        </TableCell>

                                        <TableCell width={40} align="center">
                                            Soir
                                        </TableCell>

                                        <TableCell width={40} align="center">
                                            Nuit
                                        </TableCell>

                                        <TableCell width={80} sx={{ ...leftBorder }}>
                                            Code
                                        </TableCell>
                                        <TableCell width={80} align="center">
                                            Secteur
                                        </TableCell>
                                        <TableCell width={80} align="right">
                                            Heures
                                        </TableCell>

                                        <TableCell width={80} sx={{ ...leftBorder }}>
                                            Code
                                        </TableCell>
                                        <TableCell width={80} align="center">
                                            Secteur
                                        </TableCell>
                                        <TableCell width={80} align="right">
                                            Heures
                                        </TableCell>

                                        <TableCell width={80} sx={{ ...leftBorder }}>
                                            Code
                                        </TableCell>
                                        <TableCell width={80} align="center">
                                            Secteur
                                        </TableCell>
                                        <TableCell width={80} align="right">
                                            Heures
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...FactLignesVac]
                                        .sort((a, b) => a.rank - b.rank)
                                        .map((factLigne, idx) => {
                                            const {
                                                // nce,
                                                // revision,
                                                lineno,
                                                linedt,
                                                moderem,
                                                nuit,
                                                am,
                                                pm,
                                                soir,

                                                FactLigneVacDetails,
                                            } = factLigne;

                                            const firstVacDetailsItem = FactLigneVacDetails[0] || null;
                                            const secondVacDetailsItem = FactLigneVacDetails[1] || null;
                                            const thirdVacDetailsItem = FactLigneVacDetails[2] || null;

                                            return (
                                                <TableRow
                                                    key={idx}
                                                    // sx={{
                                                    //     '&:last-child td, &:last-child th': {
                                                    //         border: 0,
                                                    //     },
                                                    // }}
                                                    hover={true}
                                                >
                                                    <TableCell>
                                                        {linedt ? moment(linedt).format(SQL_DATE) : ''}
                                                    </TableCell>

                                                    <TableCell align="center" sx={{ ...leftBorder }}>
                                                        {moderem}
                                                    </TableCell>

                                                    <TableCell align="center" sx={{ ...leftBorder }}>
                                                        <BooleanIcon value={am} />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <BooleanIcon value={pm} />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <BooleanIcon value={soir} />
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        <BooleanIcon value={nuit} />
                                                    </TableCell>

                                                    {/* First context */}
                                                    <TableCell align="left" sx={{ ...leftBorder }}>
                                                        {firstVacDetailsItem && firstVacDetailsItem.code}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {firstVacDetailsItem && firstVacDetailsItem.sd}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {firstVacDetailsItem &&
                                                            fmpHoursToRamqHours(
                                                                firstVacDetailsItem.hr,
                                                                firstVacDetailsItem.min,
                                                            )}
                                                    </TableCell>

                                                    {/* Second context */}
                                                    <TableCell align="left" sx={{ ...leftBorder }}>
                                                        {secondVacDetailsItem && secondVacDetailsItem.code}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {secondVacDetailsItem && secondVacDetailsItem.sd}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {secondVacDetailsItem &&
                                                            fmpHoursToRamqHours(
                                                                secondVacDetailsItem.hr,
                                                                secondVacDetailsItem.min,
                                                            )}
                                                    </TableCell>

                                                    {/* Third context */}
                                                    <TableCell align="left" sx={{ ...leftBorder }}>
                                                        {thirdVacDetailsItem && thirdVacDetailsItem.code}
                                                    </TableCell>

                                                    <TableCell align="center">
                                                        {thirdVacDetailsItem && thirdVacDetailsItem.sd}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        {thirdVacDetailsItem &&
                                                            fmpHoursToRamqHours(
                                                                thirdVacDetailsItem.hr,
                                                                thirdVacDetailsItem.min,
                                                            )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}

                                    <TableRow>
                                        <TableCell colSpan={10} rowSpan={5} />
                                        <TableCell colSpan={3} sx={{ pt: 3, pb: 1 }}>
                                            <Typography variant="body1" color="primary">
                                                Heures réclamées:
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                Heures payées:
                                            </Typography>
                                        </TableCell>
                                        <TableCell colSpan={2} align="right" sx={{ pt: 3, pb: 1 }}>
                                            <Typography variant="body1" color="primary">
                                                {fmpHoursToRamqHours(hrs_activite, mins_activite)} h
                                            </Typography>
                                            <Typography variant="body1" color="primary">
                                                {formatNumber(hrs_activite_payes)} h
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    <TableRow sx={{ borderTopStyle: 'hidden' }}>
                                        <TableCell colSpan={5} sx={{ pt: 1, pb: 1 }}>
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                sx={(theme) => ({
                                                    borderTop: '1px solid',
                                                    borderTopColor: theme.palette.divider,
                                                })}
                                                pt={2}
                                            >
                                                <Box>
                                                    <Typography
                                                        variant="body1"
                                                        fontWeight={500}
                                                        sx={(theme) => ({
                                                            color: theme.palette.pink1Dark.main,
                                                        })}
                                                    >
                                                        Montant payé:
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        variant="body1"
                                                        fontWeight={500}
                                                        sx={(theme) => ({
                                                            color: theme.palette.pink1Dark.main,
                                                        })}
                                                    >
                                                        {formatNumber(total_remu_activite)} $
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

                {/* <Grid container rowSpacing={4} mt={1.5}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Déplacement:</Typography>

                        <Typography>Lieu de départ: {pst_depart}</Typography>
                        <Typography>Lieu d'arrivée: {pst_arrivee}</Typography>
                        <Typography>Date d'arrivée: {moment(dh_arrivee).format(SQL_DATETIME)}</Typography>
                        <Typography>Temps: {fmpHoursToRamqHours(hrs_depl, mins_depl)}</Typography>
                        <Typography>Distance: {kms_depl} km</Typography>
                        <Typography>Montant réclamé: {montant_transport_reclame}</Typography>
                    </Grid>
                </Grid> */}

                <Grid container rowSpacing={4} columnSpacing={4} mt={1.5} justifyContent="space-between">
                    <Grid item xs={4}>
                        <TableContainer
                            component={Paper}
                            sx={{ borderBottom: 0, borderColor: 'primary.main' }}
                            elevation={3}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={(theme) => ({
                                            bgcolor: lighten(theme.palette.primary.main, 0.2),
                                        })}
                                    >
                                        <TableCell colSpan={16} align="left">
                                            <Typography variant="body1" fontWeight={500} color="primary.contrastText">
                                                Mandataire
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="body1" fontWeight={500}>
                                            {nom_mand}
                                        </Typography>
                                        <Typography variant="body2" mt={0.5}>
                                            Signé le: {moment(date_mand).format(SQL_DATE)}
                                        </Typography>
                                    </Box>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={4}>
                        <TableContainer
                            component={Paper}
                            sx={{ borderBottom: 0, borderColor: 'primary.main' }}
                            elevation={3}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={(theme) => ({
                                            bgcolor: lighten(theme.palette.primary.main, 0.2),
                                        })}
                                    >
                                        <TableCell colSpan={16} align="left">
                                            <Typography variant="body1" fontWeight={500} color="primary.contrastText">
                                                Signataire
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <Box sx={{ p: 2 }}>
                                        <Typography variant="body1" fontWeight={500}>
                                            {nom_sign}
                                        </Typography>
                                        <Typography variant="body2" mt={0.5}>
                                            Signé le: {moment(date_sign).format(SQL_DATE)}
                                        </Typography>
                                    </Box>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Fermer</Button>
            </DialogActions>
        </TabContext>
    );
};

interface FactLigneVacDetailsTooltipProps {
    factLigneVacDetailsItem: any;
}

const FactLigneVacDetailsTooltip = (props: FactLigneVacDetailsTooltipProps) => {
    const { factLigneVacDetailsItem } = props;

    const { nce, revision, lineno, rank, ref, code, sd, hr, min } = factLigneVacDetailsItem;

    return (
        <Tooltip
            title={
                <>
                    <Typography variant="body2">Ref: {ref}</Typography>
                    <Typography variant="body2">Code: {code}</Typography>
                    <Typography variant="body2">Secteur dispensaire: {sd}</Typography>
                    <Typography variant="body2">
                        Heures: {hr}
                        {min > 0 ? `.${min}` : ''}
                    </Typography>
                </>
            }
            arrow
            placement="top"
            disableInteractive
        >
            <Button variant="text" size="small">
                Info
            </Button>
        </Tooltip>
    );
};

const leftBorder: SxProps = {
    borderLeftWidth: '1px',
    borderLeftStyle: 'solid',
    borderLeftColor: 'rgb(224,224,224)',
};
