import React from 'react';
import { TextField, Autocomplete } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, Controller, FieldError, FormState, useController, UseControllerProps } from 'react-hook-form';
import { getHelperText } from '.';

interface AutocompleteControlProps {
    name: string;
    control: Control<any, any>;
    rules?: UseControllerProps['rules'];
    autocompleteOptions: {
        options: any[];
        valuePropertyName: string;
        valueGetter?: (option: any) => string | null;
        loading?: boolean;
        renderOption?: () => any;
        getOptionLabel: (option: any) => string;
        isOptionEqualToValue: (option: any, value: any) => boolean;
        disabled?: boolean;
    };
    textFieldOptions?: { label?: string; suffix?: string; disabled?: boolean };
}

export function AutocompleteControl({
    name,
    control,
    rules,
    autocompleteOptions: {
        options = [],
        valuePropertyName,
        valueGetter,
        loading,
        renderOption,
        getOptionLabel,
        isOptionEqualToValue,
        disabled,
    },
    textFieldOptions,
}: AutocompleteControlProps) {
    const {
        field: { onChange, value },
        fieldState: { invalid, error },
    } = useController({ name, control, rules });

    const onValueChange = useCallback(
        (e, value) => {
            if (valueGetter) {
                onChange(valueGetter(value));
            } else {
                onChange(value?.[valuePropertyName] || null);
            }
        },
        [onChange, valueGetter, valuePropertyName],
    );

    return (
        <Autocomplete
            // disablePortal
            options={options}
            loading={loading}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onValueChange}
            isOptionEqualToValue={isOptionEqualToValue}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="small"
                    fullWidth
                    {...textFieldOptions}
                    error={invalid}
                    helperText={getHelperText(error)}
                />
            )}
        />
    );
}
