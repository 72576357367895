import React, { useState, useMemo, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';

// MUI Components
import {
    Alert,
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Paper,
    Snackbar,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import { useTheme, darken, lighten } from '@mui/material/styles';

// MUI Grid

// MUI Icons
import { Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import {
    AutocompleteControl,
    CheckboxControl,
    DateControl,
    NumberControl,
    SelectControl,
    TextControl,
} from 'components/inputs';

// Apollo
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_MD, UPDATE_MD } from './apollo-queries';

// Redux

// Services

// Constants

// Utils
import { getErrorMsg } from 'utils';

export default function PersonalInfo() {
    const theme = useTheme();

    // Apollo queries
    const {
        loading: mdLoading,
        error: mdError,
        data: mdData,
        refetch: mdRefetch,
        networkStatus: mdNetworkStatus,
    } = useQuery(GET_MD, {});
    const md = mdData?.md || {};

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            id: md.id,
            is_still_client: md.is_still_client,
            left_on: md.left_on,
            can_login: md.can_login,
            qb_customer_id: md.qb_customer_id,
            qb_customer_fully_qualified_name: md.qb_customer_fully_qualified_name || '',

            is_billable: md.is_billable,
            is_billable_until: md.is_billable_until,

            should_receive_reports: md.should_receive_reports,
            should_receive_reports_until: md.should_receive_reports_until,

            wants_invoice_emails: md.wants_invoice_emails,
            wants_ec_emails: md.wants_ec_emails,
            wants_newsletter_emails: md.wants_newsletter_emails,
        },
    });

    useEffect(() => {
        const md = mdData?.md || {};

        if (!isEmpty(md)) {
            reset({
                id: md.id,
                is_still_client: md.is_still_client,
                left_on: md.left_on,
                can_login: md.can_login,
                qb_customer_id: md.qb_customer_id,
                qb_customer_fully_qualified_name: md.qb_customer_fully_qualified_name,

                is_billable: md.is_billable,
                is_billable_until: md.is_billable_until,

                should_receive_reports: md.should_receive_reports,
                should_receive_reports_until: md.should_receive_reports_until,

                wants_invoice_emails: md.wants_invoice_emails,
                wants_ec_emails: md.wants_ec_emails,
                wants_newsletter_emails: md.wants_newsletter_emails,
            });
        }
    }, [reset, mdData]);

    const [updateMd, { loading: updateMdLoading, error: updateMdError, data: updateMdData }] = useMutation(UPDATE_MD, {
        // refetchQueries: [GET_SCANS],
    });

    const onSubmit = async (data) => {
        console.log(data);

        await updateMd({
            variables: {
                id: data.id,
                is_still_client: data.is_still_client,
                left_on: data.left_on,
                can_login: data.can_login,
                qb_customer_id: data.qb_customer_id,
                qb_customer_fully_qualified_name: data.qb_customer_fully_qualified_name,

                is_billable: data.is_billable,
                is_billable_until: data.is_billable_until,

                should_receive_reports: data.should_receive_reports,
                should_receive_reports_until: data.should_receive_reports_until,

                wants_invoice_emails: data.wants_invoice_emails,
                wants_ec_emails: data.wants_ec_emails,
                wants_newsletter_emails: data.wants_newsletter_emails,
            },
        });

        mdRefetch();
    };

    // Error snackbar
    const [isErrorSnackbarOpened, setIsErrorSnackbarOpened] = useState(false);
    const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsErrorSnackbarOpened(false);
    };
    useEffect(() => setIsErrorSnackbarOpened(updateMdError ? true : false), [updateMdError]);

    const inputGridXl = 6;
    const inputGridLg = 6;
    const inputGridMd = 6;
    const inputGridSm = 6;
    const inputGridXs = 12;

    return mdLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
            <Loading />
        </Box>
    ) : (
        <Paper sx={{ p: 2 }}>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Grid container spacing={3}>
                    <Grid item container mt={2}>
                        <Grid item xs={12} mb={3}>
                            <Typography variant="subtitle1" fontWeight="500">
                                Informations personnelles
                            </Typography>

                            <Divider sx={{ mt: 2 }} />
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} lg={4}>
                                <TextControl name="first_name" control={control} options={{ label: 'Prénom' }} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <TextControl name="last_name" control={control} options={{ label: 'Nom' }} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <DateControl name="dob" control={control} options={{ label: 'Date de naissance' }} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <NumberControl
                                    name="phone_mobile"
                                    control={control}
                                    options={{ label: 'Numéro de téléphone mobile' }}
                                    phone
                                    nullable
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <NumberControl
                                    name="phone_home"
                                    control={control}
                                    options={{ label: 'Numéro de téléphone maison' }}
                                    phone
                                    nullable
                                />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <NumberControl
                                    name="phone_work"
                                    control={control}
                                    options={{ label: 'Numéro de téléphone travail' }}
                                    phone
                                    nullable
                                />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextControl name="email" control={control} options={{ label: 'Adresse courriel' }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container mt={2}>
                        <Grid item xs={12} mb={3}>
                            <Typography variant="subtitle1" fontWeight="500">
                                Adresse
                            </Typography>

                            <Divider sx={{ mt: 2 }} />
                        </Grid>

                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12} lg={4}>
                                <TextControl name="address1" control={control} options={{ label: 'Adresse 1' }} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <TextControl name="address2" control={control} options={{ label: 'Adresse 2' }} />
                            </Grid>

                            <Grid item xs={12} lg={4}>
                                <DateControl name="city" control={control} options={{ label: 'Ville' }} />
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <TextControl name="zip" control={control} options={{ label: 'Code postal' }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container justifyContent="flex-end" mt={0}>
                        <Button type="submit" variant="contained" disabled={updateMdLoading}>
                            Sauvegarder
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <Snackbar
                open={isErrorSnackbarOpened}
                autoHideDuration={6000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity="error" sx={{ width: '100%' }}>
                    {getErrorMsg(updateMdError)}
                </Alert>
            </Snackbar>
        </Paper>
    );
}
