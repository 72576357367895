import { gql } from '@apollo/client';
import { MdFields, EcFields, Ec1717igFields, FactLigneFields, CodeFactFields } from 'apollo/fragments';

export const GET_EC_MD_GRP = gql`
    ${MdFields}
    ${EcFields}
    ${Ec1717igFields}
    ${FactLigneFields}
    ${CodeFactFields}

    query GetEcMdGrp($id: Int!) {
        ecMdGrp(id: $id) {
            id
            licence
            grp
            ec_id
            fmp_id

            Md {
                ...MdFields
            }
            Groupe {
                numero
                licence
                denomination
                incorp
                debut
                fin
            }
            Ec {
                ...EcFields
            }

            Ec1703 {
                mdgrpid
                compteur
                valideur
                msg
                msg_permanent
            }
            Ec1704 {
                mdgrpid
                compteur
                valideur
                codetrans
                montantbrut
                libellecodetrans
            }
            Ec1707 {
                mdgrpid
                compteur
                valideur
                codededuction
                montantdeduction
                libellededuction
                dateenrgded
                nocompteded
            }
            Ec1708 {
                mdgrpid
                compteur
                valideur
                nomfed
                montantcotisation
            }
            Ec1709 {
                mdgrpid
                compteur
                valideur
                codetransfert
                classegrptransfert
                nogrptransfert
                montanttransfert
            }
            Ec1710 {
                mdgrpid
                compteur
                valideur
                interetsurrecouv
                interetsuravance
                avancesemises
                avancesrecouv
                interetsursoldeneg
            }
            Ec1711 {
                mdgrpid
                compteur
                valideur
                soldereporte
                soldeareporte
                modifdesolde
                paiementrecu
                debutinteretsursoldeneg
                codemsg
                montantmsg1
            }
            Ec1712 {
                mdgrpid
                compteur
                valideur
                montantnetverse
                nochequeouvirement
                modepaiement
            }
            Ec1716 {
                mdgrpid
                compteur
                valideur
                nomfederation
                montantcumulatif
            }

            Ec1717ig {
                ...Ec1717igFields

                Ec1717e2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    debutperiode
                    finperiode
                    noentente
                    typeassresp
                    libelletypeassresp
                    montantpaye
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantcumule
                    montantcumuleant
                }
                Ec1717f2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    libelleforfaitaire
                    taux
                    montantpaye
                }
                Ec1717m2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    typemesinc
                    libelleelmmesinc
                    elmmesinc
                    libelleelmmesinc2
                    codemsgexpl1
                    codemsgexpl2
                    montantpaye
                    nombrejours
                    montantconsidere
                    nombrejourscons
                    montantconsant
                    nombrejoursconsant
                    reference
                    debutmesureinc
                    finmesureinc
                }
                Ec1717r2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    typemesurepart
                    libelletypemesurepart
                    typeappl
                    montantpaye
                    montantcons
                    montantconsant
                }
                Ec1717r3 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    dateoccurrence
                    montantpaye
                    montantcons
                    montantconsant
                    reference
                }
                Ec1717v2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codenatserv
                    libellecodenatserv
                    codeemploitemps
                    libellecodeemploitemps
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    nombreheurespayees
                    montantcons
                    nombreheurescons
                    montantconsant
                    nombreheuresconsant
                    reference
                }
                Ec1717v3 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    nombreheurespayees
                    montantcons
                    nombreheurescons
                    montantconsant
                    nombreheuresconsant
                    reference
                }
                Ec1717v4 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    nombrekmspayes
                    montantcons
                    nombrekmscons
                    montantconsant
                    nombrekmsconsant
                    reference
                    refocctempsdepl
                }
                Ec1717x2 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codenatserv
                    libellecodenatserv
                    codeemploitemps
                    libellecodeemploitemps
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    typeunitpayees
                    nombreunitpayees
                    nombreunitcons
                    nombreunitconsant
                    reference
                    sansobjet
                }
                Ec1717x3 {
                    masterid
                    compteur
                    valideur
                    mdgrpid
                    nodemande
                    codemsgexpl1
                    codemsgexpl2
                    codemsgexpl3
                    montantpaye
                    typeunitpayees
                    nombreunitpayees
                    nombreunitcons
                    nombreunitconsant
                    reference
                }
            }

            Ec1720 {
                nci
                nam
                dateoccurrence
                montantpaye
                dateec
                rev

                Facture {
                    poste_id
                    poste_mod
                    FactLignes {
                        ...FactLigneFields
                        # AMT_AT_EC(ec_id: $ec_id)
                    }
                }
            }
            Ec1721 {
                mdgrpid
                nci
                noligne
                nam
                dateoccurrence
                codetran
                typeident
                valeurident
                montantprel
                montantpaye
                codemsgrfp
                codemsgrfp1
                codemsgrfp2
                codemsgrfp3
                codemsgrfp4
                codemsgrfp5
                id
                rev
                dateec

                Facture {
                    poste_id
                    poste_mod
                    FactLignes {
                        ...FactLigneFields
                    }
                }

                MessageExpl {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl1 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl2 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl3 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl4 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl5 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }

                CodeFact {
                    ...CodeFactFields
                }
            }
            Ec1722 {
                mdgrpid
                nci
                noligne
                nam
                dateoccurrence
                codetran
                typeident
                valeurident
                montantant
                montantnouv
                montantpaye
                codemsgrfp1
                codemsgrfp2
                codemsgrfp3
                codemsgrfp4
                codemsgrfp5
                id
                rev
                dateec

                Facture {
                    poste_id
                    poste_mod
                    FactLignes {
                        ...FactLigneFields
                    }
                }

                MessageExpl1 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl2 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl3 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl4 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl5 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }

                CodeFact {
                    ...CodeFactFields
                }
            }
            Ec1725 {
                mdgrpid
                nci
                noligne
                nam
                dateoccurrence
                codetran
                montantremb
                typeident
                valeurident
                montantant
                montantnouv
                montantpaye
                codemsgrfp1
                codemsgrfp2
                codemsgrfp3
                codemsgrfp4
                codemsgrfp5
                id
                rev
                dateec

                Facture {
                    poste_id
                    poste_mod
                    FactLignes {
                        ...FactLigneFields
                    }
                }

                MessageExpl1 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl2 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl3 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl4 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }
                MessageExpl5 {
                    entente
                    codemsgexpl
                    texte
                    categorie
                    typefact
                    debut
                    fin
                }

                CodeFact {
                    ...CodeFactFields
                }
            }
        }
    }
`;
