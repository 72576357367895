import { gql } from '@apollo/client';
import { FRAGMENTS } from './apollo-fragments';

export const GET_FACTURES_DAILY = gql`
    ${FRAGMENTS}

    query GetFacturesDaily($date_service: Date, $poste_id: String) {
        facturesDaily(date_service: $date_service, poste_id: $poste_id) {
            created_at
            nce
            nci
            revision
            reqt_id
            licence
            poste_id
            poste_nom
            poste_mod
            jeton
            statut_recv
            is_canceled
            lot_id
            ec_id
            date_service
            date_dernier_paiement
            payment_status

            type_service_id
            type_service_nom
            type_service_abbrev

            total_prelim
            total_final
            total_diff

            line_count

            patient_nam

            PAYMENT_STATUS_NAME

            FactLignes {
                ...FactLigneFields
            }
        }
    }
`;
