import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme, lighten } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {
    Box,
    Button,
    Chip,
    FormGroup,
    Grid,
    IconButton,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    FormControlLabel,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro, GridRowId } from '@mui/x-data-grid-pro';
import { getFactureListColumns } from './grid-columns';

// MUI Icons
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Help as HelpIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import FactSmGridDetailPanel, {
    DETAIL_PANEL_PB_BUFFER_HEIGHT,
    DETAIL_PANEL_PY_HEIGHT,
    DETAIL_PANEL_TH_HEIGHT,
    DETAIL_PANEL_TR_HEIGHT,
} from 'components/FactSmGridDetailPanel';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils
import { formatNumber } from 'utils';

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME, SQL_TIME } from 'constants/Moment';

interface FacturationTabPanelProps {
    tabIndexAsString: string;
    factures: any[];
    patients_count: number;
    total_prelim: number;
    total_final: number;
    date_service: Moment;
}

export default function FacturationTabPanel({
    tabIndexAsString,
    factures,
    patients_count,
    total_prelim,
    total_final,
    date_service,
}: FacturationTabPanelProps) {
    const theme = useTheme();

    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
        setDetailPanelExpandedRowIds(newIds);
    }, []);

    const [expandAllChecked, setExpandAllChecked] = React.useState(false);
    const handleChangeExpandAllSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setExpandAllChecked(checked);
        if (checked) {
            setDetailPanelExpandedRowIds(factures.map((facture) => facture.reqt_id));
        } else {
            setDetailPanelExpandedRowIds([]);
        }
    };

    // Columns
    const columns = useMemo(() => getFactureListColumns(), []);

    return (
        <TabPanel value={tabIndexAsString}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} lg={3}>
                    <Typography variant="h6" fontWeight={500}>
                        Nombre de patients vus: {patients_count}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Typography variant="h6" fontWeight={500}>
                        Montant préliminaire: {formatNumber(total_prelim)} $
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Typography variant="h6" fontWeight={500}>
                        Montant final: {formatNumber(total_final)} $
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={3}
                    display="flex"
                    sx={(theme) => ({
                        [theme.breakpoints.up('lg')]: {
                            justifyContent: 'flex-end',
                        },
                    })}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={<Switch checked={expandAllChecked} onChange={handleChangeExpandAllSwitch} />}
                            label="Développer toutes les factures"
                        />
                    </FormGroup>
                </Grid>

                <Grid item xs={12} mb={-1}>
                    <Typography sx={{ color: 'info.main' }}>
                        ** Pour afficher le détail d'une facture, veuillez cliquer sur la flèche à la gauche de la date
                        de service.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <DataGridPro
                        getRowId={(row) => row.reqt_id}
                        rows={factures}
                        columns={columns}
                        // pagination
                        // pageSize={pageSize}
                        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        // rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        autoHeight
                        disableSelectionOnClick
                        rowThreshold={0}
                        getDetailPanelContent={(params) => (
                            <FactSmGridDetailPanel
                                daily={true}
                                date_service={date_service}
                                params={params}
                                containerSx={{
                                    bgcolor: 'rgb(230,230,230)',
                                }}
                                tableRowSx={{ bgcolor: lighten(theme.palette.primary.main, 0.2) }}
                                tableRowTextColor="white"
                            />
                        )}
                        getDetailPanelHeight={React.useCallback(
                            ({ row }) =>
                                DETAIL_PANEL_TH_HEIGHT +
                                row.FactLignes.length * DETAIL_PANEL_TR_HEIGHT +
                                DETAIL_PANEL_PY_HEIGHT +
                                DETAIL_PANEL_PB_BUFFER_HEIGHT,
                            [],
                        )}
                        detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                        onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                        components={{
                            LoadingOverlay: DataGridLoader,
                            DetailPanelExpandIcon: React.useCallback(() => <ExpandMoreIcon color="info" />, []),
                            DetailPanelCollapseIcon: React.useCallback(() => <ExpandLessIcon color="info" />, []),
                        }}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    );
}
