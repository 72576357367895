import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Avatar,
    AvatarGroup,
    Box,
    Breadcrumbs,
    Button,
    ButtonBase,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
    SxProps,
    ToggleButtonGroup,
    ToggleButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Fab,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Tooltip,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// MUI Grid
import { DataGridPro, GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import {
    Cancel as CancelIcon,
    NavigateNext as NavigateNextIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Visibility as VisibilityIcon,
    Male as MaleIcon,
    Female as FemaleIcon,
    OpenInNew as OpenInNewIcon,
    ExpandMore as ExpandMoreIcon,
    Help as HelpIcon,
    PriorityHigh as PriorityHighIcon,
} from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import Loading from 'components/Loading';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';

// Redux
import {} from 'redux';

// Utils
import { capitalize, formatNumber } from 'utils';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    BOOLEAN_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

const GET_FACTURE_INIT = gql`
    query GetFactureInit($nce: Int, $nci: BigInt) {
        facture(nce: $nce, nci: $nci) {
            nce
            nci
        }
    }
`;

const GET_FACTURE = gql`
    query GetFacture($nce: Int, $nci: BigInt, $revision: Int!) {
        facture(nce: $nce, nci: $nci, revision: $revision) {
            reqt_id
            nce
            nci
            type_service_id
            revision
            licence
            poste_id
            poste_mod
            jeton
            statut_recv
            is_canceled
            lot_id
            created_at
            ec_id

            # TOTAL
            type_service_nom

            Lot {
                lotid
                agence
                ouverture
                fermeture
                utilisateur
                nbredp
                lasttransmission
                enattente
                annule
                utilisateuredit
                actif
                refact

                Utilisateur {
                    idname
                }
                UtilisateurEdit {
                    idname
                }
            }

            Medecin {
                licence
                prenom
                nom
                prenomsa
                nomsa
                male
                specialiste
                lieutra
                retraite
            }
            Poste {
                numero
                modulateur
                description
                descriptionsa
                cab
                clsc
                cli
                urg
                usi
                adm
                ger
                psy
                pal
                rea
                heb
                udc
                umf
                actif

                PosteAdresse {
                    no
                    alias
                    nom
                    adresse
                    ville
                    zip
                }
            }

            FactDebutPeriode {
                nce
                revision
                debutperiode
            }
            FactElmContx {
                nce
                revision
                codeelmcontx
                codefactlink

                ElementContx {
                    elementno
                    entente
                    codeelement
                    texte
                    textesa
                    niveau
                    debut
                    fin
                }
            }

            FactLieuGeo {
                nce
                revision
                typeid
                idlieu
                typelieu
                codeprecilieu
                nobureau

                Localite {
                    localiteno
                    code
                    nom
                    debut
                    fin
                }

                LieuCodePrecision {
                    entente
                    code
                    description
                    geo
                    phys
                }
            }
            FactLieuPhys {
                nce
                revision
                typeid
                idlieu
                nosectact
                codeprecisectact
                codeprecilieu

                Poste {
                    description
                }

                SecteurActivite {
                    numero
                    description
                }

                LieuCodePrecision {
                    entente
                    code
                    description
                    geo
                    phys
                }
            }

            FactLignes {
                ligneno
                nce
                revision
                rank
                mdgrppstno
                typelmfact
                codefact
                datefact
                coderole
                debutelmfact
                finelmfact
                coderef
                mntprelim
                mntfinal
                statrecv

                # Child
                FactLigneElmContx {
                    ligneno
                    codeelmcontx

                    ElementContx {
                        elementno
                        entente
                        codeelement
                        texte
                        textesa
                        niveau
                        debut
                        fin
                    }
                }
                FactLigneElmMesure {
                    ligneno
                    codeelmmesure
                    valmesure

                    ElementMesure {
                        code
                        nom
                        typeunite
                    }
                }
                FactLigneFd {
                    ligneno
                    typefd
                    codetransport
                    nbrkmaller
                    nbrkmretour
                    nbrhrsdeplacement
                    nbrhrsattente

                    TYPE_FD
                    METHODE_TRANSPORT
                }
                FactLigneFdFrais {
                    ligneno
                    rank
                    typefrais
                    frais
                }
                FactLigneFormExpl {
                    ligneno
                    formuexpl
                }
                FactLigneInfoRemb {
                    ligneno
                    mntpercu
                }
                FactLigneLieuGeo {
                    ligneno
                    typelieu
                    codeprecilieu
                    no_bur
                }
                FactLigneLieuPhys {
                    ligneno
                    nosectact
                    codeprecisectact
                    codeprecilieu
                }
                FactLigneLieuRef {
                    ligneno
                    typereflieu
                    typeidlieu
                    idlieu
                }
                FactLigneProfRef {
                    ligneno
                    typerefprof
                    typeidprof
                    identifiant
                    dh_demconsult
                }
                FactLigneProfRefNc {
                    ligneno
                    typerefprof
                    codeterritoire
                    nom
                    prenom
                    typeprof
                    dh_demconsult
                }
                FactLigneRankDm {
                    ligneno
                    rank
                    nce
                    revision
                }
                CodeFact {
                    codefactno
                    entente
                    code
                    description
                    typecode
                    debut
                    fin
                    descriptionsa
                }
                MessageExplRecev {
                    section
                    codemsgexpl
                    texte
                    id
                }
            }

            FactPersObj {
                personneobjetno
                nce
                revision
            }

            FactRmx {
                nce
                revision
                dept
                debutperiode
                finperiode
                hrstotal
                minstotal
                demiperdiem
                csi
                cs2
                nommand
                datemand
                nomsign
                datesign
                once
                attestation
            }
            FactLignesRmx {
                nce
                revision
                lineno
                linedt
                am
                pm
                ref
                demiperdiem

                FactLigneRmxDetails {
                    nce
                    revision
                    lineno
                    rank
                    ref
                    code
                    sd
                    hr
                    min
                }
            }

            FactVac {
                nce
                revision
                debutperiode
                finperiode
                hrstotal
                minstotal
                csi
                cs2
                nommand
                datemand
                nomsign
                datesign
                once
                attestation
            }
            FactVacDepl {
                nce
                revision
                postea
                posteb
                dtarrivee
                hrsdepl
                minsdepl
                km
                montant

                PosteDepart {
                    description
                }
                PosteArrivee {
                    description
                }
            }
            FactLignesVac {
                nce
                revision
                lineno
                linedt
                moderem
                nuit
                am
                pm
                soir

                FactLigneVacDetails {
                    nce
                    revision
                    lineno
                    rank
                    ref
                    code
                    sd
                    hr
                    min
                }
            }

            FactureFd {
                nce
                revision
                dthrdepart
                typeidlieudepart
                idlieudepart
                dthrarrivee
                typeidlieuarrivee
                idlieuarrivee

                PosteDepart {
                    description
                }
                LocaliteDepart {
                    localiteno
                    code
                    nom
                    debut
                    fin
                }
                PosteArrivee {
                    description
                }
                LocaliteArrivee {
                    localiteno
                    code
                    nom
                    debut
                    fin
                }
            }

            EcMdGrps {
                id
                licence
                grp
                ec_id

                Ec {
                    created_at
                    updated_at
                    date_ec
                    date_coupure
                    dd_periode_fact
                    df_periode_fact
                    no_paiement
                    fmp_id
                }

                Ec1721(nci: $nci, revision: $revision) {
                    mdgrpid
                    nci
                    noligne
                    nam
                    dateoccurrence
                    codetran
                    typeident
                    valeurident
                    montantprel
                    montantpaye
                    codemsgrfp
                    codemsgrfp1
                    codemsgrfp2
                    codemsgrfp3
                    codemsgrfp4
                    codemsgrfp5
                    id
                    rev
                    dateec

                    MessageExpl {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl1 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl2 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl3 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl4 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl5 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                }
                Ec1722(nci: $nci, revision: $revision) {
                    mdgrpid
                    nci
                    noligne
                    nam
                    dateoccurrence
                    codetran
                    typeident
                    valeurident
                    montantant
                    montantnouv
                    montantpaye
                    codemsgrfp1
                    codemsgrfp2
                    codemsgrfp3
                    codemsgrfp4
                    codemsgrfp5
                    id
                    rev
                    dateec

                    MessageExpl1 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl2 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl3 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl4 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl5 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                }
                Ec1725(nci: $nci, revision: $revision) {
                    mdgrpid
                    nci
                    noligne
                    nam
                    dateoccurrence
                    codetran
                    montantremb
                    typeident
                    valeurident
                    montantant
                    montantnouv
                    montantpaye
                    codemsgrfp1
                    codemsgrfp2
                    codemsgrfp3
                    codemsgrfp4
                    codemsgrfp5
                    id
                    rev
                    dateec

                    MessageExpl1 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl2 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl3 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl4 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                    MessageExpl5 {
                        entente
                        codemsgexpl
                        texte
                        categorie
                        typefact
                        debut
                        fin
                    }
                }
            }
        }
    }
`;

const GET_FACTURE_VERSIONS = gql`
    query GetFactureVersions($nce: Int, $nci: BigInt) {
        factures(nce: $nce, nci: $nci) {
            reqt_id
            nce
            nci
            type_service_id
            revision
            licence
            poste_id
            poste_mod
            jeton
            statut_recv
            is_canceled
            lot_id
            created_at
            ec_id

            Lot {
                lotid
                agence
                ouverture
                fermeture
                utilisateur
                nbredp
                lasttransmission
                enattente
                annule
                utilisateuredit
                actif
                refact
            }
        }
    }
`;

export default function FactureShow() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const search_nce = searchParams.get('nce');
    const search_nci = searchParams.get('nci');
    const search_revision = searchParams.get('revision');

    const {
        loading: factureInitLoading,
        error: factureInitError,
        data: factureInitData,
    } = useQuery(GET_FACTURE_INIT, {
        variables: {
            nce: search_nce ? parseInt(search_nce) : null,
            nci: search_nci ? parseInt(search_nci) : null,
        },
    });

    const {
        loading: factureLoading,
        error: factureError,
        data: factureData,
    } = useQuery(GET_FACTURE, {
        variables: {
            nce: factureInitData?.facture.nce,
            nci: factureInitData?.facture.nci,
            revision: search_revision ? parseInt(search_revision) : null,
        },
        skip: !factureInitData?.facture,
    });

    const {
        loading: factureVersionsLoading,
        error: factureVersionError,
        data: factureVersionsData,
    } = useQuery(GET_FACTURE_VERSIONS, {
        variables: {
            nce: search_nce ? parseInt(search_nce) : null,
            nci: search_nci ? parseInt(search_nci) : null,
        },
    });

    const handleChangeRevision = (event: React.MouseEvent<HTMLElement>, newRevision: string) => {
        if (search_nce) {
            navigate(`/facture?nce=${search_nce}&revision=${newRevision}`);
        } else if (search_nci) {
            navigate(`/facture?nci=${search_nci}&revision=${newRevision}`);
        }
    };

    const [isMetaDialogOpen, setIsMetaDialogOpen] = useState<boolean>(false);
    const handleCloseMetaDialog = () => {
        setIsMetaDialogOpen(false);
    };

    const [isLotDialogOpen, setIsLotDialogOpen] = useState<boolean>(false);
    const handleCloseLotDialog = () => {
        setIsLotDialogOpen(false);
    };

    const [isCodeFactDialogOpen, setIsCodeFactDialogOpen] = useState<boolean>(false);
    const [codeFact, setCodeFact] = useState<any | null>(null);
    const handleOpenCodeFactDialog = (codeFact: any) => {
        setCodeFact(codeFact);
        setIsCodeFactDialogOpen(true);
    };
    const handleCloseCodeFactDialog = () => {
        setIsCodeFactDialogOpen(false);
    };

    const [isFactLigneHistoryDialogOpen, setIsFactLigneHistoryDialogOpen] = useState<boolean>(false);
    const [selectedFactLigne, setSelectedFactLigne] = useState<any | null>(null);
    const handleOpenFactLigneHistoryDialog = (factLigne: any = null) => {
        setSelectedFactLigne(factLigne);
        setIsFactLigneHistoryDialogOpen(true);
    };
    const handleCloseFactLigneHistoryDialog = () => {
        setIsFactLigneHistoryDialogOpen(false);
    };

    const handleClickMetaButton = () => {
        setIsMetaDialogOpen(true);
    };
    const handleClickLotButton = () => {
        setIsLotDialogOpen(true);
    };
    const handleClickCodeFactButton = () => {
        setIsCodeFactDialogOpen(true);
    };

    const breadcrumbs = [
        <Typography key="1" fontSize={15}>
            Demandes
        </Typography>,
        <Typography key="1" fontSize={15}>
            {search_nce ? search_nce : search_nci}
        </Typography>,
        <Typography key="1" fontSize={15}>
            V-{search_revision}
        </Typography>,
    ];

    if (factureError) return <Typography>Error! {factureError.message}</Typography>;

    const poste = factureData?.facture?.Poste;
    /**
     * Exemples de factures avec plusieurs revisions:
     * - 1916984
     * -
     */
    return (
        <>
            <Grid container alignItems="flex-start">
                <Grid item xs={7}>
                    <Typography variant="h5" fontWeight="600">
                        DP #{search_nce ? search_nce : search_nci} (V-{search_revision})
                    </Typography>
                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={{ mt: 0, mb: 5 }}>
                        {breadcrumbs}
                    </Breadcrumbs>

                    <Grid item xs={10} mt={6}>
                        <MedecinComponent factureData={factureData} />
                    </Grid>
                </Grid>

                <Grid item xs={5}>
                    <TopRightComponent
                        factureData={factureData}
                        factureVersionsData={factureVersionsData}
                        handleChangeRevision={handleChangeRevision}
                        handleClickMetaButton={handleClickMetaButton}
                        handleClickLotButton={handleClickLotButton}
                        handleOpenFactLigneHistoryDialog={handleOpenFactLigneHistoryDialog}
                    />
                </Grid>

                <Grid item xs={12} mt={1}>
                    {factureInitLoading || factureLoading ? (
                        <Loading />
                    ) : (
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="body1" fontSize={17} fontWeight="500">
                                    Type de demande: {factureData?.facture?.type_service_nom}
                                </Typography>
                                {poste && (
                                    <Typography variant="body1" fontSize={15} mt={1.5}>
                                        Poste: {poste.description} (#{poste.numero})
                                    </Typography>
                                )}
                            </Grid>

                            <FactElmContxComponent factureData={factureData} />

                            <Grid item xs={12} container justifyContent="space-between" mt={7} mb={5}>
                                <Grid item xs={5}>
                                    <PersObjComponent factureData={factureData} />
                                    <FactureFdDepartComponent factureData={factureData} />
                                    <FactureRmxComponent factureData={factureData} />
                                    <FactureVacDeplComponent factureData={factureData} />
                                </Grid>

                                <Grid item xs={5}>
                                    <FactLieuPhysComponent factureData={factureData} />
                                    <FactLieuGeoComponent factureData={factureData} />
                                    <FactureFdArriveeComponent factureData={factureData} />
                                </Grid>
                            </Grid>

                            <DataGridComponent
                                factureData={factureData}
                                handleOpenCodeFactDialog={handleOpenCodeFactDialog}
                                handleOpenFactLigneHistoryDialog={handleOpenFactLigneHistoryDialog}
                            />
                        </Grid>
                    )}
                </Grid>

                <MetaDialog
                    factureData={factureData}
                    isMetaDialogOpen={isMetaDialogOpen}
                    handleCloseMetaDialog={handleCloseMetaDialog}
                />
                <LotDialog
                    factureData={factureData}
                    isLotDialogOpen={isLotDialogOpen}
                    handleCloseLotDialog={handleCloseLotDialog}
                />
                <CodeFactDialog
                    codeFact={codeFact}
                    isCodeFactDialogOpen={isCodeFactDialogOpen}
                    handleCloseCodeFactDialog={handleCloseCodeFactDialog}
                />
                <FactLigneHistoryDialog
                    factLigne={selectedFactLigne}
                    factureData={factureData}
                    isFactLigneHistoryDialogOpen={isFactLigneHistoryDialogOpen}
                    handleCloseFactLigneHistoryDialog={handleCloseFactLigneHistoryDialog}
                />
            </Grid>
        </>
    );
}

const SectionTitle = ({ text }: { text: string }) => (
    <Typography variant="body2" fontWeight="500" fontSize={17}>
        {text}
    </Typography>
);
const SectionBodyText = ({ text }: { text: string }) => (
    <Typography variant="subtitle1" fontSize={15}>
        {text}
    </Typography>
);

const DataGridComponent = ({ factureData, handleOpenCodeFactDialog, handleOpenFactLigneHistoryDialog }) => {
    const facture = factureData?.facture;

    if (!facture) return null;

    let columns: null | any[] = null;
    let rows: null | any[] = null;
    let rowIdField: string = '';
    switch (facture.type_service_id) {
        case 0:
            // SM
            columns = getFactureLignesSmTableColumns(
                handleOpenCodeFactDialog,
                handleOpenFactLigneHistoryDialog,
                facture.Ec1721,
            );
            rows = facture.FactLignes;
            rowIdField = 'ligneno';
            break;
        case 1:
            // FD
            columns = getFactureLignesSmTableColumns(
                handleOpenCodeFactDialog,
                handleOpenFactLigneHistoryDialog,
                facture.Ec1721,
            );
            rows = facture.FactLignes;
            rowIdField = 'ligneno';
            break;
        case 7:
            // RMX
            columns = getFactureLignesRmxTableColumns(handleOpenCodeFactDialog);
            rows = facture.FactLignesRmx;
            rowIdField = 'lineno';
            break;
        case 8:
            // VAC
            columns = getFactureLignesVacTableColumns(handleOpenCodeFactDialog);
            rows = facture.FactLignesVac;
            rowIdField = 'lineno';
            break;
    }

    if (columns && rows && rowIdField) {
        return (
            <DataGridPro
                getRowId={(row) => row[rowIdField]}
                rows={rows}
                columns={columns}
                pagination
                pageSize={100}
                rowsPerPageOptions={[25, 50, 75, 100]}
                autoHeight
                disableSelectionOnClick
                hideFooter
            />
        );
    } else {
        return <></>;
    }
};

const TopRightComponent = ({
    factureData,
    factureVersionsData,
    handleChangeRevision,
    handleClickMetaButton,
    handleClickLotButton,
    handleOpenFactLigneHistoryDialog,
}) => {
    const facture = factureData?.facture;
    const factureVersions = factureVersionsData?.factures;

    if (facture && factureVersions) {
        const { revision: selectedRevision } = facture;

        return (
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} container justifyContent="flex-end">
                    <ToggleButtonGroup
                        color="primary"
                        value={selectedRevision.toString()}
                        exclusive
                        onChange={handleChangeRevision}
                    >
                        {[...factureVersions]
                            ?.sort((a, b) => {
                                return a.revision - b.revision;
                            })
                            .map((facture, idx) => {
                                const { revision, is_canceled, lot_id, ec_id } = facture;

                                return (
                                    <ToggleButton
                                        key={idx}
                                        value={revision.toString()}
                                        disabled={revision.toString() === selectedRevision.toString()}
                                    >
                                        <BooleanIcon value={!is_canceled} sx={{ mr: 1 }} />
                                        V-{revision}
                                        <br />
                                        EC-{ec_id}
                                    </ToggleButton>
                                );
                            })}
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ mr: 2 }}
                        onClick={handleClickMetaButton}
                        endIcon={<VisibilityIcon />}
                    >
                        META
                    </Button>
                    <Button variant="contained" size="small" color="info" sx={{ mr: 2 }}>
                        EC #{factureData?.facture?.ec_id}
                    </Button>
                    <Button variant="contained" size="small" color="info" onClick={handleClickLotButton}>
                        Lot #{factureData?.facture?.lot_id}
                    </Button>
                </Grid>

                <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                        onClick={() => {
                            handleOpenFactLigneHistoryDialog();
                        }}
                        variant="contained"
                        size="small"
                        color="warning"
                    >
                        Reconstitution EC
                    </Button>
                </Grid>
            </Grid>
        );
    } else {
        return null;
    }
};

const FactElmContxComponent = ({ factureData }) => {
    if (factureData?.facture?.FactElmContx && factureData?.facture?.FactElmContx.length > 0) {
        return (
            <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="body1" textTransform="uppercase" fontSize={14} sx={{ mr: 1 }}>
                    Contextes:
                </Typography>
                <FactElmContxList factureData={factureData} />
            </Box>
        );
    } else {
        return null;
    }
};

const FactElmContxList = ({ factureData }) => {
    return (factureData?.facture?.FactElmContx || []).map((factElmContx, idx) => {
        const { codeelmcontx, codefactlink, ElementContx } = factElmContx;

        if (ElementContx) {
            const { elementno, entente, codeelement, texte, textesa, niveau, debut, fin } = ElementContx;

            return (
                <Tooltip
                    key={idx}
                    title={
                        <>
                            <Typography variant="body2" fontWeight="500">
                                Info sur l'élément contexte
                            </Typography>
                            <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                            <Typography variant="body2">
                                Élément contexte: {texte} (#{codeelement})
                            </Typography>
                            <Typography variant="body2">Entente: {entente}</Typography>
                            <Typography variant="body2">Date de début d'effectivité: {debut}</Typography>
                            <Typography variant="body2">Date de fin d'effectivité: {fin}</Typography>
                            <Typography variant="body2">codefactlink: {codefactlink}</Typography>
                        </>
                    }
                    placement="top"
                    arrow
                    sx={{ mr: 1 }}
                >
                    <Chip size="small" label={`#${codeelmcontx}`} clickable />
                </Tooltip>
            );
        } else {
            return <Chip size="small" label={`#${codeelmcontx}`} />;
        }
    });
};

const MedecinComponent = ({ factureData }) => {
    const medecin = factureData?.facture?.Medecin;
    const navigate = useNavigate();

    if (medecin) {
        const { licence, prenom, nom, specialiste, male } = medecin;

        return (
            <>
                <Box display="flex" alignItems="center">
                    {male ? (
                        <MaleIcon
                            sx={{
                                color: 'blue1Dark.main',
                                fontSize: 25,
                                ml: '-7px',
                                mr: 1,
                            }}
                        />
                    ) : (
                        <FemaleIcon
                            sx={{
                                color: 'pink1Dark.main',
                                fontSize: 25,
                                ml: '-7px',
                                mr: 1,
                            }}
                        />
                    )}

                    <Typography variant="h5">
                        {male ? 'Dr.' : 'Dre.'} {nom}, {prenom} (#{licence})
                    </Typography>

                    <IconButton size="medium" color="primary" href={`/mds/${licence}`} target="_blank">
                        <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                </Box>

                <Divider sx={{ mt: 1, mb: 2 }} />
            </>
        );
    } else {
        return null;
    }
};

const PersObjComponent = ({ factureData }) => {
    const persObj = factureData?.facture?.FactPersObj;

    if (persObj) {
        const { personneobjetno } = persObj;

        return (
            <>
                <SectionTitle text="Patient:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <SectionBodyText text={personneobjetno} />
            </>
        );
    } else {
        return null;
    }
};

const FactLieuPhysComponent = ({ factureData }) => {
    const lieuPhys = factureData?.facture?.FactLieuPhys;

    if (lieuPhys) {
        const {
            codeprecilieu,
            codeprecisectact,
            idlieu,
            nosectact,
            typeid,
            SecteurActivite,
            Poste,
            LieuCodePrecision,
        } = lieuPhys;
        const { description: descriptionSecteurActivite } = SecteurActivite || {};
        const { description: descriptionPoste } = Poste || {};

        return (
            <>
                <SectionTitle text="Lieu physique:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <SectionBodyText text={`Poste: ${descriptionPoste || 'Non spécifié'} ${idlieu && `(#${idlieu})`}`} />
                <SectionBodyText
                    text={`Secteur d'activité: ${descriptionSecteurActivite || "Aucun secteur d'activité spécifié"} ${
                        nosectact && `(#${nosectact})`
                    }`}
                />
                {LieuCodePrecision && (
                    <Box display="flex" alignItems="center">
                        <SectionBodyText text={`Code précisé lieu: ${codeprecilieu}`} />
                        <LieuCodePrecisionTooltip lieuCodePrecision={LieuCodePrecision} />
                    </Box>
                )}
            </>
        );
    } else {
        return null;
    }
};

const FactLieuGeoComponent = ({ factureData }) => {
    const lieuGeo = factureData?.facture?.FactLieuGeo;

    if (lieuGeo) {
        const {
            nce,
            revision,
            typeid,
            idlieu,
            typelieu,
            codeprecilieu,
            nobureau,

            Localite,
            LieuCodePrecision,
        } = lieuGeo;

        const { localiteno, code, nom, debut, fin } = Localite || {};

        return (
            <>
                <SectionTitle text="Lieu géographique:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <SectionBodyText text={`Localité: ${nom || 'Localité introuvable'} ${idlieu && `(#${idlieu})`}`} />
                {LieuCodePrecision && (
                    <Box display="flex" alignItems="center">
                        <SectionBodyText text={`Code précisé lieu: ${codeprecilieu}`} />
                        <LieuCodePrecisionTooltip lieuCodePrecision={LieuCodePrecision} />
                    </Box>
                )}
            </>
        );
    } else {
        return null;
    }
};

const LieuCodePrecisionTooltip = ({ lieuCodePrecision }) => {
    const { entente, code, description: descriptionLieuCodePrecision, geo, phys } = lieuCodePrecision || {};

    return (
        <Tooltip
            title={
                <>
                    <Typography variant="body2" fontWeight="500">
                        Code précisé lieu
                    </Typography>
                    <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                    <Typography variant="body2">Entente: {entente}</Typography>
                    <Typography variant="body2">Code: {code}</Typography>
                    <Typography variant="body2">Description: {descriptionLieuCodePrecision}</Typography>
                </>
            }
            placement="right"
            arrow
        >
            <IconButton>
                <HelpIcon fontSize="small" color="info" />
            </IconButton>
        </Tooltip>
    );
};

const FactureRmxComponent = ({ factureData }) => {
    const factRmx = factureData?.facture?.FactRmx;

    if (factRmx) {
        const {
            dept,
            debutperiode,
            finperiode,
            hrstotal,
            minstotal,
            demiperdiem,
            csi,
            cs2,
            nommand,
            datemand,
            nomsign,
            datesign,
            once,
            attestation,
        } = factRmx;

        return (
            <>
                <SectionTitle text="Information RMX:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <Grid container>
                    <Grid item xs={6}>
                        <SectionBodyText text={`Département: ${dept}`} />
                        <SectionBodyText text={`Période: ${debutperiode} au ${finperiode}`} />
                        <SectionBodyText text={`Total: ${hrstotal}h${minstotal < 10 ? `0${minstotal}` : minstotal}`} />
                        <SectionBodyText text={`Demi Per Diem: ${demiperdiem}`} />
                    </Grid>
                    <Grid item xs={6}>
                        <SectionBodyText text={`Mand: ${nommand} le ${datemand}`} />
                        <SectionBodyText text={`Sign: ${nomsign} le ${datesign}`} />
                        <SectionBodyText text={`Once: ${once}`} />
                        <SectionBodyText text={`Attestation: ${attestation}`} />
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return null;
    }
};

const FactureVacDeplComponent = ({ factureData }) => {
    const factVacDepl = factureData?.facture?.FactVacDepl;

    if (factVacDepl) {
        const { postea, posteb, dtarrivee, hrsdepl, minsdepl, km, montant, PosteDepart, PosteArrivee } = factVacDepl;

        const { description: descriptionPosteDepart } = PosteDepart || {};
        const { description: descriptionPosteArrivee } = PosteArrivee || {};

        return (
            <>
                <SectionTitle text="Information déplacement VAC:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <Grid container>
                    <Grid item xs={6}>
                        <SectionBodyText text={`Lieu départ: ${descriptionPosteDepart || postea}`} />
                        <SectionBodyText text={`Lieu d'arrivé: ${descriptionPosteArrivee || posteb}`} />
                        <SectionBodyText text={`Date d'arrivée: ${moment(dtarrivee).format(SQL_DATETIME)}`} />
                    </Grid>
                    <Grid item xs={6}>
                        <SectionBodyText text={`Hrs déplacement: ${hrsdepl}h`} />
                        <SectionBodyText text={`Mins déplacement: ${minsdepl < 10 ? `0${minsdepl}` : minsdepl}`} />
                        <SectionBodyText text={`Km: ${km}`} />
                        <SectionBodyText text={`Montant: ${montant}`} />
                    </Grid>
                </Grid>
            </>
        );
    } else {
        return null;
    }
};

const FactureFdDepartComponent = ({ factureData }) => {
    const factureFd = factureData?.facture?.FactureFd;

    if (factureFd) {
        const { dthrdepart, typeidlieudepart, idlieudepart } = factureFd;

        return (
            <>
                <SectionTitle text="Départ:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <SectionBodyText text={`Date/heure départ: ${moment(dthrdepart).format(SQL_DATETIME)}`} />

                {/* <Typography variant="subtitle1" fontSize={15}>Type du lieu: {typeidlieudepart}</Typography>
                <Typography variant="subtitle1" fontSize={15}>ID lieu: {idlieudepart}</Typography> */}
                <LieuComponent factureFd={factureFd} type="depart" />
            </>
        );
    } else {
        return null;
    }
};

const FactureFdArriveeComponent = ({ factureData }) => {
    const factureFd = factureData?.facture?.FactureFd;

    if (factureFd) {
        const { dthrarrivee, typeidlieuarrivee, idlieuarrivee } = factureFd;

        return (
            <>
                <SectionTitle text="Arrivée:" />
                <Divider sx={{ mt: 1, mb: 2 }} />

                <SectionBodyText text={`Date/heure arrivée: ${moment(dthrarrivee).format(SQL_DATETIME)}`} />

                {/* <Typography variant="subtitle1" fontSize={15}>Type du lieu: {typeidlieuarrivee}</Typography>
                <Typography variant="subtitle1" fontSize={15}>ID lieu: {idlieuarrivee}</Typography> */}
                <LieuComponent factureFd={factureFd} type="arrivee" />
            </>
        );
    } else {
        return null;
    }
};

const LieuComponent = ({ factureFd, type }: { factureFd: any; type: 'depart' | 'arrivee' }) => {
    const idlieu = factureFd[`idlieu${type}`];

    switch (factureFd[`typeidlieu${type}`]) {
        case '1':
            const posteDepart = factureFd[`Poste${capitalize(type)}`];

            if (posteDepart) {
                const { description } = posteDepart;

                return <SectionBodyText text={`Lieu: ${description} (Poste #${idlieu})`} />;
            } else {
                return <SectionBodyText text={`Poste introuvable`} />;
            }
        case '2':
            const codePostal = idlieu;

            return <SectionBodyText text={`Lieu: ${codePostal} (Code postal)`} />;
        case '3':
            const localiteDepart = factureFd[`Localite${capitalize(type)}`];

            if (localiteDepart) {
                const { localiteno, code, nom, debut, fin } = localiteDepart;

                return <SectionBodyText text={`Lieu: ${nom} (Localité #${idlieu})`} />;
            } else {
                return <SectionBodyText text={`Localité introuvable`} />;
            }
        default:
            return <SectionBodyText text={`Type de lieu non répertorié`} />;
    }
};

const MetaDialog = ({ factureData, isMetaDialogOpen, handleCloseMetaDialog }) => {
    const facture = factureData?.facture;

    if (facture) {
        const {
            reqt_id,
            nce,
            nci,
            type_service_id,
            revision,
            licence,
            poste_id,
            poste_mod,
            jeton,
            statut_recv,
            is_canceled,
            lot_id,
            created_at,
            ec_id,

            type_service_nom,
        } = facture;

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} open={isMetaDialogOpen} onClose={handleCloseMetaDialog}>
                {facture && (
                    <>
                        <DialogTitle>Information meta sur cette DP</DialogTitle>
                        <Chip
                            label={is_canceled ? 'DP Annulée' : 'DP Active'}
                            sx={{
                                position: 'absolute',
                                top: 20,
                                right: 20,
                                borderRadius: 2,
                                fontWeight: 'bold',
                                px: 0,
                                py: 0,
                                bgcolor: is_canceled ? 'red1Dark.main' : 'green1Dark.main',
                                color: is_canceled ? 'red1Light.main' : 'green1Light.main',
                            }}
                        ></Chip>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} mt={3} mb={2}>
                                    <Typography variant="h6" fontWeight="500">
                                        Créée le: {moment(created_at).format(SQL_DATETIME)}
                                    </Typography>
                                    <Divider sx={{ mt: 1.5 }} />
                                </Grid>

                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            NCE:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {nce}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Version:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {revision}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container spacing={2} mt={3}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Type de service
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {type_service_nom} (#{type_service_id})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Statut de recevabilité
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {statut_recv}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container spacing={2} mt={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" mb={2}>
                                            RAMQ - NCI:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {nci}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" mb={2}>
                                            RAMQ - No. Requête:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {reqt_id}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" mb={2}>
                                            RAMQ - Jeton
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {jeton}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseMetaDialog}>Fermer</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        );
    } else {
        return null;
    }
};

const LotDialog = ({ factureData, isLotDialogOpen, handleCloseLotDialog }) => {
    const lot = factureData?.facture?.Lot;

    if (lot) {
        const {
            lotid,
            agence,
            ouverture,
            fermeture,
            utilisateur,
            nbredp,
            lasttransmission,
            enattente,
            annule,
            utilisateuredit,
            actif,
            refact,
            Utilisateur,
            UtilisateurEdit,
        } = lot;

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} open={isLotDialogOpen} onClose={handleCloseLotDialog}>
                {lot && (
                    <>
                        <DialogTitle>Lot #{lotid}</DialogTitle>
                        <Chip
                            label={actif ? 'Ouvert' : 'Fermé'}
                            sx={{
                                position: 'absolute',
                                top: 20,
                                right: 20,
                                borderRadius: 2,
                                fontWeight: 'bold',
                                px: 0,
                                py: 0,
                                bgcolor: actif ? 'red1Dark.main' : 'green1Dark.main',
                                color: actif ? 'red1Light.main' : 'green1Light.main',
                            }}
                        ></Chip>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <Typography variant="h6">Facturé par: </Typography>

                                        <Chip
                                            avatar={
                                                <Avatar
                                                    src={
                                                        'https://storage.cloud.google.com/fmparadis-public-bucket-staging/admin-avatars/grogu.jpeg?authuser=2'
                                                    }
                                                />
                                            }
                                            label={capitalize(Utilisateur.idname)}
                                            variant="outlined"
                                            sx={{ ml: 1.5 }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid item xs={12} mt={3} mb={2}>
                                    <Typography variant="h6" fontWeight="500">
                                        Nombre de DPs: {nbredp}
                                    </Typography>
                                    <Divider sx={{ mt: 1.5 }} />
                                </Grid>

                                <Grid item xs={12} container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" mb={2}>
                                            Ouverture:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {moment(ouverture).format(SQL_DATETIME)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" mb={2}>
                                            Fermeture:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {moment(fermeture).format(SQL_DATETIME)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" mb={2}>
                                            Dernière transmission:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {moment(lasttransmission).format(SQL_DATETIME)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} mt={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" mb={2}>
                                                En attente:
                                            </Typography>
                                            <BooleanIcon value={enattente} fontSize="medium" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" mb={2}>
                                                Actif:
                                            </Typography>
                                            <BooleanIcon value={actif} fontSize="medium" />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography variant="body2" mb={2}>
                                                Refact:
                                            </Typography>
                                            <BooleanIcon value={refact} fontSize="medium" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseLotDialog}>Fermer</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        );
    } else {
        return null;
    }
};

const CodeFactDialog = ({ codeFact, isCodeFactDialogOpen, handleCloseCodeFactDialog }) => {
    if (codeFact) {
        const { codefactno, entente, code, description, typecode, debut, fin, descriptionsa } = codeFact;

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} open={isCodeFactDialogOpen} onClose={handleCloseCodeFactDialog}>
                {codeFact && (
                    <>
                        <DialogTitle>Information sur le code de facturation #{code}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12} container spacing={2} mt={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" mb={2}>
                                            Description du code:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {description}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container spacing={2} mt={3}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Code:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {code}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Code FMP:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {codefactno}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container spacing={2} mt={3}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Type de code:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {typecode}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Entente:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {entente}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container spacing={2} mt={3}>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Date de début d'effectivité du code de facturation:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {debut}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="body2" mb={2}>
                                            Date de fin d'effectivité du code de facturation:
                                        </Typography>
                                        <Typography variant="body1" fontWeight="500">
                                            {fin}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCodeFactDialog}>Fermer</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        );
    } else {
        return null;
    }
};

const FactLigneHistoryDialog = ({
    factLigne,
    factureData,
    isFactLigneHistoryDialogOpen,
    handleCloseFactLigneHistoryDialog,
}) => {
    const columns = useMemo(() => getEcLinesColumns(), []);

    if (factureData?.facture) {
        const { EcMdGrps } = factureData.facture;

        // const array = [...Ec1721, ...Ec1722, ...Ec1725];

        // let obj: any = {};

        // array.forEach((item, idx) => {
        //     if (!obj[item.mdgrpid]) obj[item.mdgrpid] = [];

        //     obj[item.mdgrpid].push(item);
        // });

        // console.log(obj);

        // console.log(factLigne);
        // console.log(Ec1721);
        // console.log(Ec1722);
        // console.log(Ec1725);

        return (
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={isFactLigneHistoryDialogOpen}
                onClose={handleCloseFactLigneHistoryDialog}
            >
                <>
                    <DialogTitle>
                        {factLigne
                            ? `Information sur la ligne #${factLigne.rank}`
                            : "Reconstitution de l'historique d'EC"}
                    </DialogTitle>
                    <DialogContent sx={{ mt: 3 }}>
                        {[...EcMdGrps]
                            .sort((a, b) => a.ec_id - b.ec_id)
                            .map((ecMdGrp, idx) => {
                                const {
                                    id,
                                    licence,
                                    grp,
                                    ec_id,
                                    // Ec: {
                                    //     created_at,
                                    //     updated_at,
                                    //     date_ec,
                                    //     date_coupure,
                                    //     dd_periode_fact,
                                    //     df_periode_fact,
                                    //     no_paiement,
                                    //     fmp_id,
                                    // },
                                    Ec1721,
                                    Ec1722,
                                    Ec1725,
                                } = ecMdGrp;

                                let nonEmptyEcs: any[] = [];

                                if (Ec1721.length > 0) {
                                    nonEmptyEcs.push({ rows: Ec1721, ec17type: '1721' });
                                }

                                if (Ec1722.length > 0) {
                                    nonEmptyEcs.push({ rows: Ec1722, ec17type: '1722' });
                                }

                                if (Ec1725.length > 0) {
                                    nonEmptyEcs.push({ rows: Ec1725, ec17type: '1725' });
                                }

                                return (
                                    <Box
                                        sx={{
                                            borderLeftWidth: '2px',
                                            borderLeftStyle: 'solid',
                                            borderColor:
                                                factureData.facture.ec_id === ec_id ? 'primary.main' : 'warning.main',
                                            pl: 2,
                                        }}
                                    >
                                        <Typography variant="h6">{/* EC {date_ec} ({no_paiement}) */}</Typography>
                                        <Divider sx={{ mt: 1, mb: 2 }} />

                                        <Grid container mb={3}>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" fontWeight="500" textAlign="center">
                                                    Mdgrp ID: {id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" fontWeight="500" textAlign="center">
                                                    EC ID: {ec_id}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Typography variant="body1" fontWeight="500" textAlign="center">
                                                    Groupe: {grp}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {/* {linesRendered} */}
                                        {nonEmptyEcs.map((nonEmptyEc, idx) => {
                                            const { rows, ec17type } = nonEmptyEc;

                                            let typeEcText = '';
                                            switch (ec17type) {
                                                case '1721':
                                                    typeEcText =
                                                        'Factures dont le montant final diffère du montant préliminaire';
                                                    break;
                                                case '1722':
                                                    typeEcText =
                                                        'Factures dont une ou plusieurs lignes ont été modifiées';
                                                    break;
                                                case '1725':
                                                    typeEcText = 'Factures dont...';
                                                    break;
                                            }

                                            return (
                                                <>
                                                    <Typography variant="h6" fontSize={18} mb={1}>
                                                        {typeEcText} ({ec17type})
                                                    </Typography>

                                                    <DataGridPro
                                                        getRowId={(row) => row.id}
                                                        rows={rows}
                                                        columns={columns}
                                                        pagination
                                                        pageSize={100}
                                                        rowsPerPageOptions={[25, 50, 75, 100]}
                                                        autoHeight
                                                        disableSelectionOnClick
                                                        hideFooter
                                                        initialState={{
                                                            sorting: {
                                                                sortModel: [{ field: 'noligne', sort: 'asc' }],
                                                            },
                                                        }}
                                                        sx={{ mb: 4 }}
                                                    />
                                                </>
                                            );
                                        })}
                                    </Box>
                                );
                            })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseFactLigneHistoryDialog}>Fermer</Button>
                    </DialogActions>
                </>
            </Dialog>
        );
    } else {
        return null;
    }
};

const getEcLinesColumns = (): GridColDefPro[] => [
    {
        field: 'noligne',
        headerName: 'Rank',
        width: 100,
    },
    {
        field: 'mntprelim',
        headerName: '$ Préliminaire',
        width: 140,
        align: 'right',
        valueGetter: (params: any) =>
            params.row.montantant !== undefined ? params.row.montantant : params.row.montantprel,
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'mntfinal',
        headerName: '$ Terminal',
        width: 140,
        align: 'right',
        valueGetter: (params: any) =>
            params.row.montantnouv !== undefined ? params.row.montantnouv : params.row.montantpaye,
        valueFormatter: (params: any) => `${formatNumber(params.value)} $`,
    },
    {
        field: 'diff',
        headerName: '$ Différence',
        width: 170,
        align: 'right',
        valueGetter: (params: any) =>
            params.row.__typename === 'Ec1721'
                ? params.row.montantpaye - params.row.montantprel
                : params.row.montantnouv - params.row.montantant,
        renderCell: (params: any) => {
            let percentage =
                (params.value /
                    (params.row.montantant !== undefined ? params.row.montantant : params.row.montantprel)) *
                100;

            if (isNaN(percentage)) {
                percentage = 0;
            }

            return (
                <Typography
                    variant="body2"
                    sx={{
                        color: params.value > 0 ? 'success.main' : params.value == 0 ? 'warning.main' : 'error.main',
                    }}
                >
                    {params.value > 0 ? '+ ' : params.value < 0 ? '- ' : ''}
                    {formatNumber(Math.abs(params.value))} $ {`(${formatNumber(percentage)}%)`}
                </Typography>
            );
        },
    },
    {
        field: 'messageExpl',
        headerName: 'Refus RAMQ',
        minWidth: 300,
        align: 'right',
        renderCell: (params: any) => {
            // const { rank, mntfinal, mntprelim } = params.row;

            // let ec1721LinkedToLigne = ec1721.find((item) => item.noligne === rank);

            // /**
            //  * The if statement below was added on 2022-03-22
            //  * Some EC1721 have a noligne === 0.
            //  * These EC1721 do not have a corresponding factligne because all factligne's rank start at 1
            //  * In the DB, we concluded that the EC1721 with a noligne === 0 were still valid and still needed to be manually pointed to the correct factligne
            //  * The if statement below attempts to achieve that
            //  */
            // if (!ec1721LinkedToLigne) {
            //     ec1721LinkedToLigne = ec1721.find(
            //         (item) =>
            //             item.noligne === 0 &&
            //             item.codetran !== 1 &&
            //             item.montantpaye === mntfinal &&
            //             item.montantprel === mntprelim,
            //     );
            // }

            return <Ec1721Tooltips ec1721LinkedToLigne={params.row} />;
        },
    },
    // // {
    // //     field: 'messageExpl',
    // //     headerName: 'Refus RAMQ',
    // //     minWidth: 300,
    // //     align: 'right',
    // //     renderCell: (params: any) => {
    // //         const { rank, mntfinal, mntprelim } = params.row;

    // //         let ec1721LinkedToLigne = ec1721.find((item) => item.noligne === rank);

    // //         /**
    // //          * The if statement below was added on 2022-03-22
    // //          * Some EC1721 have a noligne === 0.
    // //          * These EC1721 do not have a corresponding factligne because all factligne's rank start at 1
    // //          * In the DB, we concluded that the EC1721 with a noligne === 0 were still valid and still needed to be manually pointed to the correct factligne
    // //          * The if statement below attempts to achieve that
    // //          */
    // //         if (!ec1721LinkedToLigne) {
    // //             ec1721LinkedToLigne = ec1721.find(
    // //                 (item) =>
    // //                     item.noligne === 0 &&
    // //                     item.codetran !== 1 &&
    // //                     item.montantpaye === mntfinal &&
    // //                     item.montantprel === mntprelim,
    // //             );
    // //         }

    // //         return ec1721LinkedToLigne ? <Ec1721Tooltips ec1721LinkedToLigne={ec1721LinkedToLigne} /> : null;
    // //     },
    // // },
    // {
    //     field: 'diff',
    //     headerName: '$ Différence',
    //     width: 160,
    //     align: 'right',
    //     valueGetter: (params: any) => params.row.mntfinal - params.row.mntprelim,
    //     renderCell: (params: any) => (
    //         <Typography
    //             variant="body2"
    //             sx={{ color: params.value > 0 ? 'success.main' : params.value == 0 ? 'warning.main' : 'error.main' }}
    //         >
    //             {params.value > 0 ? '+ ' : params.value < 0 ? '- ' : ''}
    //             {Math.abs(params.value).toFixed(2)} ${' '}
    //             {`(${((params.row.mntfinal - params.row.mntprelim) / params.row.mntprelim) * 100}%)`}
    //         </Typography>
    //     ),
    // },
];

const getFactureLignesSmTableColumns = (
    handleOpenCodeFactDialog,
    handleOpenFactLigneHistoryDialog,
    ec1721,
): GridColDefPro[] => [
    {
        field: 'rank',
        headerName: 'Rank',
        width: 60,
    },
    {
        field: 'ligneno',
        headerName: '# Ligne fact.',
        width: 120,
    },
    // {
    //     field: 'revision',
    //     headerName: 'Nb. révisions',
    //     width: 120,
    // },
    {
        field: 'mdgrppstno',
        headerName: 'mdgrppstno',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'codefact',
        headerName: 'Code fact.',
        width: 120,
        valueGetter: (params) => params.row.CodeFact,
        renderCell: (params) => {
            const codeFact = params.value;
            const factLigneFd = params.row.FactLigneFd;

            let factLigneFdTooltip: ReactNode | null = null;
            if (factLigneFd) {
                const { nbrkmaller, nbrkmretour, nbrhrsdeplacement, nbrhrsattente, TYPE_FD, METHODE_TRANSPORT } =
                    factLigneFd;

                factLigneFdTooltip = (
                    <Tooltip
                        title={
                            <>
                                <Typography variant="body2" fontWeight="500">
                                    Info sur les frais de déplacement
                                </Typography>
                                <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                                <Typography variant="body2">Type frais: {TYPE_FD}</Typography>
                                <Typography variant="body2">Méthode transport: {METHODE_TRANSPORT}</Typography>
                                <Typography variant="body2">Nbr. km aller: {nbrkmaller}</Typography>
                                <Typography variant="body2">Nbr. km retour: {nbrkmretour}</Typography>
                                <Typography variant="body2">Nbr. hrs déplacement: {nbrhrsdeplacement}</Typography>
                                <Typography variant="body2">Nbr. hrs attente: {nbrhrsattente}</Typography>
                            </>
                        }
                        placement="right"
                        arrow
                    >
                        <IconButton>
                            <HelpIcon fontSize="small" color="info" />
                        </IconButton>
                    </Tooltip>
                );
            }

            if (codeFact)
                return (
                    <>
                        <Button
                            onClick={() => {
                                handleOpenCodeFactDialog(codeFact);
                            }}
                            variant="contained"
                            size="small"
                        >
                            {codeFact.code}
                        </Button>
                        {factLigneFdTooltip || null}
                    </>
                );
        },
    },
    {
        field: 'factLigneElmCtx',
        headerName: 'Éléments de contexte',
        minWidth: 180,
        flex: 1,
        valueGetter: (params) => params.row.FactLigneElmContx,
        renderCell: (params) => {
            const factLigneElmContx = params.value;

            if (factLigneElmContx)
                return factLigneElmContx.map((elmCtx, idx) => {
                    const { codeelmcontx, ligneno, ElementContx } = elmCtx;
                    const { elementno, entente, codeelement, texte, textesa, niveau, debut, fin } = ElementContx || {};

                    return (
                        <Tooltip
                            key={idx}
                            title={
                                <>
                                    <Typography variant="body2" fontWeight="500">
                                        Info sur l'élément contexte
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                                    <Typography variant="body2">
                                        Élément contexte: {texte} (#{codeelement})
                                    </Typography>
                                    <Typography variant="body2">Entente: {entente}</Typography>
                                    <Typography variant="body2">Date de début d'effectivité: {debut}</Typography>
                                    <Typography variant="body2">Date de fin d'effectivité: {fin}</Typography>
                                </>
                            }
                            placement="top"
                            arrow
                            sx={{ mr: 1 }}
                        >
                            <Chip size="small" label={`#${codeelmcontx}`} clickable />
                        </Tooltip>
                    );
                });
        },
    },
    {
        field: 'factLigneElmMesure',
        headerName: 'Éléments de mesure',
        width: 180,
        valueGetter: (params) => params.row.FactLigneElmMesure,
        renderCell: (params) => {
            const factLigneElmMesure = params.value;

            if (factLigneElmMesure)
                return factLigneElmMesure.map((elmMesure, idx) => {
                    const { codeelmmesure, valmesure } = elmMesure;
                    const ElementMesure = elmMesure?.ElementMesure;

                    const valeurMesure = parseInt(valmesure, 10) / 100;

                    return (
                        <Tooltip
                            key={idx}
                            title={
                                <>
                                    <Typography variant="body2" fontWeight="500">
                                        Info sur l'élément mesuré
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                                    <Typography variant="body2">
                                        Élément mesuré: {ElementMesure?.nom} (#{codeelmmesure})
                                    </Typography>
                                    <Typography variant="body2">
                                        Valeur mesurée: {valeurMesure}
                                        {ElementMesure?.typeunite}
                                    </Typography>
                                </>
                            }
                            placement="right"
                            arrow
                        >
                            <Chip size="small" label={`#${codeelmmesure}`} clickable />
                        </Tooltip>
                    );
                });
        },
    },
    {
        field: 'coderole',
        headerName: 'Code rôle',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'coderef',
        headerName: 'Code ref.',
        width: ID_COLUMN_WIDTH,
    },
    {
        field: 'mntprelim',
        headerName: '$ Préliminaire',
        width: 140,
        align: 'right',
        valueFormatter: (params: any) => `${params.value?.toFixed(2)} $`,
    },
    {
        field: 'mntfinal',
        headerName: '$ Terminal',
        width: 140,
        align: 'right',
        renderCell: (params: any) => {
            const mntfinal = `${params.value?.toFixed(2)} $`;
            const formuexpl = params.row.FactLigneFormExpl?.formuexpl;
            const rank = params.row.rank;

            // if (formuexpl) {
            return (
                <>
                    <Typography variant="body2">{mntfinal}</Typography>
                    {formuexpl && (
                        <Tooltip
                            title={
                                <>
                                    <Typography variant="body2" fontWeight="500">
                                        Info sur le calcul de la RAMQ
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                                    <Typography variant="body2" whiteSpace="pre-line">
                                        {formuexpl}
                                    </Typography>
                                </>
                            }
                            placement="right"
                            arrow
                        >
                            <IconButton>
                                <HelpIcon fontSize="small" color="info" />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            );
            // }
        },
    },
    {
        field: 'diff',
        headerName: '$ Différence',
        width: 160,
        align: 'right',
        valueGetter: (params: any) => params.row.mntfinal - params.row.mntprelim,
        renderCell: (params: any) => (
            <Typography
                variant="body2"
                sx={{ color: params.value > 0 ? 'success.main' : params.value == 0 ? 'warning.main' : 'error.main' }}
            >
                {params.value > 0 ? '+ ' : params.value < 0 ? '- ' : ''}
                {Math.abs(params.value).toFixed(2)} ${' '}
                {`(${(((params.row.mntfinal - params.row.mntprelim) / params.row.mntprelim) * 100).toFixed(2)}%)`}
            </Typography>
        ),
    },
    // {
    //     field: 'messageExpl2',
    //     headerName: 'Refs',
    //     minWidth: 300,
    //     align: 'right',
    //     renderCell: (params) => {
    //         const factLigne = params.row;

    //         return (
    //             <>
    //                 <Button
    //                     onClick={() => {
    //                         handleOpenFactLigneHistoryDialog(factLigne);
    //                     }}
    //                     variant="contained"
    //                     size="small"
    //                 >
    //                     {factLigne.rank}
    //                 </Button>
    //             </>
    //         );
    //     },
    // },
    {
        field: 'datefact',
        headerName: 'Date facturation',
        width: DATETIME_COLUMN_WIDTH,
    },
    {
        field: 'debutelmfact',
        headerName: 'Début elm. fact.',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => (params.value ? moment(params.value).format(SQL_DATETIME) : null),
    },
    {
        field: 'finelmfact',
        headerName: 'Fin elm. fact.',
        width: DATETIME_COLUMN_WIDTH,
        valueFormatter: (params: any) => (params.value ? moment(params.value).format(SQL_DATETIME) : null),
    },
    {
        field: 'statrecv',
        headerName: 'Statut recv. (1|2|3)',
        width: 150,
    },
];

const Ec1721Tooltips = ({ ec1721LinkedToLigne }) => {
    const array: any[] = [];

    console.log(ec1721LinkedToLigne);
    Object.keys(ec1721LinkedToLigne).forEach((key) => {
        if (
            ['MessageExpl', 'MessageExpl1', 'MessageExpl2', 'MessageExpl3', 'MessageExpl4', 'MessageExpl5'].includes(
                key,
            ) &&
            ec1721LinkedToLigne[key] !== null
        )
            array.push(ec1721LinkedToLigne[key]);
    });

    const tooltips = array.map((item, idx) => {
        const { entente, codemsgexpl, texte, categorie, typefact, debut, fin } = item;

        return (
            <Tooltip
                title={
                    <>
                        <Typography variant="body2" fontWeight="500">
                            Info sur un refus de la RAMQ
                        </Typography>
                        <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                        <Typography variant="body2">Code du message: {codemsgexpl}</Typography>
                        <br />
                        <Typography variant="body2">Message explicatif: {texte}</Typography>
                    </>
                }
                placement="top"
                arrow
            >
                <Chip size="small" label={`#${codemsgexpl}`} clickable color="error" sx={{ ml: 1 }} />
            </Tooltip>
        );
    });

    return <>{tooltips}</>;
};

const getFactureLignesRmxTableColumns = (handleOpenCodeFactDialog): GridColDefPro[] => [
    {
        field: 'lineno',
        headerName: 'Rank',
        width: 60,
    },
    {
        field: 'linedt',
        headerName: 'Date',
        width: DATE_COLUMN_WIDTH,
    },
    {
        field: 'am',
        headerName: 'AM',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'pm',
        headerName: 'PM',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'demiperdiem',
        headerName: 'Demi Per Diem',
        width: 150,
    },
    {
        field: 'ref',
        headerName: 'ref',
        width: 100,
    },
    {
        field: 'factLigneRmxDetails',
        headerName: 'Détails RMX',
        minWidth: 180,
        flex: 1,
        valueGetter: (params) => params.row.FactLigneRmxDetails,
        renderCell: (params) => {
            const factLigneRmxDetails = params.value;

            if (factLigneRmxDetails)
                return factLigneRmxDetails.map((rmxDetails, idx) => {
                    const { nce, revision, lineno, rank, ref, code, sd, hr, min } = rmxDetails;

                    return (
                        <Tooltip
                            key={idx}
                            title={
                                <>
                                    <Typography variant="body2" fontWeight="500">
                                        Info sur RMX
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                                    <Typography variant="body2">Code: #{code}</Typography>
                                    <Typography variant="body2">SD: {sd}</Typography>
                                    <Typography variant="body2">Ref: {ref}</Typography>
                                    <Typography variant="body2">HR: {hr}</Typography>
                                    <Typography variant="body2">MIN: {min}</Typography>
                                </>
                            }
                            placement="left"
                            arrow
                            sx={{ mr: 1 }}
                        >
                            <Chip size="small" label={`${hr}h${min < 10 ? `0${min}` : min}`} clickable />
                        </Tooltip>
                    );
                });
        },
    },
];

const getFactureLignesVacTableColumns = (handleOpenCodeFactDialog): GridColDefPro[] => [
    {
        field: 'lineno',
        headerName: 'Rank',
        width: 60,
    },
    {
        field: 'linedt',
        headerName: 'Date',
        width: DATE_COLUMN_WIDTH,
    },
    {
        field: 'am',
        headerName: 'AM',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'pm',
        headerName: 'PM',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'soir',
        headerName: 'Soir',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'nuit',
        headerName: 'Nuit',
        width: BOOLEAN_COLUMN_WIDTH,
        renderCell: (params) => <BooleanIcon value={params.value} />,
    },
    {
        field: 'moderem',
        headerName: 'Moderem',
        width: SMALL_STRING_COLUMN_WIDTH,
    },
    {
        field: 'factLigneVacDetails',
        headerName: 'Détails VAC',
        minWidth: 180,
        flex: 1,
        valueGetter: (params) => params.row.FactLigneVacDetails,
        renderCell: (params) => {
            const factLigneVacDetails = params.value;

            if (factLigneVacDetails)
                return factLigneVacDetails.map((vacDetails, idx) => {
                    const { nce, revision, lineno, rank, ref, code, sd, hr, min } = vacDetails;

                    return (
                        <Tooltip
                            key={idx}
                            title={
                                <>
                                    <Typography variant="body2" fontWeight="500">
                                        Info sur VAC
                                    </Typography>
                                    <Divider sx={{ bgcolor: 'primary.main', mb: 2, mt: 0.5 }} />

                                    <Typography variant="body2">Code: #{code}</Typography>
                                    <Typography variant="body2">SD: {sd}</Typography>
                                    <Typography variant="body2">Ref: {ref}</Typography>
                                    <Typography variant="body2">HR: {hr}</Typography>
                                    <Typography variant="body2">MIN: {min}</Typography>
                                </>
                            }
                            placement="left"
                            arrow
                            sx={{ mr: 1 }}
                        >
                            <Chip size="small" label={`${hr}h${min < 10 ? `0${min}` : min}`} clickable />
                        </Tooltip>
                    );
                });
        },
    },
];
