import { gql } from '@apollo/client';
// import { FRAGMENTS } from './apollo-fragments';
import { MdBillFields } from 'apollo/fragments';

export const GET_MD_BILLS = gql`
    ${MdBillFields}

    query GetMdBills {
        mdBills {
            ...MdBillFields

            date_ec
            date_coupure
            dd_periode_fact
            df_periode_fact
            no_paiement
        }
    }
`;
