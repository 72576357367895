import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

// MUI Components
import { Box } from '@mui/material';

// Components
import Loading from 'components/Loading';
import MiniDrawer from 'components/Navbar';

// Views
import Login from 'views/auth/Login';
import ForgotPassword from 'views/auth/ForgotPassword';
import ResetPassword from 'views/auth/ResetPassword';
import NotFoundPage from 'views/general/NotFoundPage';
import FactureList from 'views/factures/FactureList';
import FactureShow from 'views/factures/FactureShow';
import EcList from 'views/ecs/EcList';
import Calendar from 'views/calendar';
import MaintenancePage from 'views/general/MaintenancePage';
import Dashboard from 'views/Dashboard';
import BillList from 'views/bills/BillList';
import ReportList from 'views/reports/ReportList';
import Profile from 'views/Profile';
import PersonalInfo from 'views/Profile/PersonalInfo';

// Providers
import { useAuth } from 'providers/AuthProvider/context';

// Services
import { Api } from 'services';

export const LOGIN_ROUTE = '/login';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const RESET_PASSWORD_ROUTE = '/reset-password';

export const MAINTENANCE_ROUTE = '/maintenance';
export const MAIN_PAGE_WHEN_AUTHENTICATED = '/tableau-de-bord';

const Router = () => {
    return (
        <Routes>
            {/* Auth */}
            <Route
                path={LOGIN_ROUTE}
                element={
                    <RedirectIfLoggedIn>
                        <Login />
                    </RedirectIfLoggedIn>
                }
            />

            <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
            <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />

            <Route
                path={MAINTENANCE_ROUTE}
                element={
                    <RedirectIfBackOnline>
                        <MaintenancePage />
                    </RedirectIfBackOnline>
                }
            />

            <Route
                path=""
                element={
                    <RequireAuth>
                        <MiniDrawer />
                    </RequireAuth>
                }
            >
                <Route path="/tableau-de-bord" element={<Dashboard />} />

                {/* Stats */}

                {/* Facturation RAMQ */}
                <Route path="/etats-de-compte" element={<EcList />} />
                <Route path="/calendrier-reclamations" element={<Calendar />} />
                <Route path="/demandes" element={<FactureList />} />
                <Route path="/rapports" element={<ReportList />} />
                <Route path="/facture-ramq" element={<FactureShow />} />

                <Route path="/factures" element={<BillList />} />

                <Route path="profil" element={<Profile />}>
                    <Route path="informations-personnelles" element={<PersonalInfo />} />
                    <Route path="preferences-paiements" element={<PersonalInfo />} />
                    <Route path="mot-de-passe" element={<PersonalInfo />} />
                    <Route path="parametres" element={<PersonalInfo />} />
                </Route>

                <Route path="" element={<Dashboard />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
    );
};

function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (auth.isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" width="100vw" height="100vh">
                <Loading />
            </Box>
        );
    } else {
        if (auth.md) {
            return children;
        } else {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
        }
    }
}

function RedirectIfLoggedIn({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    if (auth.md) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to={MAIN_PAGE_WHEN_AUTHENTICATED} state={{ from: location }} replace />;
    } else {
        return children;
    }
}

function RedirectIfBackOnline({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();

    const [apiStatusOnline, setApiStatusOnline] = useState(false);

    const getApiStatus = async () => {
        const res = await Api.get('api-status');
        setApiStatusOnline(res.data);
    };

    useEffect(() => {
        getApiStatus();
    }, []);

    if (!apiStatusOnline) {
        return children;
    } else {
        if (auth.isLoading) {
            return children;
        } else if (auth.md) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected. This allows us to send them
            // along to that page after they login, which is a nicer user experience
            // than dropping them off on the home page.
            return <Navigate to={MAIN_PAGE_WHEN_AUTHENTICATED} state={{ from: location }} replace />;
        } else {
            return <Navigate to={LOGIN_ROUTE} state={{ from: location }} replace />;
        }
    }
}

export default Router;
