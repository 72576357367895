import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit';
import moment from 'moment';
import { SQL_DATE } from 'constants/Moment';

import { IClaim, IClaimAct } from 'redux/data';

interface IActiveClaimOverrides {
    facility_id: Nullable<IClaim['facility_id']>;
    dx_id: Nullable<IClaim['dx_id']>;
}

interface IActiveCreateClaim
    extends Omit<IClaim, keyof IActiveClaimOverrides | 'id' | 'created_at' | 'doctor_id' | 'patient_id'>,
        IActiveClaimOverrides {
    doctor_id: number | null;
    patient_nam: string | null;
}

interface IActiveEditClaim extends IClaim {
    patient_nam: string | null;
}

export type IActiveClaimState = {
    activeCreateClaim: IActiveCreateClaim;
    activeEditClaim: IActiveEditClaim;
};

const initialState: IActiveClaimState = {
    activeCreateClaim: {
        date: moment(new Date()).format(SQL_DATE),
        doctor_id: null,
        patient_nam: null,
        facility_id: 1,
        assigned_to: [],
        dx_id: null,
        acts: [] as IClaimAct[],
        referring_physician_practice_number: null,
        total: 0,
        status: 'Draft',
        note: '',
    },
    activeEditClaim: {
        id: 1,
        created_at: '',
        date: moment(new Date()).format(SQL_DATE),
        doctor_id: 1,
        patient_id: 1,
        patient_nam: null,
        facility_id: 1,
        assigned_to: [],
        dx_id: 1,
        acts: [] as IClaimAct[],
        referring_physician_practice_number: null,
        total: 0,
        status: 'Draft',
        note: '',
    },
};

type AddIsEditRouteParam<T> = T & {
    isEditRoute: boolean;
};

const getStateProp = (action: PayloadAction<AddIsEditRouteParam<{}>>) => {
    return action.payload.isEditRoute ? 'activeEditClaim' : 'activeCreateClaim';
};

const claimsSlice = createSlice({
    name: 'activeClaim',
    initialState,
    reducers: {
        fillClaim(
            state,
            action: PayloadAction<
                | {
                      isEditRoute: true;
                      claim: IActiveEditClaim;
                  }
                | {
                      isEditRoute: false;
                      claim: IActiveCreateClaim;
                  }
            >,
        ) {
            if (action.payload.isEditRoute == true) {
                const { claim } = action.payload;

                state.activeEditClaim = {
                    id: claim.id!,
                    created_at: claim.created_at!,
                    date: claim.date,
                    doctor_id: claim.doctor_id!,
                    patient_id: claim.patient_id!,
                    patient_nam: claim.patient_nam,
                    facility_id: claim.facility_id!,
                    assigned_to: claim.assigned_to,
                    dx_id: claim.dx_id!,
                    acts: claim.acts,
                    referring_physician_practice_number: claim.referring_physician_practice_number,
                    total: claim.total,
                    status: claim.status,
                    note: claim.note,
                };
            } else if (action.payload.isEditRoute == false) {
                const { claim } = action.payload;

                state.activeCreateClaim = {
                    date: claim.date,
                    doctor_id: claim.doctor_id,
                    patient_nam: claim.patient_nam,
                    facility_id: claim.facility_id,
                    assigned_to: claim.assigned_to,
                    dx_id: claim.dx_id,
                    acts: claim.acts,
                    referring_physician_practice_number: claim.referring_physician_practice_number,
                    total: claim.total,
                    status: claim.status,
                    note: claim.note,
                };
            }
        },
        emptyClaim(state, action: PayloadAction<AddIsEditRouteParam<{}>>) {
            if (action.payload.isEditRoute == true) {
            } else if (action.payload.isEditRoute == false) {
                state.activeCreateClaim = initialState.activeCreateClaim;
            }
        },
        setDate(state, action: PayloadAction<AddIsEditRouteParam<{ date: IActiveCreateClaim['date'] }>>) {
            const stateProp = getStateProp(action);
            state[stateProp].date = action.payload.date;
        },
        setPatientNam(
            state,
            action: PayloadAction<
                AddIsEditRouteParam<{
                    patient_nam: IActiveCreateClaim['patient_nam'];
                }>
            >,
        ) {
            const stateProp = getStateProp(action);
            state[stateProp].patient_nam = action.payload.patient_nam;
        },
        setFacilityId(
            state,
            action: PayloadAction<
                AddIsEditRouteParam<{
                    facility_id: IActiveCreateClaim['facility_id'];
                }>
            >,
        ) {
            const stateProp = getStateProp(action);
            state[stateProp].facility_id = action.payload.facility_id;
        },
        setDxId(state, action: PayloadAction<AddIsEditRouteParam<{ dx_id: IActiveCreateClaim['dx_id'] }>>) {
            const stateProp = getStateProp(action);
            state[stateProp].dx_id = action.payload.dx_id;
        },
        addAct(state, action: PayloadAction<AddIsEditRouteParam<{ act: Omit<IActiveCreateClaim['acts'][0], 'id'> }>>) {
            const stateProp = getStateProp(action);
            state[stateProp].acts.push({ ...action.payload.act, id: state[stateProp].acts.length });
        },
        editAct(
            state,
            action: PayloadAction<
                AddIsEditRouteParam<{
                    id: IActiveCreateClaim['acts'][0]['id'];
                    updatedData: IActiveCreateClaim['acts'][0];
                }>
            >,
        ) {
            const stateProp = getStateProp(action);
            let existingActIdx = state[stateProp].acts.findIndex((act) => {
                return act.id === action.payload.id;
            });
            if (existingActIdx !== undefined) {
                state[stateProp].acts[existingActIdx] = action.payload.updatedData;
            }
        },
        deleteAct(
            state,
            action: PayloadAction<
                AddIsEditRouteParam<{
                    actId: IActiveCreateClaim['acts'][0]['id'];
                }>
            >,
        ) {
            const stateProp = getStateProp(action);
            const updatedActs = state[stateProp].acts.filter((act) => act.id !== action.payload.actId);
            state[stateProp].acts = updatedActs;
        },
        setReferringPhysicianPracticeNumber(
            state,
            action: PayloadAction<
                AddIsEditRouteParam<{
                    referring_physician_practice_number:
                        | IActiveCreateClaim['referring_physician_practice_number']
                        | null;
                }>
            >,
        ) {
            const stateProp = getStateProp(action);
            state[stateProp].referring_physician_practice_number = action.payload.referring_physician_practice_number;
        },
        setNote(state, action: PayloadAction<AddIsEditRouteParam<{ note: IActiveCreateClaim['note'] }>>) {
            const stateProp = getStateProp(action);
            state[stateProp].note = action.payload.note;
        },
    },
});

export const {
    fillClaim,
    emptyClaim,
    setDate,
    setPatientNam,
    setFacilityId,
    setDxId,
    addAct,
    editAct,
    deleteAct,
    setReferringPhysicianPracticeNumber,
    setNote,
} = claimsSlice.actions;

export default claimsSlice.reducer;
