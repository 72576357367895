import moment from 'moment';
import { NewsItem1 } from './news-item-1';
import { NewsItem2 } from './news-item-2';
import { NewsItem3 } from './news-item-3';

export const babillardItems = [
    {
        id: 1,
        sent_on: moment('2022-06-13 21:28:00'),
        admin: {
            first_name: 'Équipe',
            last_name: 'Facturation Médicale Paradis',
        },
        title: 'Infolettre 036, 038 et 059 - publications importantes',
        subtitle:
            "La Régie de l'assurance maladie du Québec (RAMQ) a publié 3 infolettres nécessitant votre attention depuis le 13 mai dernier.",
        content: NewsItem1,
    },
    {
        id: 2,
        sent_on: moment('2022-06-10 12:35:00'),
        admin: {
            first_name: 'Marc',
            last_name: 'Leblond',
        },
        title: 'Nouvelle Directrice et nouveau portail en ligne',
        subtitle:
            'Nous sommes heureux de vous annoncer officiellement la nomination de madame Claudie Vachon au poste de Directrice de Facturation médicale Paradis inc. Claudie s’est jointe à notre équipe en mars dernier et certains d’entre vous ont déjà pu échanger avec elle au cours des dernières semaines.',
        content: NewsItem2,
    },
    {
        id: 3,
        sent_on: moment('2022-06-14 10:25:00'),
        admin: {
            first_name: 'Claudie',
            last_name: 'Vachon',
        },
        title: 'Nouveau portail',
        subtitle:
            'L’équipe de facturation médicale Paradis annonce qu’à compter de ce soir le 14 juin, le portail-médecin en ligne actuel ne sera plus disponible.   Tel que mentionné dans notre courriel du 10 juin 2022, un nouveau portail sera mis à votre disposition.',
        content: NewsItem3,
    },
    // {
    //     id: 4,
    //     sent_on: moment(),
    //     admin: {
    //         first_name: 'Virginie',
    //         last_name: 'Champion',
    //     },
    //     title: 'Lorem ipsum',
    //     subtitle:
    //         'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, cupiditate animi ab, eum, eveniet accusamus',
    //     content: NewsItem1,
    // },
    // {
    //     id: 5,
    //     sent_on: moment(),
    //     admin: {
    //         first_name: 'Sandra',
    //         last_name: 'Gagnon',
    //     },
    //     title: 'Lorem ipsum',
    //     subtitle:
    //         'Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, cupiditate animi ab, eum, eveniet accusamus',
    //     content: NewsItem1,
    // },
];
