import { gql } from '@apollo/client';
import { PstReportFields } from 'apollo/fragments';

export const GET_PST_REPORT = gql`
    ${PstReportFields}

    query GetPstReport($ec_md_grp_id: Int!, $pst_no: String!, $pst_mod: Int) {
        pstReport(ec_md_grp_id: $ec_md_grp_id, pst_no: $pst_no, pst_mod: $pst_mod) {
            ...PstReportFields

            FILE_URL
        }
    }
`;
