import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Container,
    useTheme,
    lighten,
    Alert,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useForm, Controller, useController } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// Services
import { Auth } from 'services';

// Providers
import { useAuth } from 'providers/AuthProvider/context';

// Router
import { LOGIN_ROUTE, MAIN_PAGE_WHEN_AUTHENTICATED } from 'router';

// Components
import GuestLayout from 'components/GuestLayout';

const RESET_PASSWORD_MD = gql`
    mutation ResetPasswordMd($token_id: Int, $token: String!, $password: String!) {
        resetPasswordMd(token_id: $token_id, token: $token, password: $password)
    }
`;

export default function ResetPassword() {
    const navigate = useNavigate();
    const auth = useAuth();
    const theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();

    const [passwordChanged, setPasswordChanged] = useState(false);

    const showLaunchInfo = searchParams.get('launch');

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            password: '',
        },
    });

    const [
        resetPasswordMd,
        { loading: resetPasswordMdLoading, error: resetPasswordMdError, data: resetPasswordMdData },
    ] = useMutation(RESET_PASSWORD_MD, {
        onCompleted: ({ resetPasswordMd }) => {
            setPasswordChanged(true);
        },
    });

    if (resetPasswordMdError) {
        console.log(resetPasswordMdError);
        console.log(resetPasswordMdError.graphQLErrors);
        if (resetPasswordMdError.graphQLErrors.length > 0) {
            console.log(resetPasswordMdError.graphQLErrors[0].extensions.code);
        }
    }

    const onSubmit = async (data) => {
        resetPasswordMd({
            variables: {
                token_id: parseInt(searchParams.get('token_id')!),
                token: searchParams.get('token'),
                password: data.password,
            },
        });
    };

    return (
        <GuestLayout>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Box display="flex" justifyContent="center">
                            <img
                                src="/logo-blanc.jpg"
                                alt=""
                                style={{
                                    display: 'block',
                                    height: '70px',
                                }}
                            />
                        </Box>
                    </Grid>

                    {!passwordChanged ? (
                        <>
                            <Grid item xs={12} mb={4}>
                                <Typography variant="h6" textAlign="center">
                                    Changer mon mot de passe
                                </Typography>

                                <Typography variant="body1" textAlign="center" mt={1}>
                                    Veuillez entrer votre nouveau mot de passe ci-dessous.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Controller
                                    name="password"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Nouveau mot de passe"
                                            size="small"
                                            fullWidth
                                            {...field}
                                            type="password"
                                        />
                                    )}
                                />
                            </Grid>

                            {resetPasswordMdError && (
                                <Grid item xs={12} mt={2}>
                                    <Typography color="red" textAlign="center">
                                        {resetPasswordMdError.message}
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item xs={12} mt={2}>
                                <Button type="submit" variant="contained" fullWidth disabled={resetPasswordMdLoading}>
                                    Modifier mon mot de passe
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Alert severity="success">
                                Votre mot de passe a été modifié avec succès! Vous pouvez désormais vous connecter à
                                votre compte avec ce mot de passe.
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12} mt={0} container justifyContent="center">
                        <Button variant="text" component={RouterLink} to={LOGIN_ROUTE}>
                            Retourner à la page de connexion
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </GuestLayout>
    );
}
