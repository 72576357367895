import { useState } from 'react';
import FileSaver from 'file-saver';
import { useForm } from 'react-hook-form';

// MUI Components
import { Grid, Typography, Divider, Chip, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

// Components
import { SelectControl } from 'components/inputs';

// Apollo
import { Api } from 'services';

// Redux

// Utils

// Constants

export default function RapportDiabeteTrimestrielForm() {
    // Form
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {},
    });

    // Report generation
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const onSubmit = async (data) => {
        setRequestPending(true);

        try {
            const res = await Api.get('storage/generate-diabete-trimestriel-report', {
                responseType: 'blob',
                timeout: 1000 * 20, // 20 seconds
                params: data,
            });

            const filename = `diabete-trimestriel.pdf`;
            FileSaver.saveAs(res.data, filename);
        } catch (e) {
        } finally {
            setRequestPending(false);
        }
    };

    const inputGridXl = 2;
    const inputGridLg = 4;
    const inputGridMd = 4;
    const inputGridSm = 6;
    const inputGridXs = 12;

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                        Rapport de diabète (trimestriel)
                    </Typography>
                </Grid>
                <Grid item xs container spacing={1} flexWrap="nowrap">
                    <Grid item>
                        <Chip
                            label="Nouveau"
                            size="small"
                            variant="filled"
                            sx={(theme) => ({ backgroundColor: theme.palette.blue1.main })}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 1.25, mb: 2 }} />

            <Grid container spacing={3} pt={1}>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'flex-end',
                            md: 'flex-start',
                        },
                        flex: {
                            xs: 1,
                        },
                    }}
                >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        // size="small"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<FileDownloadIcon />}
                        loading={requestPending}
                        loadingPosition="end"
                    >
                        Générer le rapport
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
}
