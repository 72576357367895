import React from 'react';
import { Chip, Stack, Tooltip } from '@mui/material';

interface RfpErrorStackProps {
    data: any;
}

export default function RfpErrorStack(props: RfpErrorStackProps) {
    let { data } = props;

    return (
        <Stack direction="row" spacing={1}>
            {['MessageExpl', 'MessageExpl1', 'MessageExpl2', 'MessageExpl3', 'MessageExpl4', 'MessageExpl5']
                .filter((value) => data[value])
                .map((value, idx) => {
                    const { categorie, codemsgexpl, debut, fin, entente, texte, typefact } = data[value];

                    return (
                        <Tooltip key={idx} title={texte} disableInteractive>
                            <Chip label={codemsgexpl} size="small" color="error" variant="outlined" clickable />
                        </Tooltip>
                    );
                })}
        </Stack>
    );
}
