import { gql } from '@apollo/client';

export const FactLigneFields = gql`
    fragment FactLigneFields on FactLigne {
        ligneno
        nce
        revision
        rank
        mdgrppstno
        typelmfact
        codefact
        datefact
        coderole
        debutelmfact
        finelmfact
        coderef
        mntprelim
        mntfinal
        statrecv
    }
`;

export const CodeFactFields = gql`
    fragment CodeFactFields on CodeFact {
        codefactno
        entente
        code
        description
        typecode
        debut
        fin
        descriptionsa
    }
`;

export const FactLigneFdFields = gql`
    fragment FactLigneFdFields on FactLigneFd {
        ligneno
        typefd
        codetransport
        nbrkmaller
        nbrkmretour
        nbrhrsdeplacement
        nbrhrsattente

        TYPE_FD
        METHODE_TRANSPORT
    }
`;

export const FactLigneFdFraisFields = gql`
    fragment FactLigneFdFraisFields on FactLigneFdFrais {
        ligneno
        rank
        typefrais
        frais
    }
`;

export const FactLigneVacFields = gql`
    fragment FactLigneVacFields on FactLigneVac {
        nce
        revision
        lineno
        linedt
        moderem
        nuit
        am
        pm
        soir
    }
`;

export const FactLigneVacDetailsFields = gql`
    fragment FactLigneVacDetailsFields on FactLigneVacDetails {
        nce
        revision
        lineno
        rank
        ref
        code
        sd
        hr
        min
    }
`;

export const FactLigneRmxFields = gql`
    fragment FactLigneRmxFields on FactLigneRmx {
        nce
        revision
        lineno
        linedt
        am
        pm
        ref
        demiperdiem
    }
`;

export const FactLigneRmxDetailsFields = gql`
    fragment FactLigneRmxDetailsFields on FactLigneRmxDetails {
        nce
        revision
        lineno
        rank
        ref
        code
        sd
        hr
        min
    }
`;

export const ElementContxFields = gql`
    fragment ElementContxFields on ElementContx {
        elementno
        entente
        codeelement
        texte
        textesa
        niveau
        debut
        fin
    }
`;

export const FactLigneElmContxFields = gql`
    fragment FactLigneElmContxFields on FactLigneElmContx {
        ligneno
        codeelmcontx
    }
`;

export const FactLigneElmMesureFields = gql`
    fragment FactLigneElmMesureFields on FactLigneElmMesure {
        ligneno
        codeelmmesure
        valmesure
    }
`;

export const ElementMesureFields = gql`
    fragment ElementMesureFields on ElementMesure {
        code
        nom
        typeunite
    }
`;

export const MdFields = gql`
    fragment MdFields on Md {
        id
        created_at
        updated_at
        licence_sm
        email
        password
        first_name
        last_name
        first_name_smchar
        last_name_smchar
        sex
        retraite_on
        avatar_url
        is_still_client
        can_login
        address1
        address2
        city
        zip
        phone_mobile
        phone_home
        phone_work
        joined_on
        left_on
        balance
        qb_customer_id
        qb_customer_fully_qualified_name

        is_billable
        is_billable_until

        should_receive_reports
        should_receive_reports_until

        wants_invoice_emails
        wants_ec_emails
        wants_newsletter_emails
    }
`;

export const MdBillFields = gql`
    fragment MdBillFields on MdBill {
        id
        created_at
        updated_at
        ec_id
        subtotal_amt
        tps_rate
        tvq_rate
        tps_amt
        tvq_amt
        total_amt
        md_id
        filename
        is_sipt
        qb_invoice_id
        bill_no
        status # 'draft' | 'approved' | 'billed' | 'canceled' | 'credited'
        email_sent
        last_email_sent_at
        billing_method
    }
`;

export const MdGrpFields = gql`
    fragment MdGrpFields on MdGrp {
        id
        created_at
        updated_at
        md_id
        grp
        sm
        rmx
        rpv
        rps
        is_active
    }
`;

export const MdTransactionFields = gql`
    fragment MdTransactionFields on MdTransaction {
        id
        created_at
        updated_at
        md_id
        type
        ref_id
        txn_recorded_at
        name
        amt
        new_balance
    }
`;

export const MdHistoricalTransactionFields = gql`
    fragment MdHistoricalTransactionFields on MdHistoricalTransaction {
        txn_recorded_at
        type # 'payment | 'bill
        ref_id
        ref_no
        amt
        old_balance
        new_balance
    }
`;

export const EcFields = gql`
    fragment EcFields on Ec {
        id
        created_at
        updated_at
        date_ec
        date_coupure
        dd_periode_fact
        df_periode_fact
        no_paiement
        fmp_id
    }
`;

export const MdBillLineFields = gql`
    fragment MdBillLineFields on MdBillLine {
        id
        md_bill_id
        bill_line_tag_id
        position
        name
        desc
        pst
        grp
        unit_amt
        qty
        total_amt
        json_details
        md_billing_auto_line_id
    }
`;

export const MdBillModLineFields = gql`
    fragment MdBillModLineFields on MdBillModLine {
        id
        md_bill_id
        bill_mod_line_tag_id
        name
        desc
        value_type
        value
        total_amt
        md_billing_auto_mod_line_id
    }
`;

export const BillLineTagFields = gql`
    fragment BillLineTagFields on BillLineTag {
        id
        created_at
        updated_at
        slug
        name
        desc
    }
`;

export const BillModLineTagFields = gql`
    fragment BillModLineTagFields on BillModLineTag {
        id
        created_at
        updated_at
        slug
        name
        desc
    }
`;

export const BillingSheetFields = gql`
    fragment BillingSheetFields on BillingSheet {
        id
        created_at
        updated_at
        name
        desc
        first_bracket_fee
        second_bracket_fee
        y_increment
    }
`;

export const PosteFields = gql`
    fragment PosteFields on Poste {
        numero
        modulateur
        description
        descriptionsa
        cab
        clsc
        cli
        urg
        usi
        adm
        ger
        psy
        pal
        rea
        heb
        udc
        umf
        actif
    }
`;

export const MdWorkStoppageFields = gql`
    fragment MdWorkStoppageFields on MdWorkStoppage {
        id
        created_at
        updated_at
        md_id
        work_stoppage_cause_id
        note
        start_on
        end_on
        indeterminate
        cancel_automatic_billing
    }
`;

export const MdBillingInfoFields = gql`
    fragment MdBillingInfoFields on MdBillingInfo {
        id
        created_at
        updated_at
        md_id
        billing_method
        billing_sheet_id
        fact_fee
        fact_line_fee
        fact_fee_urg
        fact_line_fee_urg
        fact_fee_hospit
        fact_line_fee_hospit
        fact_fee_dme
        fact_line_fee_dme
        rmx_sheet_fee
        rpv_sheet_fee
        rps_sheet_fee
        hors_ramq_req_fee
        manual_fd_fee
        is_approved
    }
`;

export const BankAccountFields = gql`
    fragment BankAccountFields on BankAccount {
        id
        created_at
        updated_at
        name
        qb_account_id
    }
`;

export const Ec1717igFields = gql`
    fragment Ec1717igFields on Ec1717ig {
        mdgrpid
        ec_id
        typereglement
        compteur
        valideur
        masterid
        nodemande
        debutperiode
        finperiode
        datereception
        nodemref1
        nodemref2
        codetrans
        libellecodetrans
        codemsgexpl1
        codemsgexpl2
        codemsgexpl3
        montantinteret
        noattestation
    }
`;

export const EcReportFields = gql`
    fragment EcReportFields on EcReport {
        id
        filename
        licence
        grp
        ec_id
    }
`;

export const DfReportFields = gql`
    fragment DfReportFields on DfReport {
        id
        filename
        licence
        grp
        ec_id
    }
`;

export const PstReportFields = gql`
    fragment PstReportFields on PstReport {
        id
        created_at
        updated_at
        filename
        ec_md_grp_id
        pst_no
        pst_mod
        licence
        grp
        ec_id
    }
`;

export const FactVacViewFields = gql`
    fragment FactVacViewFields on FactVacView {
        created_at
        nce
        revision
        reqt_id
        licence
        pst_no
        pst_mod
        pst_name
        statut_recv
        is_canceled
        lot_id
        fmp_ec_id
        ec_id

        debut_periode
        fin_periode
        hrs_activite
        mins_activite
        cs1
        cs2
        nom_mand
        date_mand
        nom_sign
        date_sign
        once
        attestation

        pst_depart
        pst_arrivee
        dh_arrivee
        hrs_depl
        mins_depl
        kms_depl
        montant_transport_reclame

        date_service_min
        date_service_max
        date_service_agg

        payment_status

        ec_1717ig_agg

        hrs_activite_payes
        total_remu_activite
        hrs_depl_payes
        total_remu_temps_depl
        kms_transport_payes
        total_remu_cout_transport
        total_remu

        line_count

        PAYMENT_STATUS_NAME
    }
`;
