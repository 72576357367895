import React, { useCallback, useMemo, useState, useEffect, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    AppBar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Slide,
    Tab,
    Toolbar,
    Typography,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { TransitionProps } from '@mui/material/transitions';

// MUI Grid

// MUI Icons
import { Close as CloseIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import InteractiveEcTabPanel from './InteractiveEcTabPanel';
import EcReportTabPanel from './EcReportTabPanel';
import DfReportTabPanel from './DfReportTabPanel';
import PstReportTabPanel from './PstReportTabPanel';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_EC_OVERVIEW, GET_PST_REPORTS } from './apollo-queries';

// Redux

// Utils

// Constants
interface EcDialogProps {
    ecMdGrpId: number | null;
    handleCloseEcDialog: () => void;
}

interface EcDialogContentProps {
    ecOverview: any;
    pstReports: any[];
    handleCloseEcDialog: () => void;
}

export default function EcDialog({ ecMdGrpId, handleCloseEcDialog }: EcDialogProps) {
    // Apollo queries
    const {
        loading: ecOverviewLoading,
        error: ecOverviewError,
        data: ecOverviewData,
    } = useQuery(GET_EC_OVERVIEW, {
        variables: { id: ecMdGrpId },
        skip: !ecMdGrpId,
    });
    const ecOverview = ecOverviewData?.ecOverview;

    const {
        loading: pstReportsLoading,
        error: pstReportsError,
        data: pstReportsData,
    } = useQuery(GET_PST_REPORTS, {
        variables: { ec_md_grp_id: ecMdGrpId },
        skip: !ecMdGrpId,
    });
    const pstReports = pstReportsData?.pstReports;

    const isDialogOpen = ecMdGrpId ? true : false;
    const isDialogLoading = ecOverviewLoading || pstReportsLoading;

    return (
        <Dialog fullScreen={true} open={isDialogOpen} onClose={handleCloseEcDialog} TransitionComponent={Transition}>
            {isDialogLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '300px' }}>
                    <Loading />
                </Box>
            ) : (
                <EcDialogContent
                    ecOverview={ecOverview}
                    pstReports={pstReports}
                    handleCloseEcDialog={handleCloseEcDialog}
                />
            )}
        </Dialog>
    );
}

const EcDialogContent = ({ ecOverview, pstReports, handleCloseEcDialog }: EcDialogContentProps) => {
    const [tabIndex, setTabIndex] = React.useState('ec-interactif');

    const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue.toString());
    };

    if (!ecOverview) return <></>;

    const {
        id: ecMdGrpId,
        licence,
        grp,
        ec_id,
        fmp_id,
        date_ec,
        date_coupure,
        dd_periode_fact,
        df_periode_fact,
        no_paiement,
        grp_nom,
        is_grp_inc,
    } = ecOverview;

    return (
        <TabContext value={tabIndex}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseEcDialog}>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        État de compte du {moment(date_ec).format('LL')} (OR-{no_paiement}) - Groupe {grp}
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleCloseEcDialog}>
                        Fermer
                    </Button>
                </Toolbar>
            </AppBar>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 0 }}>
                <TabList
                    onChange={onTabChange}
                    variant="scrollable"
                    // scrollButtons
                    allowScrollButtonsMobile
                >
                    <Tab label="État de compte interactif" value="ec-interactif" />
                    <Tab label="Rapport EC" value="rapport-ec" />
                    <Tab label="Rapport DF" value="rapport-df" />
                    {pstReports.map((pstReport, idx) => (
                        <Tab
                            key={idx}
                            label={`Rapport sommaire : poste ${pstReport.pst_no}${
                                pstReport.pst_mod ? `-${pstReport.pst_mod}` : ''
                            }`}
                            value={`rapport-sommaire-${pstReport.pst_no}-${pstReport.pst_mod}`}
                        />
                    ))}
                </TabList>
            </Box>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} pt={1} px={4}>
                        <InteractiveEcTabPanel tabIndexAsString="ec-interactif" ecMdGrpId={ecMdGrpId} />
                        <EcReportTabPanel tabIndexAsString="rapport-ec" ecMdGrpId={ecMdGrpId} />
                        <DfReportTabPanel tabIndexAsString="rapport-df" ecMdGrpId={ecMdGrpId} />
                        {pstReports.map((pstReport, idx) => (
                            <PstReportTabPanel
                                key={idx}
                                tabIndexAsString={`rapport-sommaire-${pstReport.pst_no}-${pstReport.pst_mod}`}
                                ecMdGrpId={ecMdGrpId}
                                pstNo={pstReport.pst_no}
                                pstMod={pstReport.pst_mod}
                            />
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleCloseEcDialog}>Fermer</Button>
            </DialogActions> */}
        </TabContext>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
