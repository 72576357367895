import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { Button, ButtonProps, IconButton, Typography, Tooltip } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components

// Constants
import {
    DATE_COL_DEFAULTS,
    NCE_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
    PAYMENT_STATUS_COL_DEFAULTS,
    LINE_COUNT_COL_DEFAULTS,
    LOCALITE_COL_DEFAULT,
    DATETIME_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber } from 'utils';

export const getFactureListColumns = (isMobile): GridColDefPro[] => [
    // Main info
    {
        ...NCE_COL_DEFAULTS,
        field: 'nce',
        headerName: 'NCE',
        valueGetter: ({ value, row }) => `${value}__${row.revision}`,
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'date_service_min',
        headerName: 'Date service',
        valueGetter: ({ value }) => (value ? moment(value).format(SQL_DATE) : null),
    },

    // Travel info
    {
        ...LOCALITE_COL_DEFAULT,
        field: 'pst_depart_id',
        headerName: 'Poste départ',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'dh_depart',
        headerName: 'Départ à',
    },
    {
        ...LOCALITE_COL_DEFAULT,
        field: 'pst_arrivee_id',
        headerName: 'Poste arrivée',
    },
    {
        ...DATETIME_COL_DEFAULTS,
        field: 'dh_arrivee',
        headerName: 'Arrivée à',
    },

    // Money info
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_prelim',
        headerName: 'Montant prélim.',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_final',
        headerName: 'Montant final',
        valueFormatter: ({ value }) => `${formatNumber(value as number)} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_diff',
        headerName: 'Différence',
        renderCell: ({ value, row }) => {
            let textColor = '';
            if (value > 0) textColor = 'success.main';
            else if (value === 0) textColor = 'warning.main';
            else if (value < 0) textColor = 'error.main';
            else textColor = 'success.main';

            let text = value > 0 ? '+ ' : value < 0 ? '- ' : '';
            text += formatNumber(Math.abs(value));

            return (
                <Typography variant="body2" sx={{ color: textColor }}>
                    {text}
                </Typography>
            );
        },
    },

    {
        ...PAYMENT_STATUS_COL_DEFAULTS,
        field: 'PAYMENT_STATUS_NAME',
        headerName: 'Statut paiement',
        renderCell: ({ value, row }) => {
            let buttonColor: ButtonProps['color'] = 'success';
            switch (row.payment_status) {
                case 'canceled':
                    buttonColor = 'error';
                    break;
                case 'payable':
                    buttonColor = 'warning';
                    break;
                case 'paid':
                    buttonColor = 'success';
                    break;
            }

            let buttonText = value;

            return (
                <Button variant="text" color={buttonColor}>
                    {buttonText}
                </Button>
            );
        },
    },
    {
        ...LINE_COUNT_COL_DEFAULTS,
        field: 'line_count',
        headerName: "Nb. d'actes",
    },

    {
        field: 'actions',
        headerName: 'Actions',
        width: isMobile ? 60 : 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { nce, revision } = row;

            return (
                <>
                    <Tooltip
                        title="La page du détaillé complet d'une réclamation sera disponible au courant des prochaines semaines!"
                        disableInteractive
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            // component={RouterLink}
                            // to={`/factures/${nce}/${revision}`}
                            // target="_blank"
                            onClick={() => null}
                        >
                            <OpenInNewIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];
