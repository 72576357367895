import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment, { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';

// MUI Components
import {} from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useMediaQuery } from '@mui/material';

// MUI Grid
import { DataGridPro, GridRowId } from '@mui/x-data-grid-pro';
import { getFactureListColumns } from './grid-columns';

// MUI Icons
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DataGridLoader from 'components/DataGridLoader';
import DataGridNoRowsOverlay from 'components/DataGridNoRowsOverlay';
import DataGridNoResultsOverlay from 'components/DataGridNoResultsOverlay';
import FactVacGridDetailPanel, {
    DETAIL_PANEL_TH_HEIGHT,
    DETAIL_PANEL_TR_HEIGHT,
    DETAIL_PANEL_PY_HEIGHT,
    DETAIL_PANEL_PB_BUFFER_HEIGHT,
} from 'components/FactVacGridDetailPanel';

// Apollo
import { useQuery, gql, useLazyQuery } from '@apollo/client';
import { GET_FACT_VACS } from './apollo-queries';

// Redux
import {} from 'redux';

// Utils
import { capitalize } from 'utils';

// Constants
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';

interface FactVacTabPanelProps {
    tabIndexAsString: string;
    filters: FactVacFilters;
}

interface FactVacFilters {
    created_at: Moment | null;
    date_service: Moment | null;
    ec_id: number | null;
    pst_id: string | null;
    payment_status: string | null;
}

export default function FactVacTabPanel({ tabIndexAsString, filters }: FactVacTabPanelProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
    const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

    // Apollo queries
    const {
        loading: facturesLoading,
        error: facturesError,
        data: facturesData,
    } = useQuery(GET_FACT_VACS, {
        variables: filters,
        // skip: !ecIdFilter || !medecinLicenceFilter,
    });
    const factures = facturesData?.factVacs || [];

    // DataGrid
    const [pageSize, setPageSize] = React.useState<number>(15);
    const factureListColumns = useMemo(() => getFactureListColumns(isMobile), [isMobile]);

    // Detail panel
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
        setDetailPanelExpandedRowIds(newIds);
    }, []);

    return (
        <TabPanel value={tabIndexAsString}>
            <DataGridPro
                getRowId={(row) => uuidv4()}
                rows={factures}
                columns={factureListColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                pinnedColumns={{ right: ['actions'], ...(isLaptop ? { left: ['nce'] } : {}) }}
                initialState={{
                    sorting: {
                        // sortModel: [{ field: 'debut_periode', sort: 'desc' }],
                    },
                    columns: {
                        columnVisibilityModel: {
                            cs1: false,
                            cs2: false,
                            once: false,
                        },
                    },
                }}
                loading={facturesLoading}
                rowThreshold={0}
                getDetailPanelContent={(params) => (
                    <FactVacGridDetailPanel
                        params={params}
                        containerSx={{
                            // maxWidth: isLaptop ? '100vw' : '100%',
                            maxWidth: isLaptop ? '1400px' : '100%',
                            pl: isLaptop ? 15 : 2,
                            pr: isLaptop ? 15 : 2,
                            bgcolor: 'rgb(240,240,240)',
                        }}
                    />
                )}
                getDetailPanelHeight={React.useCallback(
                    ({ row }) =>
                        DETAIL_PANEL_TH_HEIGHT +
                        row.line_count * DETAIL_PANEL_TR_HEIGHT +
                        DETAIL_PANEL_PY_HEIGHT +
                        DETAIL_PANEL_PB_BUFFER_HEIGHT,
                    [],
                )}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
                components={{
                    LoadingOverlay: DataGridLoader,
                    NoRowsOverlay: DataGridNoRowsOverlay,
                    NoResultsOverlay: DataGridNoResultsOverlay,
                    DetailPanelExpandIcon: React.useCallback(() => <ExpandMoreIcon color="info" />, []),
                    DetailPanelCollapseIcon: React.useCallback(() => <ExpandLessIcon color="info" />, []),
                }}
            />
        </TabPanel>
    );
}
