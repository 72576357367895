import { Box, Typography } from '@mui/material';

export const NewsItem3 = () => {
    return (
        <Box>
            <Paragraph>
                L’équipe de facturation médicale Paradis annonce qu’à compter de ce soir le 14 juin, le portail-médecin
                en ligne actuel ne sera plus disponible. Tel que mentionné dans notre courriel du 10 juin 2022, un
                nouveau portail sera mis à votre disposition.
            </Paragraph>

            <Paragraph>
                Afin de nous permettre de compléter la migration de vos données vers la nouvelle plateforme et y
                déployer les premiers éléments de Votre portail, ce nouvel outil sera opérationnel lundi le 20 juin
                2022. Par conséquent, vous ne pourrez avoir accès aux informations quotidiennes de votre facturation
                d’ici le 20 juin 2022.
            </Paragraph>

            <Paragraph>
                Nous tenons à réitérer notre souhait de vous fournir l’information à travers un seul et unique guichet
                sécurisé plutôt que de vous acheminer des courriels.
            </Paragraph>

            <Paragraph>
                Veuillez s’il vous plaît porter attention à notre prochain envoi par courriel. Ce dernier vous
                expliquera simplement et clairement la façon de vous identifier et vous donner accès à Votre portail.
            </Paragraph>

            <Paragraph>Nous sommes très heureux et fiers de vous offrir notre nouvelle plateforme !</Paragraph>

            <Box mt={4}></Box>

            <Typography mt={2}>
                <i>Claudie Vachon, directrice</i>
            </Typography>
        </Box>
    );
};

function Subtitle({ children }) {
    return (
        <Typography variant="h6" mt={3} mb={1}>
            {children}
        </Typography>
    );
}

function Paragraph({ children }) {
    return <Typography mb={2}>{children}</Typography>;
}
