import { Box, Typography, IconButton, Divider, Grid, Tooltip, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useMemo, ReactNode } from 'react';
import { formatNumber } from 'utils';
import {
    getEc1720Columns,
    getEc1721Columns,
    getEc1722Columns,
    getEc1725Columns,
} from 'views/ecs/EcList/EcDialog/grid-columns';

import { Help as HelpIcon } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import ColoredNumber from 'components/ColoredNumber';

const SHOW_SECTION_IF_EMPTY = false;
const SHOW_MONTANTS_BRUT_TOOLTIP = false;
const SHOW_DEMANDE_TYPE_TOOLTIP = false;

export const InteractiveEc = ({ ecMdGrp }) => {
    const columns1720 = useMemo(() => getEc1720Columns(), []);
    const columns1721 = useMemo(() => getEc1721Columns(), []);
    const columns1722 = useMemo(() => getEc1722Columns(), []);
    const columns1725 = useMemo(() => getEc1725Columns(), []);

    const {
        id: ecMdGrpId,
        licence,
        grp,
        ec_id,
        fmp_id,

        Md,
        Groupe,
        Ec,

        Ec1703,
        Ec1704,
        Ec1707,
        Ec1708,
        Ec1709,
        Ec1710,
        Ec1711,
        Ec1712,
        Ec1716,
        Ec1720,
        Ec1721,
        Ec1722,
        Ec1725,

        Ec1717ig,
    } = ecMdGrp;

    const { first_name, last_name, sex } = Md;
    const {
        numero,
        // licence,
        denomination,
        incorp,
        debut,
        fin,
    } = Groupe || {}; // Groupe can be null which means it is the 0 groupe
    const {
        // ec_id,
        //  agence,
        no_paiement,
        date_ec,
        date_coupure,
    } = Ec || {};

    const {
        //  mdgrpid,
        //  compteur,
        //   valideur,
        msg,
        msg_permanent,
    } = Ec1703 || {};
    const {
        // mdgrpid,
        // compteur,
        // valideur,
        nomfed,
        montantcotisation,
    } = Ec1708 || {};
    const {
        // mdgrpid,
        // compteur,
        // valideur,
        interetsurrecouv,
        interetsuravance,
        avancesemises,
        avancesrecouv,
        interetsursoldeneg,
    } = Ec1710 || {};
    const {
        // mdgrpid,
        // compteur,
        // valideur,
        soldereporte,
        soldeareporte,
        modifdesolde,
        paiementrecu,
        debutinteretsursoldeneg,
        codemsg,
        montantmsg1,
    } = Ec1711 || {};
    const {
        // mdgrpid,
        // compteur,
        // valideur,
        montantnetverse,
        nochequeouvirement,
        modepaiement,
    } = Ec1712 || {};

    let modePaiementText = '';
    if (modepaiement == 'C') modePaiementText = 'chèque';
    if (modepaiement == 'V') modePaiementText = 'virement';

    return (
        // <Paper sx={{ p: 3 }} elevation={10}>
        <Grid container pt={2}>
            {/* <Grid item xs={12}>
                        <Typography variant="h6">Groupe #{grp}</Typography>
                        <Divider sx={{ mb: 2, mt: 1 }} />
                    </Grid> */}

            <Grid item xs={6}>
                <Typography variant="h5" fontWeight="500">
                    {sex === 1 ? 'Dr' : sex === 2 ? 'Dre' : ''} {last_name}, {first_name}
                </Typography>

                <Typography variant="subtitle1">Licence : {licence}</Typography>
            </Grid>

            <Grid item xs={6} container flexDirection="column" alignItems="flex-end">
                <Typography variant="h6">
                    Date EC : {moment(date_ec).format('LL')} (OR-{no_paiement})
                </Typography>

                <Typography variant="subtitle1">Date coupure : {moment(date_coupure).format('LL')}</Typography>
            </Grid>

            <Grid item xs={12} mt={3} container flexDirection="column" alignItems="center">
                <Typography variant="h6" fontSize={20}>
                    Groupe {grp}
                </Typography>

                {Groupe && (
                    <Typography variant="h6" fontSize={25}>
                        {denomination}
                    </Typography>
                )}
            </Grid>

            <Grid item xs={12} mt={8}>
                <SectionTitle>Détails sur le paiement</SectionTitle>

                <PaddedSection>
                    <SubSectionTitle>
                        <Box display="flex" alignItems="center">
                            Solde
                            {Ec1711 && (
                                <Tooltip
                                    title={
                                        <>
                                            <Typography variant="body2">
                                                Début du solde: {debutinteretsursoldeneg}
                                            </Typography>
                                            <Typography variant="body2">Code msg: {codemsg}</Typography>
                                            <Typography variant="body2">Montant msg 1: {montantmsg1}</Typography>
                                        </>
                                    }
                                    placement="right"
                                    arrow
                                    sx={{ ml: 0.8 }}
                                >
                                    <IconButton size="small">
                                        <HelpIcon fontSize="small" color="info" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </SubSectionTitle>
                    <PaddedSection>
                        <LineItem title="Solde reporté" value={soldereporte} />
                        <LineItem title="Solde à reporter" value={soldeareporte} />
                        <LineItem title="Modification du solde" value={modifdesolde} />
                        <LineItem title="Paiement reçu" value={paiementrecu} />
                    </PaddedSection>

                    <SubSectionTitle>Montants bruts</SubSectionTitle>
                    <PaddedSection>
                        {[...(Ec1704 || [])]
                            .sort((a, b) => {
                                return a.compteur - b.compteur;
                            })
                            .map((item, idx) => {
                                const {
                                    // mdgrpid,
                                    // compteur,
                                    // valideur,
                                    codetrans,
                                    montantbrut,
                                    libellecodetrans,
                                } = item;

                                const title = (
                                    <Box display="flex" alignItems="center">
                                        {libellecodetrans}

                                        {SHOW_MONTANTS_BRUT_TOOLTIP && (
                                            <Tooltip
                                                title={
                                                    <>
                                                        <Typography variant="body2">
                                                            Code de transaction: {codetrans}
                                                        </Typography>
                                                    </>
                                                }
                                                placement="right"
                                                arrow
                                                sx={{ ml: 0.8 }}
                                            >
                                                <IconButton size="small">
                                                    <HelpIcon fontSize="small" color="info" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                );
                                const value = montantbrut;

                                return <LineItem key={idx} title={title} value={value} />;
                            })}
                    </PaddedSection>

                    {Ec1707 && Ec1707.length > 0 && (
                        <>
                            <SubSectionTitle>Déductions</SubSectionTitle>
                            <PaddedSection>
                                {[...(Ec1707 || [])]
                                    .sort((a, b) => {
                                        return a.compteur - b.compteur;
                                    })
                                    .map((item, idx) => {
                                        const {
                                            mdgrpid,
                                            compteur,
                                            valideur,
                                            codededuction,
                                            montantdeduction,
                                            libellededuction,
                                            dateenrgded,
                                            nocompteded,
                                        } = item;

                                        const title = `${libellededuction} (codededuction: ${codededuction})`;
                                        const value = montantdeduction;

                                        return <LineItem key={idx} title={title} value={value} />;
                                    })}
                            </PaddedSection>
                        </>
                    )}

                    {Ec1709 && Ec1709.length > 0 && (
                        <>
                            <SubSectionTitle>Transferts de solde</SubSectionTitle>
                            <PaddedSection>
                                {[...(Ec1709 || [])]
                                    .sort((a, b) => {
                                        return a.compteur - b.compteur;
                                    })
                                    .map((item, idx) => {
                                        const {
                                            mdgrpid,
                                            compteur,
                                            valideur,
                                            codetransfert,
                                            classegrptransfert,
                                            nogrptransfert,
                                            montanttransfert,
                                        } = item;

                                        const title = (
                                            <Box display="flex" alignItems="center">
                                                {`${
                                                    codetransfert === 'A'
                                                        ? 'Transfert au groupe'
                                                        : 'Transfert du groupe'
                                                } ${nogrptransfert}`}

                                                <Tooltip
                                                    title={
                                                        <>
                                                            <Typography variant="body2">
                                                                Classe du groupe de transfert: {classegrptransfert}
                                                            </Typography>
                                                        </>
                                                    }
                                                    placement="right"
                                                    arrow
                                                    sx={{ ml: 0.8 }}
                                                >
                                                    <IconButton size="small">
                                                        <HelpIcon fontSize="small" color="info" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        );
                                        const value = montanttransfert;

                                        return <LineItem key={idx} title={title} value={value} />;
                                    })}
                            </PaddedSection>
                        </>
                    )}

                    <SubSectionTitle>Intérêts et avances</SubSectionTitle>
                    <PaddedSection>
                        <LineItem title="Intérêts sur recouv" value={interetsurrecouv} />
                        <LineItem title="Intérêts sur avance" value={interetsuravance} />
                        <LineItem title="Avances remises" value={avancesemises} />
                        <LineItem title="Avances recouv" value={avancesrecouv} />
                        <LineItem title="Intérêts sur solde neg" value={interetsursoldeneg} />
                    </PaddedSection>

                    {Ec1708 && (
                        <>
                            <SubSectionTitle>Cotisation de fédération</SubSectionTitle>
                            <PaddedSection>
                                <LineItem title={`Cotisation à la ${nomfed}`} value={montantcotisation} />
                            </PaddedSection>
                        </>
                    )}

                    {Ec1712 && (
                        <>
                            <SubSectionTitle>Montant net versé</SubSectionTitle>
                            <PaddedSection>
                                <LineItem
                                    title={`Paiement via ${modePaiementText} (No. ${nochequeouvirement})`}
                                    value={montantnetverse}
                                />
                            </PaddedSection>
                        </>
                    )}
                </PaddedSection>
            </Grid>

            {Ec1717ig.length > 0 && (
                <Grid item xs={12} mt={5}>
                    <SectionTitle>Demandes VAC/SAL/RMX</SectionTitle>

                    <Grid item xs={12}>
                        <ForfaitaireComponent Ec1717ig={Ec1717ig} />
                    </Grid>
                </Grid>
            )}

            <Grid item xs={12} mt={5}>
                <SectionTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" color="primary">
                            Factures réglées avec montant final égal au montant préliminaire
                        </Typography>
                        <ColoredNumber
                            value={Ec1720.reduce((prevValue, curr) => {
                                return (prevValue += curr.montantpaye);
                            }, 0)}
                            variant="h6"
                        />
                    </Stack>
                </SectionTitle>

                <Grid item xs={12}>
                    <DataGridPro
                        getRowId={(row) => uuidv4()}
                        rows={Ec1720 || []}
                        columns={columns1720}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                        autoHeight
                        disableSelectionOnClick
                        // density="compact"
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} mt={5}>
                <SectionTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" color="primary">
                            Factures dont le montant final diffère du montant préliminaire
                        </Typography>
                        <ColoredNumber
                            value={Ec1721.reduce((prevValue, curr) => {
                                return (prevValue += curr.montantpaye);
                            }, 0)}
                            variant="h6"
                        />
                    </Stack>
                </SectionTitle>

                <Grid item xs={12}>
                    <DataGridPro
                        getRowId={(row) => uuidv4()}
                        rows={Ec1721 || []}
                        columns={columns1721}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                        autoHeight
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} mt={5}>
                <SectionTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" color="primary">
                            Factures dont une ou plusieurs lignes ont été modifiées
                        </Typography>
                        <ColoredNumber
                            value={Ec1722.reduce((prevValue, curr) => {
                                return (prevValue += curr.montantpaye);
                            }, 0)}
                            variant="h6"
                        />
                    </Stack>
                </SectionTitle>

                <Grid item xs={12}>
                    <DataGridPro
                        getRowId={(row) => uuidv4()}
                        rows={Ec1722 || []}
                        columns={columns1722}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                        autoHeight
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} mt={5}>
                <SectionTitle>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6" color="primary">
                            Montants remboursés à une personne assurée
                        </Typography>
                        <ColoredNumber
                            value={Ec1725.reduce((prevValue, curr) => {
                                return (prevValue += curr.montantpaye);
                            }, 0)}
                            variant="h6"
                        />
                    </Stack>
                </SectionTitle>

                <Grid item xs={12}>
                    <DataGridPro
                        getRowId={(row) => uuidv4()}
                        rows={Ec1725 || []}
                        columns={columns1725}
                        pagination
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50, 75, 100]}
                        autoHeight
                        disableSelectionOnClick
                    />
                </Grid>
            </Grid>

            {/* <Grid item xs={12} container justifyContent="flex-end" mt={6}>
                <Button variant="contained" size="small" endIcon={<ArrowForwardIcon />}>
                    Détails sur ces demandes
                </Button>
            </Grid> */}

            <Grid item xs={12} mt={5}>
                <SectionTitle>Cumulatifs</SectionTitle>

                <PaddedSection>
                    <SubSectionTitle>Cumulatifs de fédération</SubSectionTitle>
                    <PaddedSection>
                        {[...(Ec1716 || [])]
                            .sort((a, b) => {
                                return a.compteur - b.compteur;
                            })
                            .map((item, idx) => {
                                const {
                                    // mdgrpid,
                                    //  compteur,
                                    //   valideur,
                                    nomfederation,
                                    montantcumulatif,
                                } = item;

                                const title = `Montant cumulatif de la ${nomfederation}`;
                                const value = montantcumulatif;

                                return <LineItem key={idx} title={title} value={value} />;
                            })}
                    </PaddedSection>
                </PaddedSection>
            </Grid>

            <Grid item xs={12} mt={5}>
                <SectionTitle>Messages de la RAMQ</SectionTitle>

                {msg && (
                    <>
                        <Typography>{msg}</Typography>
                        <br />
                    </>
                )}

                {msg_permanent && (
                    <>
                        <Typography>{msg_permanent}</Typography>
                        <br />
                    </>
                )}
            </Grid>
        </Grid>
        // </Paper>
    );
};

const ForfaitaireComponent = ({ Ec1717ig }) => {
    return (
        <PaddedSection>
            {[...(Ec1717ig || [])]
                .sort((a, b) => a.compteur - b.compteur)
                .map((ec1717ig, idx) => {
                    const {
                        mdgrpid,
                        ec_id,
                        typereglement,
                        compteur,
                        valideur,
                        masterid,
                        nodemande,
                        debutperiode,
                        finperiode,
                        datereception,
                        nodemref1,
                        nodemref2,
                        codetrans,
                        libellecodetrans,
                        codemsgexpl1,
                        codemsgexpl2,
                        codemsgexpl3,
                        montantinteret,
                        noattestation,
                    } = ec1717ig;
                    const {
                        Ec1717e2,
                        Ec1717f2,
                        Ec1717m2,
                        Ec1717r2,
                        Ec1717r3,
                        Ec1717v2,
                        Ec1717v3,
                        Ec1717v4,
                        Ec1717x2,
                        Ec1717x3,
                    } = ec1717ig || {};

                    const ec1717igText = `Du ${moment(debutperiode).format('LL')} au ${moment(finperiode).format(
                        'LL',
                    )}`;

                    return (
                        <Box key={idx}>
                            <SubSectionTitle>
                                <Box display="flex" alignItems="center">
                                    {ec1717igText}
                                    <Tooltip
                                        title={
                                            <>
                                                <Typography variant="body2">mdgrpid: {mdgrpid}</Typography>
                                                <Typography variant="body2">ec_id: {ec_id}</Typography>
                                                <Typography variant="body2">typereglement: {typereglement}</Typography>
                                                <Typography variant="body2">compteur: {compteur}</Typography>
                                                <Typography variant="body2">valideur: {valideur}</Typography>
                                                <Typography variant="body2">masterid: {masterid}</Typography>
                                                <Typography variant="body2">nodemande: {nodemande}</Typography>
                                                <Typography variant="body2">debutperiode: {debutperiode}</Typography>
                                                <Typography variant="body2">finperiode: {finperiode}</Typography>
                                                <Typography variant="body2">datereception: {datereception}</Typography>
                                                <Typography variant="body2">nodemref1: {nodemref1}</Typography>
                                                <Typography variant="body2">nodemref2: {nodemref2}</Typography>
                                                <Typography variant="body2">codetrans: {codetrans}</Typography>
                                                <Typography variant="body2">
                                                    libellecodetrans: {libellecodetrans}
                                                </Typography>
                                                <Typography variant="body2">codemsgexpl1: {codemsgexpl1}</Typography>
                                                <Typography variant="body2">codemsgexpl2: {codemsgexpl2}</Typography>
                                                <Typography variant="body2">codemsgexpl3: {codemsgexpl3}</Typography>
                                                <Typography variant="body2">
                                                    montantinteret: {montantinteret}
                                                </Typography>
                                                <Typography variant="body2">noattestation: {noattestation}</Typography>
                                            </>
                                        }
                                        placement="right"
                                        arrow
                                        sx={{ ml: 0.8 }}
                                    >
                                        <IconButton size="small">
                                            <HelpIcon fontSize="small" color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </SubSectionTitle>

                            <Box sx={{ my: 2 }}></Box>

                            <PaddedSection>
                                <PaddedSection>
                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717e2 && Ec1717e2.length > 0)) && (
                                        <>
                                            <SubSectionTitle>
                                                <DemandeTypeSubtitle
                                                    title="Assurance responsabilité"
                                                    type="EC1717 - E2"
                                                />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717e2 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            debutperiode,
                                                            finperiode,
                                                            noentente,
                                                            typeassresp,
                                                            libelletypeassresp,
                                                            montantpaye,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            codemsgexpl3,
                                                            montantcumule,
                                                            montantcumuleant,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${libelletypeassresp}`}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                debutperiode: {debutperiode}
                                                                            </Typography>
                                                                            <Typography>
                                                                                finperiode: {finperiode}
                                                                            </Typography>
                                                                            <Typography>
                                                                                noentente: {noentente}
                                                                            </Typography>
                                                                            <Typography>
                                                                                typeassresp: {typeassresp}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libelletypeassresp: {libelletypeassresp}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl3: {codemsgexpl3}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcumule: {montantcumule}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcumuleant: {montantcumuleant}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717f2 && Ec1717f2.length > 0)) && (
                                        <>
                                            <SubSectionTitle>
                                                <DemandeTypeSubtitle title="Forfaitaire" type="EC1717 - F2" />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717f2 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            libelleforfaitaire,
                                                            taux,
                                                            montantpaye,
                                                        } = item;

                                                        const title = `${libelleforfaitaire} (Taux: ${taux})`;
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717m2 && Ec1717m2.length > 0)) && (
                                        <>
                                            <SubSectionTitle>
                                                <DemandeTypeSubtitle title="Mesures incitatives" type="EC1717 - M2" />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717m2 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            typemesinc,
                                                            libelleelmmesinc,
                                                            elmmesinc,
                                                            libelleelmmesinc2,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            montantpaye,
                                                            nombrejours,
                                                            montantconsidere,
                                                            nombrejourscons,
                                                            montantconsant,
                                                            nombrejoursconsant,
                                                            reference,
                                                            debutmesureinc,
                                                            finmesureinc,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${libelleelmmesinc}`}
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                typemesinc: {typemesinc}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libelleelmmesinc: {libelleelmmesinc}
                                                                            </Typography>
                                                                            <Typography>
                                                                                elmmesinc: {elmmesinc}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libelleelmmesinc2: {libelleelmmesinc2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombrejours: {nombrejours}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsidere: {montantconsidere}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombrejourscons: {nombrejourscons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsant: {montantconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombrejoursconsant: {nombrejoursconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                            <Typography>
                                                                                debutmesureinc: {debutmesureinc}
                                                                            </Typography>
                                                                            <Typography>
                                                                                finmesureinc: {finmesureinc}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717r2 && Ec1717r2.length > 0)) && (
                                        <>
                                            <SubSectionTitle>
                                                <DemandeTypeSubtitle title="Mesures particulières" type="EC1717 - R2" />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717r2 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            typemesurepart,
                                                            libelletypemesurepart,
                                                            typeappl,
                                                            montantpaye,
                                                            montantcons,
                                                            montantconsant,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {libelletypemesurepart}
                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                typemesurepart: {typemesurepart}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libelletypemesurepart:{' '}
                                                                                {libelletypemesurepart}
                                                                            </Typography>
                                                                            <Typography>
                                                                                typeappl: {typeappl}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcons: {montantcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsant: {montantconsant}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717r3 && Ec1717r3.length > 0)) && (
                                        <>
                                            <SubSectionTitle>
                                                <DemandeTypeSubtitle
                                                    title="Mesures particulières : Détails"
                                                    type="EC1717 - R3"
                                                />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717r3 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            dateoccurrence,
                                                            montantpaye,
                                                            montantcons,
                                                            montantconsant,
                                                            reference,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {dateoccurrence}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                dateoccurrence: {dateoccurrence}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcons: {montantcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsant: {montantconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717v2 && Ec1717v2.length > 0)) && (
                                        <>
                                            <SubSectionTitle
                                                value={[...(Ec1717v2 || [])].reduce(
                                                    (prevValue, curr) => prevValue + curr.montantpaye,
                                                    0,
                                                )}
                                            >
                                                <DemandeTypeSubtitle title="Vacation : Activité" type="EC1717 - V2" />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717v2 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            codenatserv,
                                                            libellecodenatserv,
                                                            codeemploitemps,
                                                            libellecodeemploitemps,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            codemsgexpl3,
                                                            montantpaye,
                                                            nombreheurespayees,
                                                            montantcons,
                                                            nombreheurescons,
                                                            montantconsant,
                                                            nombreheuresconsant,
                                                            reference,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${libellecodeemploitemps} (${nombreheurespayees} H)`}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codenatserv: {codenatserv}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libellecodenatserv: {libellecodenatserv}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codeemploitemps: {codeemploitemps}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libellecodeemploitemps:{' '}
                                                                                {libellecodeemploitemps}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl3: {codemsgexpl3}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreheurespayees: {nombreheurespayees}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcons: {montantcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreheurescons: {nombreheurescons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsant: {montantconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreheuresconsant:{' '}
                                                                                {nombreheuresconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717v3 && Ec1717v3.length > 0)) && (
                                        <>
                                            <SubSectionTitle
                                                value={[...(Ec1717v3 || [])].reduce(
                                                    (prevValue, curr) => prevValue + curr.montantpaye,
                                                    0,
                                                )}
                                            >
                                                <DemandeTypeSubtitle
                                                    title="Vacation : Temps de déplacement"
                                                    type="EC1717 - V3"
                                                />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717v3 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            codemsgexpl3,
                                                            montantpaye,
                                                            nombreheurespayees,
                                                            montantcons,
                                                            nombreheurescons,
                                                            montantconsant,
                                                            nombreheuresconsant,
                                                            reference,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${nombreheurespayees} H`}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl3: {codemsgexpl3}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreheurespayees: {nombreheurespayees}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcons: {montantcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreheurescons: {nombreheurescons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsant: {montantconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreheuresconsant:{' '}
                                                                                {nombreheuresconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717v4 && Ec1717v4.length > 0)) && (
                                        <>
                                            <SubSectionTitle
                                                value={[...(Ec1717v4 || [])].reduce(
                                                    (prevValue, curr) => prevValue + curr.montantpaye,
                                                    0,
                                                )}
                                            >
                                                <DemandeTypeSubtitle
                                                    title="Vacation : Coûts de transport"
                                                    type="EC1717 - V4"
                                                />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717v4 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            codemsgexpl3,
                                                            montantpaye,
                                                            nombrekmspayes,
                                                            montantcons,
                                                            nombrekmscons,
                                                            montantconsant,
                                                            nombrekmsconsant,
                                                            reference,
                                                            refocctempsdepl,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${nombrekmspayes} KM`}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl3: {codemsgexpl3}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombrekmspayes: {nombrekmspayes}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantcons: {montantcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombrekmscons: {nombrekmscons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantconsant: {montantconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombrekmsconsant: {nombrekmsconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                            <Typography>
                                                                                refocctempsdepl: {refocctempsdepl}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717x2 && Ec1717x2.length > 0)) && (
                                        <>
                                            <SubSectionTitle
                                                value={[...(Ec1717x2 || [])].reduce(
                                                    (prevValue, curr) => prevValue + curr.montantpaye,
                                                    0,
                                                )}
                                            >
                                                <DemandeTypeSubtitle
                                                    title="Rémunération générale : Activité"
                                                    type="EC1717 - X2"
                                                />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717x2 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            codenatserv,
                                                            libellecodenatserv,
                                                            codeemploitemps,
                                                            libellecodeemploitemps,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            codemsgexpl3,
                                                            montantpaye,
                                                            typeunitpayees,
                                                            nombreunitpayees,
                                                            nombreunitcons,
                                                            nombreunitconsant,
                                                            reference,
                                                            sansobjet,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${libellecodeemploitemps} (${nombreunitpayees} ${typeunitpayees})`}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codenatserv: {codenatserv}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libellecodenatserv: {libellecodenatserv}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codeemploitemps: {codeemploitemps}
                                                                            </Typography>
                                                                            <Typography>
                                                                                libellecodeemploitemps:{' '}
                                                                                {libellecodeemploitemps}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl3: {codemsgexpl3}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                typeunitpayees: {typeunitpayees}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreunitpayees: {nombreunitpayees}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreunitcons: {nombreunitcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreunitconsant: {nombreunitconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                            <Typography>
                                                                                sansobjet: {sansobjet}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}

                                    {(SHOW_SECTION_IF_EMPTY || (Ec1717x3 && Ec1717x3.length > 0)) && (
                                        <>
                                            <SubSectionTitle
                                                value={[...(Ec1717x3 || [])].reduce(
                                                    (prevValue, curr) => prevValue + curr.montantpaye,
                                                    0,
                                                )}
                                            >
                                                <DemandeTypeSubtitle
                                                    title="Rémunération générale : Per diem"
                                                    type="EC1717 - X3"
                                                />
                                            </SubSectionTitle>

                                            <PaddedSection>
                                                {[...(Ec1717x3 || [])]
                                                    .sort((a, b) => {
                                                        return a.compteur - b.compteur;
                                                    })
                                                    .map((item, idx) => {
                                                        const {
                                                            masterid,
                                                            compteur,
                                                            valideur,
                                                            mdgrpid,
                                                            nodemande,
                                                            codemsgexpl1,
                                                            codemsgexpl2,
                                                            codemsgexpl3,
                                                            montantpaye,
                                                            typeunitpayees,
                                                            nombreunitpayees,
                                                            nombreunitcons,
                                                            nombreunitconsant,
                                                            reference,
                                                        } = item;

                                                        const title = (
                                                            <Box display="flex" alignItems="center">
                                                                {`${nombreunitpayees} ${typeunitpayees}`}

                                                                <Tooltip
                                                                    title={
                                                                        <>
                                                                            <Typography>
                                                                                No demande: {nodemande}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl1: {codemsgexpl1}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl2: {codemsgexpl2}
                                                                            </Typography>
                                                                            <Typography>
                                                                                codemsgexpl3: {codemsgexpl3}
                                                                            </Typography>
                                                                            <Typography>
                                                                                montantpaye: {montantpaye}
                                                                            </Typography>
                                                                            <Typography>
                                                                                typeunitpayees: {typeunitpayees}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreunitpayees: {nombreunitpayees}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreunitcons: {nombreunitcons}
                                                                            </Typography>
                                                                            <Typography>
                                                                                nombreunitconsant: {nombreunitconsant}
                                                                            </Typography>
                                                                            <Typography>
                                                                                reference: {reference}
                                                                            </Typography>
                                                                        </>
                                                                    }
                                                                    placement="right"
                                                                    arrow
                                                                    sx={{ ml: 0.8 }}
                                                                >
                                                                    <IconButton size="small">
                                                                        <HelpIcon fontSize="small" color="info" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        );
                                                        const value = montantpaye;

                                                        return <LineItem key={idx} title={title} value={value} />;
                                                    })}
                                            </PaddedSection>
                                        </>
                                    )}
                                </PaddedSection>
                            </PaddedSection>
                        </Box>
                    );
                })}
        </PaddedSection>
    );
};

const SectionTitle = ({ children }) => {
    return (
        <>
            <Typography variant="h6" color="primary">
                {children}
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
        </>
    );
};

const SubSectionTitle = ({ children, value = null }) => {
    let valueFormatted: string = '';

    if (!value && value !== 0) {
        valueFormatted = '';
    } else {
        valueFormatted = formatNumber(value);
    }

    return (
        <Grid item xs={12} container justifyContent="space-between" alignItems="center" mb={1}>
            <Typography variant="subtitle1" fontWeight="bold">
                {children}
            </Typography>

            {valueFormatted && (
                <Typography variant="subtitle1" fontWeight="bold">
                    {valueFormatted}$
                </Typography>
            )}
        </Grid>
    );
};

const PaddedSection = ({ children }) => {
    return (
        <Grid item xs={12} pl={3} mb={3}>
            {children}
        </Grid>
    );
};

const LineItem = ({ title, value }: { title: string | ReactNode; value: number }) => {
    // let valueFormatted: string = '';

    // if (!value && value !== 0) {
    //     valueFormatted = '0.00';
    // } else {
    //     valueFormatted = formatNumber(value);
    // }

    return (
        <>
            <Grid item xs={12} container justifyContent="space-between">
                <Typography variant="body1">{title}</Typography>

                <ColoredNumber value={value} variant="body1" sx={{ fontWeight: 'bold' }} />
                {/* <Typography variant="body1" fontWeight="bold">
                    {valueFormatted}$
                </Typography> */}
            </Grid>
            <Divider sx={{ mt: 0.5, mb: 1 }} />
        </>
    );
};

const DemandeTypeSubtitle = ({ title, type }: { title: string; type: string }) => {
    return (
        <Box display="flex" alignItems="center">
            <Typography
                variant="subtitle1"
                // fontWeight="bold"
                sx={(theme) => ({
                    color: theme.palette.warning.main,
                })}
            >
                {title}
            </Typography>

            {SHOW_DEMANDE_TYPE_TOOLTIP && (
                <Tooltip title={type} placement="right" arrow sx={{ ml: 0.8 }}>
                    <IconButton size="small">
                        <HelpIcon fontSize="small" color="warning" />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};
