import { useState } from 'react';
import FileSaver from 'file-saver';
import { useForm } from 'react-hook-form';

// MUI Components
import { Grid, Typography, Divider, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

// Components
import { AutocompleteControl, CheckboxControl, DateControl } from 'components/inputs';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_MD_GRPS } from '../apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants

export default function RapportRevenusForm() {
    // Apollo queries
    const { loading: mdGrpsLoading, error: mdGrpsError, data: mdGrpsData } = useQuery(GET_MD_GRPS);
    const mdGrps = mdGrpsData?.md?.MdGrps || [];

    // Form
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {
            md_grp_id: null,
            start_date: null,
            end_date: null,
        },
    });

    // Report generation
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const onSubmit = async (data) => {
        setRequestPending(true);

        const { md_grp_id, start_date, end_date } = data;

        const mdGrp = mdGrps.find((mdGrp) => mdGrp.id == md_grp_id);

        try {
            const res = await Api.get('storage/generate-revenus-report', {
                responseType: 'blob',
                timeout: 1000 * 20, // 20 seconds
                params: data,
            });

            const filename = `revenus-groupe-${mdGrp.grp}-${start_date}-au-${end_date}.pdf`;
            FileSaver.saveAs(res.data, filename);
        } catch (e) {
        } finally {
            setRequestPending(false);
        }
    };

    const inputGridXl = 2;
    const inputGridLg = 4;
    const inputGridMd = 4;
    const inputGridSm = 6;
    const inputGridXs = 12;

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Grid container columnSpacing={2} rowSpacing={1}>
                <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                        Rapport de revenus
                    </Typography>
                </Grid>
                <Grid item xs container spacing={1} flexWrap="nowrap">
                    <Grid item>
                        <Chip
                            label="Impôts"
                            size="small"
                            variant="filled"
                            sx={(theme) => ({ backgroundColor: theme.palette.pink1.main })}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Divider sx={{ mt: 1.25, mb: 2 }} />

            <Grid container spacing={3} pt={1}>
                <Grid item xs={12} mb={2}>
                    <Typography
                        variant="body1"
                        // sx={(theme) => ({
                        //     color: theme.palette.primary.main,
                        // })}
                    >
                        Pour générer le rapport, veuillez sélectionner le groupe, la date de début et la date de fin
                        puis cliquer sur le bouton vert.
                    </Typography>
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <AutocompleteControl
                        name={`md_grp_id`}
                        control={control as any}
                        rules={{ required: true }}
                        autocompleteOptions={{
                            options: mdGrps,
                            valuePropertyName: 'id',
                            loading: mdGrpsLoading,
                            getOptionLabel: (option: any) => {
                                const { grp, inc_legal_name } = option;

                                if (option === getValues(`md_grp_id`)) {
                                    const selected = mdGrps.find((item) => item.id === option);

                                    return selected ? `${selected.grp}` : '';
                                } else {
                                    return `${grp}`;
                                }
                            },
                            isOptionEqualToValue: (option: any, value) => option.id === value,
                        }}
                        textFieldOptions={{ label: 'Groupe' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <DateControl
                        name={`start_date`}
                        control={control as any}
                        rules={{ required: true }}
                        options={{ label: 'Date de début' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <DateControl
                        name={`end_date`}
                        control={control as any}
                        rules={{ required: true }}
                        options={{ label: 'Date de fin' }}
                    />
                </Grid>

                <Grid
                    item
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            xs: 'flex-end',
                            md: 'flex-start',
                        },
                        flex: {
                            xs: 1,
                        },
                    }}
                >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        // size="small"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<FileDownloadIcon />}
                        loading={requestPending}
                        loadingPosition="end"
                    >
                        Générer le rapport
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
}
