import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme, lighten } from '@mui/material/styles';

// MUI Components
import { Box, Chip, Grid, Paper, Typography } from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import {} from '@mui/x-data-grid-pro';

// MUI Icons
import {} from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import { InteractiveEc } from 'components/InteractiveEc';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_EC_MD_GRP } from './apollo-queries';

// Redux

// Utils

// Constants

interface InteractiveEcTabPanelProps {
    tabIndexAsString: string;
    ecMdGrpId: number;
}

export default function InteractiveEcTabPanel({ tabIndexAsString, ecMdGrpId }: InteractiveEcTabPanelProps) {
    // Apollo queries
    const {
        loading: ecMdGrpLoading,
        error: ecMdGrpError,
        data: ecMdGrpData,
    } = useQuery(GET_EC_MD_GRP, {
        variables: { id: ecMdGrpId },
        skip: !ecMdGrpId,
    });

    const ecMdGrp = ecMdGrpData?.ecMdGrp;

    return (
        <TabPanel value={tabIndexAsString}>
            <Grid container spacing={4} alignItems="flex-start">
                {ecMdGrpLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: '300px', width: '100%' }}
                    >
                        <Loading />
                    </Box>
                ) : (
                    <InteractiveEc ecMdGrp={ecMdGrp} />
                )}
            </Grid>
        </TabPanel>
    );
}
