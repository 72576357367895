import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GeneralState = {
    isThemeDark: boolean;
};

const initialState: GeneralState = {
    isThemeDark: false,
};

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setIsThemeDark(state, action: PayloadAction<GeneralState['isThemeDark']>) {
            state.isThemeDark = action.payload;
        },
    },
});

export const { setIsThemeDark } = generalSlice.actions;

export default generalSlice.reducer;
