import React from 'react';
import { TextField } from '@mui/material';
import Decimal from 'decimal.js';
import { useState, useEffect, useCallback } from 'react';
import { Control, FormState, useController } from 'react-hook-form';
import NumberFormat from 'react-number-format';

interface NumberControlProps {
    name: string;
    control: Control<any, any>;
    errors?: FormState<any>['errors'];
    options?: { label?: string; suffix?: string; thousandSeparator?: string; format?: string; mask?: string };
    money?: boolean;
    percentage?: boolean;
    phone?: boolean;
    decimals?: number;
    disabled?: boolean;
    nullable?: boolean;
}

export function NumberControl({
    name,
    control,
    errors,
    options = {},
    money = false,
    percentage = false,
    phone = false,
    decimals = undefined,
    disabled,
    nullable = false,
}: NumberControlProps) {
    let { label, suffix, thousandSeparator, format, mask } = options;

    if (money) {
        decimals = decimals === undefined ? 2 : decimals;
        thousandSeparator = thousandSeparator === undefined ? ' ' : thousandSeparator;
        suffix = suffix === undefined ? '$' : suffix;
    }

    if (percentage) {
        decimals = decimals === undefined ? 2 : decimals;
        thousandSeparator = thousandSeparator === undefined ? '' : thousandSeparator;
        suffix = suffix === undefined ? '%' : suffix;
    }

    if (phone) {
        format = format === undefined ? '+1 (###) ###-####' : format;
        mask = mask === undefined ? '_' : mask;
    }

    const {
        field: { onChange, value },
    } = useController({ name, control });
    const [formattedValue, setFormattedValue] = useState(convertDecimals(value, decimals, nullable));

    useEffect(() => {
        setFormattedValue(convertDecimals(value, decimals, nullable));
    }, [value, decimals, nullable]);

    const onValueChange = useCallback(
        ({ value, floatValue, formattedValue }) => {
            if (decimals !== undefined) {
                onChange(new Decimal(value || 0).mul(10 ** decimals).toNumber());
            } else {
                onChange(new Decimal(value || 0).toNumber());
            }

            setFormattedValue(formattedValue);
        },
        [onChange, decimals],
    );

    return (
        <>
            <NumberFormat
                customInput={TextField}
                value={formattedValue}
                onValueChange={onValueChange}
                size="small"
                fullWidth
                thousandSeparator={thousandSeparator}
                suffix={suffix}
                decimalScale={decimals}
                format={format}
                mask={mask}
                fixedDecimalScale={true}
                disabled={disabled}
                {...options}
            />
        </>
    );
}

const convertDecimals = (fullNumber: number, decimals: number | undefined, nullable: boolean) => {
    if (decimals === undefined) {
        if (fullNumber === null || fullNumber === undefined) {
            if (nullable) {
                return null;
            } else {
                return 0;
            }
        } else {
            return fullNumber;
        }
    } else {
        if (fullNumber === null || fullNumber === undefined) {
            if (nullable) {
                return null;
            } else {
                return 0;
            }
        } else {
            return fullNumber / 10 ** decimals;
        }
    }
};
