import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Container,
    useTheme,
    lighten,
    Alert,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useForm, Controller, useController } from 'react-hook-form';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

// Services
import { Auth } from 'services';

// Providers
import { useAuth } from 'providers/AuthProvider/context';

// Router
import { LOGIN_ROUTE, MAIN_PAGE_WHEN_AUTHENTICATED } from 'router';

// Components
import GuestLayout from 'components/GuestLayout';

const FORGOT_PASSWORD_MD = gql`
    mutation ForgotPasswordMd($licence: Int!) {
        forgotPasswordMd(licence: $licence)
    }
`;

export default function ForgotPassword() {
    const navigate = useNavigate();
    const auth = useAuth();
    const theme = useTheme();

    const [passwordLinkSent, setPasswordLinkSent] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            licence: '',
        },
    });

    const [
        forgotPasswordMd,
        { loading: forgotPasswordMdLoading, error: forgotPasswordMdError, data: forgotPasswordMdData },
    ] = useMutation(FORGOT_PASSWORD_MD, {
        onCompleted: ({ forgotPasswordMd }) => {
            setPasswordLinkSent(true);
        },
    });

    if (forgotPasswordMdError) {
        console.log(forgotPasswordMdError);
        console.log(forgotPasswordMdError.graphQLErrors);
        if (forgotPasswordMdError.graphQLErrors.length > 0) {
            console.log(forgotPasswordMdError.graphQLErrors[0].extensions.code);
        }
    }

    const onSubmit = async (data) => {
        forgotPasswordMd({
            variables: {
                licence: parseInt(data.licence) || 0,
            },
        });
    };

    return (
        <GuestLayout>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Box display="flex" justifyContent="center">
                            <img
                                src="/logo-blanc.jpg"
                                alt=""
                                style={{
                                    display: 'block',
                                    height: '70px',
                                }}
                            />
                        </Box>
                    </Grid>

                    {!passwordLinkSent ? (
                        <>
                            <Grid item xs={12} mb={4}>
                                <Typography variant="h6" textAlign="center">
                                    Réinitialiser mon mot de passe
                                </Typography>

                                <Typography variant="body1" textAlign="center" mt={1}>
                                    Veuillez entrer votre licence à 7 chiffres pour continuer.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="licence"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            label="Licence à 7 chiffres"
                                            size="small"
                                            fullWidth
                                            type="number"
                                            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            {forgotPasswordMdError && (
                                <Grid item xs={12} mt={2}>
                                    <Typography color="red" textAlign="center">
                                        {forgotPasswordMdError.message}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12} mt={2}>
                                <Button type="submit" variant="contained" fullWidth disabled={forgotPasswordMdLoading}>
                                    Réinitialiser
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Alert severity="success">
                                Nous avons envoyé un lien temporaire sécurisé à l'adresse courriel mentionnée. Veuillez
                                cliquer sur ce lien pour réinitialiser votre mot de passe.
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12} mt={0} container justifyContent="center">
                        <Button variant="text" component={RouterLink} to={LOGIN_ROUTE}>
                            Retourner à la page de connexion
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </GuestLayout>
    );
}
