import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

export const ID_COLUMN_WIDTH = 100;

export const DATE_COLUMN_WIDTH = 120;
export const DATETIME_COLUMN_WIDTH = 180;
export const BOOLEAN_COLUMN_WIDTH = 75;

export const SMALL_STRING_COLUMN_WIDTH = 100;
export const MEDIUM_STRING_COLUMN_WIDTH = 200;
export const LONG_STRING_COLUMN_WIDTH = 300;

export const DESCRIPTION_COLUMN_WIDTH = 300;
export const ADDRESS_COLUMN_WIDTH = 300;
export const CITY_COLUMN_WIDTH = 120;
export const ZIP_CODE_COLUMN_WIDTH = 120;
export const REGION_NAME_COLUMN_WIDTH = 150;
export const HALF_NAME_COLUMN_WIDTH = 180;
export const ACTIVE_COLUMN_WIDTH = 70;
export const MODULATEUR_COLUMN_WIDTH = 120;
export const DP_TYPE_SERVICE_ABBREV_COLUMN_WIDTH = 100;
export const DENOMINATION_COLUMN_WIDTH = 200;
export const POSTE_COLUMN_WIDTH = 250;

type ColDefaultProps = Partial<GridColDefPro>;

export const ID_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'left',
    align: 'left',
    width: 80,
};

export const DATE_COL_DEFAULTS: ColDefaultProps = {
    width: 100,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATE) : ''),
};

export const DATETIME_COL_DEFAULTS: ColDefaultProps = {
    width: 150,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: ({ value }) => (value ? moment(value).format(SQL_DATETIME) : ''),
};

export const NCE_COL_DEFAULTS: ColDefaultProps = {
    width: 100,
    headerAlign: 'center',
    align: 'center',
};

export const NAM_COL_DEFAULTS: ColDefaultProps = {
    width: 130,
    headerAlign: 'center',
    align: 'center',
};

export const NAM_WITH_ICON_COL_DEFAULTS: ColDefaultProps = {
    width: 160,
    headerAlign: 'center',
    align: 'center',
};

export const POSTE_NAME_COL_DEFAULTS: ColDefaultProps = {
    minWidth: 250,
    flex: 1,
};

export const POSTE_NAME_WITH_ID_COL_DEFAULTS: ColDefaultProps = {
    minWidth: 200,
    flex: 1,
};

/**
 * Numéro de poste OU zip code OU nom ville
 */
export const LOCALITE_COL_DEFAULT: ColDefaultProps = {
    width: 100,
};

export const AMT_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'right',
    align: 'right',
    width: 120,
};

export const PAYMENT_STATUS_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'center',
    align: 'center',
    width: 120,
};

export const TYPE_SERVICE_NAME_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'center',
    align: 'center',
    width: 145,
};

export const LINE_COUNT_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'center',
    align: 'center',
    width: 100,
};

export const RFP_ERRORS_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    minWidth: 315,
};

/**
 * No demande à 4 chiffres des feuilles RPV/RPS/RMX
 *
 * Le range du no est de 1 à 9999
 */
export const NO_DEMANDE_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'center',
    align: 'center',
    width: 60,
};

/**
 * No d'attestation pour feuilles RPV/RPS/RMX
 *
 * Ex: 6286070
 */
export const NO_ATTESTATION_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'center',
    align: 'center',
    width: 100,
};

/**
 * Nom complet du MD
 * Souvent utilisé pour les mds mandataires et les mds signataires des feuilles RPV/RPS/RMX
 *
 * Ex: Dr John Doe
 *
 */
export const MD_FULL_NAME_WITH_TITLE_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'left',
    align: 'left',
    width: 140,
};

/**
 * Departement
 * Utilisé dans les feuilles RMX
 *
 * Ex: Oncologie médicale
 *
 */
export const DEPARTEMENT_COL_DEFAULTS: ColDefaultProps = {
    headerAlign: 'left',
    align: 'left',
    width: 140,
};

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 15, 20, 25, 50, 75, 100];
