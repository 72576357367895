import Auth from 'services/Auth';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import React from 'react';
import { Routes, Route, Link, useLocation, Location } from 'react-router-dom';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    gql,
    ApolloClientOptions,
    NormalizedCacheObject,
    ServerError,
    HttpLink,
    ApolloLink,
} from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
// import { onError } from 'apollo-link-error';
import { onError } from '@apollo/client/link/error';

// Router
import { LOGIN_ROUTE, MAINTENANCE_ROUTE } from 'router';
import { NavigateFunction } from 'react-router';
import { GraphQLError } from 'graphql';
import { from } from '@apollo/client';

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
});

// const authLink = setContext((_, { headers }) => {
//     const token = Auth.getAuthToken();

//     return {
//         headers: {
//             ...headers,
//             authorization: token ? `Bearer ${token}` : '',
//         },
//     };
// });
const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${Auth.getAuthToken()}`,
        },
    }));
    return forward(operation);
});

// const authLink = new ApolloLink((operation, forward) => {
//     operation.setContext((_, { headers }) => {
//         const token = Auth.getAuthToken();

//         return {
//             headers: {
//                 ...headers,
//                 authorization: token ? `Bearer ${token}` : '',
//             },
//         };
//     })
// });

const ERROR_CODE_ACCESS_DENIED = '403';
const ERROR_CODE_SERVICE_UNAVAILABLE = 503;

const handleNetworkError = (networkError: ServerError, navigate: NavigateFunction, location: Location) => {
    const { cause, name, message, stack, statusCode } = networkError;

    console.warn(`[GraphQL network error]: ${networkError}`);
    console.warn('[Cause]: ', cause);
    console.warn('[Name]: ', name);
    console.warn('[Message]: ', message);
    console.warn('[Stack]: ', stack);
    console.warn('[StatusCode]: ', statusCode);

    if (statusCode === ERROR_CODE_SERVICE_UNAVAILABLE) {
        console.log('Service Unavailable, redirecting...');

        console.log(location.pathname);
        if (location.pathname !== MAINTENANCE_ROUTE) {
            navigate(MAINTENANCE_ROUTE);
        }
    }
};

const handleGraphqlErrors = (
    graphqlErrors: readonly GraphQLError[],
    navigate: NavigateFunction,
    location: Location,
) => {
    console.warn(`[GraphQL errors]: ${graphqlErrors}`);

    graphqlErrors.map(({ extensions }) => {
        console.log('[extensions] :', extensions);

        if (extensions && extensions.code === 'UNAUTHENTICATED') {
            console.log('[extensions.code] :', extensions.code);

            Auth.removeAuthToken();

            navigate(LOGIN_ROUTE);

            // modify the operation context with a new token
            //  const oldHeaders = operation.getContext().headers;
            //  operation.setContext({
            //      headers: {
            //          ...oldHeaders,
            //          authorization: getNewToken(),
            //      },
            //  });
            //  // retry the request, returning the new observable
            //  return forward(operation);
        }
    });
};

const errorLink = (navigate: NavigateFunction, location: Location) =>
    onError(({ graphQLErrors, networkError, operation, forward }) => {
        if (networkError) {
            return handleNetworkError(networkError as ServerError, navigate, location);
        }

        if (graphQLErrors) {
            return handleGraphqlErrors(graphQLErrors, navigate, location);
        }

        if (graphQLErrors) {
            console.log('[graphQLErrors]', graphQLErrors);
        }
    });

export default class Graphql {
    static initialize(navigate: NavigateFunction, location: Location): ApolloClient<NormalizedCacheObject> {
        const apolloConfig: ApolloClientOptions<NormalizedCacheObject> = {
            link: from([authLink, errorLink(navigate, location), httpLink]),
            // link: authLink.concat(errorLink(navigate, location)).concat(httpLink) as any,
            cache: new InMemoryCache(),
        };

        return new ApolloClient(apolloConfig);
    }
}
