import { Box, Typography } from '@mui/material';

export const NewsItem1 = () => {
    return (
        <Box>
            <Paragraph>
                La Régie de l'assurance maladie du Québec (RAMQ) a publié 3 infolettres nécessitant votre attention
                depuis le 13 mai dernier.
            </Paragraph>

            <Box mt={3}></Box>

            <Subtitle>Infolettre 038</Subtitle>

            <Paragraph>
                Nous tenons à retenir particulièrement votre attention sur la publication du 17 mai 2022. Cette dernière
                propose un retour aux délais de facturation de 90 jours et de modification d'une facture de 135 jours
                (COVID 19). Par conséquent, nous vous invitons à bien vouloir nous faire parvenir, sans plus tarder,
                votre facturation datant de la mi-février au cours des prochains jours sans quoi la facturation ne
                pourra être recevable par la RAMQ.
            </Paragraph>

            <Paragraph>
                Les détails de la publication se retrouvent dans le lien suivant:
                <br />
                <a href="https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2022/info038-22.pdf">
                    https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2022/info038-22.pdf
                </a>
            </Paragraph>

            <Subtitle>Infolettre 036</Subtitle>

            <Paragraph>
                La deuxième publication annonce en date du 13 mai 2022 la fin des modalités applicables en période de
                pandémie de COVID-19 et dispositions transitoires - LE 269. Celle-ci résume la décision de mettre fin en
                date du 15 mai 2022 à la grande majorité des composantes de la Lettre d'entente 269, à l'exception de
                certaines mesures transitoires jusqu'au 31 décembre 2022, aux dispositions pour les modalités de
                compensation du médecin devant être tenu en isolement et finalement aux services fournis par voie de
                téléconsultation.
            </Paragraph>

            <Paragraph>
                Nous vous invitons à lire le détail du contenu de l'Infolettre se résumant à une page dans le lien
                suivant :
                <br />
                <a href="https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2022/info036-22.pdf">
                    https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2022/info036-22.pdf
                </a>
            </Paragraph>

            <Subtitle>Infolettre 059</Subtitle>

            <Paragraph>
                Dans un dernier temps, un amendement important a été annoncé pour la rémunération des activités
                médico-administratives. Cet amendement nécessite encore à ce jour certaines précisions qui
                s'appliqueront rétroactivement au 1er juin 2022.
            </Paragraph>

            <Paragraph>
                Vous pouvez consulter le détail de cette infolettre dans le lien suivant :
                <br />
                <a href="https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2022/info059-22.pdf">
                    https://www.ramq.gouv.qc.ca/SiteCollectionDocuments/professionnels/infolettres/2022/info059-22.pdf
                </a>
            </Paragraph>

            <Box mt={4}></Box>

            <Paragraph>
                Bref, nous vous invitons à nous soumettre vos demandes de facturation pour nous permettre de planifier
                notre agenda et vous servir.
            </Paragraph>

            <Paragraph>
                Nous vous remercions pour votre confiance et vous transmettons nos meilleures salutations,
            </Paragraph>

            <Typography>
                <i>-- L'équipe de Facturation médicale Paradis</i>
            </Typography>
        </Box>
    );
};

function Subtitle({ children }) {
    return (
        <Typography variant="h6" mt={2} mb={1}>
            {children}
        </Typography>
    );
}

function Paragraph({ children }) {
    return <Typography mb={2}>{children}</Typography>;
}
