import { useQuery } from '@apollo/client';
import {
    Box,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    lighten,
    TableCell,
    Typography,
    IconButton,
    TableBody,
    Button,
    Tooltip,
    SxProps,
    TypographyProps,
} from '@mui/material';
import Loading from 'components/Loading';
import { SQL_DATE } from 'constants/Moment';
import moment, { Moment } from 'moment';
import { fmpHoursToRamqHours, formatNumber } from 'utils';
import { GET_FACTURE_COMPLETE, GET_FACTURE_DAILY_COMPLETE } from './apollo-queries';
import { Help as HelpIcon } from '@mui/icons-material';
import BooleanIcon from 'components/BooleanIcon';

export const DETAIL_PANEL_TH_HEIGHT = 33.92 + 34.42;
export const DETAIL_PANEL_TR_HEIGHT = 41.31;
export const DETAIL_PANEL_PY_HEIGHT = 16 * 2;
export const DETAIL_PANEL_PB_BUFFER_HEIGHT = 20;

interface FactRmxGridDetailPanelProps {
    daily?: boolean;
    date_service?: Moment;
    params: any;
    containerSx?: SxProps;
    tableRowSx?: SxProps;
    tableRowTextColor?: TypographyProps['color'];
}

export default function FactRmxGridDetailPanel({
    daily,
    date_service,
    params: { row },
    containerSx = {},
    tableRowSx = {},
    tableRowTextColor,
}: FactRmxGridDetailPanelProps) {
    const { reqt_id } = row;

    // Apollo queries
    const {
        loading: factureLoading,
        error: factureError,
        data: factureData,
    } = useQuery(daily ? GET_FACTURE_DAILY_COMPLETE : GET_FACTURE_COMPLETE, {
        variables: GET_FACTURE_DAILY_COMPLETE ? { reqt_id, date_service } : { reqt_id },
    });

    // const data = daily ? factureData?.factureDaily : factureData?.facture;
    const data = daily ? factureData?.facture : factureData?.facture;

    if (factureLoading || !data) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" pt={3}>
                <Loading />
            </Box>
        );
    } else {
        const { nce, revision } = data;

        return (
            <Box
                sx={{
                    p: 2,
                    pl: 4,
                    pr: 3,
                    height: '100%',
                    ...containerSx,
                }}
            >
                {/* <Typography sx={{ color: 'primary.main' }} mb={1}>
                    ** Pour afficher la description d'un code d'acte, de contexte ou d'élément de mesure, veuillez
                    positionner votre curseur sur le code en question.
                </Typography> */}

                <TableContainer
                    component={Paper}
                    // sx={{ borderRadius: 0 }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow sx={{ ...tableRowSx }}>
                                <TableCell colSpan={9}>
                                    <Typography
                                        variant="body1"
                                        color={tableRowTextColor || undefined}
                                        fontWeight={500}
                                    >{`#${nce}__${revision}`}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell width={40}>No. ligne</TableCell>

                                <TableCell width={80}>Date</TableCell>

                                <TableCell width={40} align="center">
                                    AM
                                </TableCell>

                                <TableCell width={40} align="center">
                                    PM
                                </TableCell>

                                <TableCell width={80} align="center">
                                    Ref
                                </TableCell>

                                <TableCell width={80} align="center">
                                    Demiperdiem
                                </TableCell>

                                <TableCell width={80}>Contexte 1</TableCell>

                                <TableCell width={80}>Contexte 2</TableCell>

                                <TableCell width={80}>Contexte 3</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {[...data.FactLignesRmx]
                                .sort((a, b) => a.rank - b.rank)
                                .map((factLigne, idx) => {
                                    const {
                                        // nce,
                                        // revision,
                                        lineno,
                                        linedt,
                                        moderem,
                                        am,
                                        pm,
                                        ref,
                                        demiperdiem,

                                        FactLigneRmxDetails,
                                    } = factLigne;

                                    const firstRmxDetailsItem = FactLigneRmxDetails[0] || null;
                                    const secondRmxDetailsItem = FactLigneRmxDetails[1] || null;
                                    const thirdRmxDetailsItem = FactLigneRmxDetails[2] || null;

                                    return (
                                        <TableRow
                                            key={idx}
                                            sx={{
                                                '&:last-child td, &:last-child th': {
                                                    border: 0,
                                                },
                                            }}
                                            hover={true}
                                        >
                                            <TableCell>{lineno}</TableCell>

                                            <TableCell>{linedt ? moment(linedt).format(SQL_DATE) : ''}</TableCell>

                                            <TableCell align="center">
                                                <BooleanIcon value={am} />
                                            </TableCell>

                                            <TableCell align="center">
                                                <BooleanIcon value={pm} />
                                            </TableCell>

                                            <TableCell align="center">{ref}</TableCell>

                                            <TableCell align="center">{demiperdiem}</TableCell>

                                            <TableCell>
                                                {firstRmxDetailsItem && (
                                                    <FactLigneRmxDetailsTooltip
                                                        factLigneRmxDetailsItem={firstRmxDetailsItem}
                                                    />
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                {secondRmxDetailsItem && (
                                                    <FactLigneRmxDetailsTooltip
                                                        factLigneRmxDetailsItem={secondRmxDetailsItem}
                                                    />
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                {thirdRmxDetailsItem && (
                                                    <FactLigneRmxDetailsTooltip
                                                        factLigneRmxDetailsItem={thirdRmxDetailsItem}
                                                    />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }
}

interface FactLigneRmxDetailsTooltipProps {
    factLigneRmxDetailsItem: any;
}

const FactLigneRmxDetailsTooltip = (props: FactLigneRmxDetailsTooltipProps) => {
    const { factLigneRmxDetailsItem } = props;

    const { nce, revision, lineno, rank, ref, code, sd, hr, min } = factLigneRmxDetailsItem;

    return (
        <Tooltip
            title={
                <>
                    <Typography variant="body2">Ref: {ref}</Typography>
                    <Typography variant="body2">Code: {code}</Typography>
                    <Typography variant="body2">Secteur dispensaire: {sd}</Typography>
                    <Typography variant="body2">Heures: {fmpHoursToRamqHours(hr, min)}</Typography>
                </>
            }
            arrow
            placement="top"
            disableInteractive
        >
            <Button variant="text" size="small">
                Info
            </Button>
        </Tooltip>
    );
};
