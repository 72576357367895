import { FieldError } from 'react-hook-form';

export { AutocompleteControl } from './AutocompleteControl';
export { CheckboxControl } from './CheckboxControl';
export { DateControl } from './DateControl';
// export { MoneyControl } from './MoneyControl';
export { NumberControl } from './NumberControl';
export { SelectControl } from './SelectControl';
export { TextControl } from './TextControl';

export const getHelperText = (error: FieldError | undefined) => {
    if (error) {
        if (error.message) return error.message;
        if (error.type === 'required') return 'Champ requis';
    }
};
