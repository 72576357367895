import moment from 'moment';

// MUI Components
import { Box, IconButton, Tooltip, CircularProgress } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { MenuOpen as MenuOpenIcon, Download as DownloadIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import { DATETIME_COL_DEFAULTS, AMT_COL_DEFAULTS, ID_COL_DEFAULTS, DATE_COL_DEFAULTS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber, capitalize } from 'utils';

export const getBillsColumns = (handleDownloadMdBill, mdBillIdsOfCurrentFileDownloads): GridColDefPro[] => [
    {
        field: 'no_paiement',
        headerName: 'No. OR',
        headerAlign: 'center',
        align: 'center',
        width: 80,
        valueGetter: ({ value }) => `OR-${value}`,
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'date_ec',
        headerName: 'Date EC',
        width: 120,
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'date_coupure',
        headerName: 'Date coupure',
        width: 120,
    },
    {
        ...ID_COL_DEFAULTS,
        field: 'bill_no',
        headerName: 'No. Facture',
        minWidth: 100,
        flex: 1,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'subtotal_amt',
        headerName: 'Sous-total',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'tps_amt',
        headerName: 'TPS',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'tvq_amt',
        headerName: 'TVQ',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'total_amt',
        headerName: 'Total',
        valueFormatter: ({ value }) => `${formatNumber(value as number, { cents: true })} $`,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 80,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ row }) => {
            const { id, filename, date_ec, no_paiement } = row;

            const isFileDownloadButtonLoading = mdBillIdsOfCurrentFileDownloads.includes(id);

            const downloadFilename = `Facture-OR-${moment(date_ec).year()}-${no_paiement}`;

            return (
                <Box display="flex" alignItems="center">
                    {isFileDownloadButtonLoading ? (
                        <IconButton size="medium" color="primary" disabled={true}>
                            <CircularProgress size={14} />
                        </IconButton>
                    ) : (
                        filename && (
                            <Tooltip title="Télécharger la facture." placement="left" disableInteractive>
                                <IconButton
                                    size="medium"
                                    color="primary"
                                    onClick={() => {
                                        handleDownloadMdBill(id, downloadFilename);
                                    }}
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )
                    )}
                </Box>
            );
        },
    },
];
