import { gql } from '@apollo/client';
import { DfReportFields } from 'apollo/fragments';

export const GET_DF_REPORT = gql`
    ${DfReportFields}

    query GetDfReport($ec_md_grp_id: Int!) {
        dfReport(ec_md_grp_id: $ec_md_grp_id) {
            ...DfReportFields

            FILE_URL
        }
    }
`;
