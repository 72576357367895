import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink, useSearchParams, useParams } from 'react-router-dom';
import { useTheme, Theme } from '@mui/material/styles';
import moment from 'moment';
import FileSaver from 'file-saver';

// MUI Components
import { Autocomplete, Breadcrumbs, Button, Grid, TextField, Typography } from '@mui/material';

// MUI Grid
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getBillsColumns } from './grid-columns';

// MUI Icons
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

// Components
import Loading from 'components/Loading';
import DataGridLoader from 'components/DataGridLoader';

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_MD_BILLS } from './apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';
import { ROWS_PER_PAGE_OPTIONS } from 'constants/DataGrid';
import { useDebounce } from 'use-debounce';

const breadcrumbs = [
    <Typography key="1" fontSize={15}>
        Compte
    </Typography>,
    <Typography key="1" fontSize={15}>
        Factures
    </Typography>,
];

type RouteParams = 'id';

const DEBOUNCE_DELAY = 500;

export default function BillList() {
    const { id } = useParams<RouteParams>();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // Apollo queries
    const {
        loading: mdBillsLoading,
        error: mdBillsError,
        data: mdBillsData,
    } = useQuery(GET_MD_BILLS, {
        variables: {},
    });

    // Md bill file download
    const [mdBillIdsOfCurrentFileDownloads, setMdBillIdsOfCurrentFileDownloads] = useState<number[]>([]);
    const handleDownloadMdBill = useCallback(
        async (md_bill_id: number, downloadFilename: string) => {
            setMdBillIdsOfCurrentFileDownloads([...mdBillIdsOfCurrentFileDownloads, md_bill_id]);

            try {
                const res = await Api.get('storage/bill', {
                    responseType: 'blob',
                    timeout: 1000 * 20, // 20 seconds
                    params: {
                        md_bill_id: md_bill_id,
                    },
                });

                const filename = `${downloadFilename}.pdf`;
                FileSaver.saveAs(res.data, filename);
            } catch (e) {
            } finally {
                setMdBillIdsOfCurrentFileDownloads(
                    mdBillIdsOfCurrentFileDownloads.filter((item) => item === md_bill_id),
                );
            }
        },
        [setMdBillIdsOfCurrentFileDownloads, mdBillIdsOfCurrentFileDownloads],
    );

    // Columns
    const billsColumns = useMemo(
        () => getBillsColumns(handleDownloadMdBill, mdBillIdsOfCurrentFileDownloads),
        [handleDownloadMdBill, mdBillIdsOfCurrentFileDownloads],
    );
    const [pageSize, setPageSize] = React.useState<number>(15);

    return (
        <>
            <Typography variant="h5" fontWeight="600">
                Factures
            </Typography>
            <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                sx={{ mt: 0.5, mb: { mobile: 2, tablet: 3, desktop: 4 } }}
            >
                {breadcrumbs}
            </Breadcrumbs>

            <DataGridPro
                getRowId={(row) => row.id}
                rows={mdBillsData?.mdBills || []}
                columns={billsColumns}
                pagination
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                autoHeight
                disableSelectionOnClick
                initialState={{
                    pinnedColumns: {
                        left: ['no_paiement'],
                        right: ['actions'],
                    },
                    sorting: {
                        sortModel: [{ field: 'date_ec', sort: 'desc' }],
                    },
                }}
                loading={mdBillsLoading}
                components={{
                    LoadingOverlay: DataGridLoader,
                }}
            />
        </>
    );
}
