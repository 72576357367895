import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate, NavigateFunction } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, Theme, lighten } from '@mui/material/styles';
import moment from 'moment';

// MUI Components
import {
    Chip,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { TabPanel } from '@mui/lab';

// MUI Grid
import {} from '@mui/x-data-grid-pro';

// MUI Icons
import {} from '@mui/icons-material';

// Components

// Apollo
import { useQuery, useLazyQuery } from '@apollo/client';

// Redux

// Utils
import { formatNumber } from 'utils';

// Constants

interface ForfaitTabPanelProps {
    tabIndexAsString: string;
    factures: any[];
}

export default function ForfaitTabPanel({ tabIndexAsString, factures }: ForfaitTabPanelProps) {
    let tables = [...TABLES];

    tables.forEach((table) => {
        table.tableRows.forEach((tableRow) => {
            const { name, desc, codes } = tableRow;

            let totalPrelim = 0;
            let totalFinal = 0;

            factures.forEach((facture) => {
                facture.FactLignes.map((factLigne) => {
                    const { codefact, mntprelim, mntfinal } = factLigne;

                    if (codes.map((code) => parseInt(code)).includes(codefact)) {
                        totalPrelim += mntprelim;
                        totalFinal += mntfinal;
                    }
                });
            }, 0);

            tableRow.totalPrelim = totalPrelim;
            tableRow.totalFinal = totalFinal;
        });
    });

    const columns = [1, 2];

    return (
        <TabPanel value={tabIndexAsString}>
            <Grid container spacing={4} alignItems="flex-start">
                {columns.map((column, idx) => {
                    return (
                        <Grid key={idx} item xs={6} container spacing={4}>
                            {tables
                                .filter((table) => table.column === idx)
                                .map((table, idx) => {
                                    const { tableName, tableRows } = table;

                                    return (
                                        <Grid key={idx} item xs={12}>
                                            <TableContainer
                                                component={Paper}
                                                sx={{ borderBottom: 0, borderColor: 'primary.main' }}
                                                elevation={3}
                                            >
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow
                                                            sx={(theme) => ({
                                                                bgcolor: lighten(theme.palette.primary.main, 0.2),
                                                            })}
                                                        >
                                                            <TableCell
                                                                colSpan={4}
                                                                sx={
                                                                    {
                                                                        // borderColor: 'primary.main',
                                                                        // borderBottomWidth: 2,
                                                                    }
                                                                }
                                                            >
                                                                <Typography
                                                                    variant="h6"
                                                                    // variant="body1"
                                                                    color="white"
                                                                    fontWeight={500}
                                                                >
                                                                    {tableName}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Codes</TableCell>
                                                            <TableCell align="right">Description</TableCell>
                                                            <TableCell align="right">Montant prélim</TableCell>
                                                            <TableCell align="right">Montant final</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {tableRows.map((tableRow, idx) => {
                                                            const { name, desc, codes, totalPrelim, totalFinal } =
                                                                tableRow;

                                                            return (
                                                                <TableRow
                                                                    key={idx}
                                                                    sx={{
                                                                        '&:last-child td, &:last-child th': {
                                                                            border: 0,
                                                                        },
                                                                    }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        {codes.join(', ')}
                                                                    </TableCell>
                                                                    <TableCell align="right">{name}</TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        sx={{
                                                                            color:
                                                                                totalPrelim == 0
                                                                                    ? 'warning.main'
                                                                                    : totalPrelim > 0
                                                                                    ? 'secondary.main'
                                                                                    : 'error.main',
                                                                            fontWeight:
                                                                                totalPrelim > 0 ? '900' : 'normal',
                                                                        }}
                                                                        width={135}
                                                                    >
                                                                        {formatNumber(totalPrelim)} $
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="right"
                                                                        sx={{
                                                                            color:
                                                                                totalFinal == 0
                                                                                    ? 'warning.main'
                                                                                    : totalFinal > 0
                                                                                    ? 'secondary.main'
                                                                                    : 'error.main',
                                                                            fontWeight:
                                                                                totalFinal > 0 ? '900' : 'normal',
                                                                        }}
                                                                        width={135}
                                                                    >
                                                                        {formatNumber(totalFinal)} $
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    );
                })}
            </Grid>
        </TabPanel>
    );
}

const TABLES = [
    {
        tableName: 'Frais de Cabinet',
        column: 0,
        tableRows: [
            {
                name: 'Entre 6 et 11 patients inscrits',
                desc: '',
                codes: ['19928'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: '12 patients et plus inscrits',
                desc: '',
                codes: ['19929'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
    {
        tableName: 'Forfaits Trimestriels DMÉ',
        column: 1,
        tableRows: [
            {
                name: 'Équipement et frais accessoires',
                desc: '',
                codes: ['19976'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Lien Internet et commutateur',
                desc: '',
                codes: ['19977'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'DME GMF ou Hors GMF',
                desc: '',
                codes: ['19978'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'DME GMF-R',
                desc: '',
                codes: ['19979'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
    {
        tableName: 'Forfaits Trimestriels',
        column: 1,
        tableRows: [
            {
                name: 'Forfait trimestriel obstétrique',
                desc: '',
                codes: ['09776'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait trimestriel CHSLD (patients)',
                desc: '',
                codes: ['42138'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait trimestriel CHSLD (md responsable)',
                desc: '',
                codes: ['42139'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
    {
        tableName: 'Forfait HUB & Accès à service de première ligne',
        column: 0,
        tableRows: [
            {
                name: 'Familiarisation',
                desc: '',
                codes: ['19966'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait GAP',
                desc: '',
                codes: ['42251'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait accès en temps opportun',
                desc: '',
                codes: ['42252'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
    {
        tableName: 'Forfaits Mensuels IPS - Facturation en cabinet',
        column: 0,
        tableRows: [
            {
                name: 'IPS',
                desc: '',
                codes: ['19948'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19949'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19950'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
    {
        tableName: 'Forfaits Administratifs',
        column: 0,
        tableRows: [
            {
                name: 'Forfait chef urgence',
                desc: '',
                codes: ['19040'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait chef département',
                desc: '',
                codes: ['19064', '09752', '13043'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait coordonnateur',
                desc: '',
                codes: ['19103'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait responsable GMF',
                desc: '',
                codes: ['19017', '09750'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Activités de fonctionnement en GMF',
                desc: '',
                codes: ['72101'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait médecin responsable GMF',
                desc: '',
                codes: ['19895', '09751'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Forfait médecin responsable GMF-U',
                desc: '',
                codes: ['19909'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
    {
        tableName: 'Forfaits de Supervision',
        column: 1,
        tableRows: [
            {
                name: 'Résident',
                desc: '',
                codes: ['19910'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Résident',
                desc: '',
                codes: ['19911'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Résident',
                desc: '',
                codes: ['19912'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Externe',
                desc: '',
                codes: ['19913'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Externe',
                desc: '',
                codes: ['19914'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Externe',
                desc: '',
                codes: ['19915'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Résident',
                desc: '',
                codes: ['19918'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'Externe',
                desc: '',
                codes: ['19921'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19922'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19923'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19924'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19925'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19926'],
                totalPrelim: 0,
                totalFinal: 0,
            },
            {
                name: 'IPS',
                desc: '',
                codes: ['19927'],
                totalPrelim: 0,
                totalFinal: 0,
            },
        ],
    },
];
