import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { IconButton, Tooltip, Stack, Chip, Button } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';
import ColoredNumber from 'components/ColoredNumber';
import RfpErrorStack from 'components/RfpErrorStack';

// Constants
import {
    ID_COLUMN_WIDTH,
    DATETIME_COLUMN_WIDTH,
    SMALL_STRING_COLUMN_WIDTH,
    MEDIUM_STRING_COLUMN_WIDTH,
    DATE_COLUMN_WIDTH,
    DENOMINATION_COLUMN_WIDTH,
    DATE_COL_DEFAULTS,
    AMT_COL_DEFAULTS,
} from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber } from 'utils';

export const getEc1720Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 100,
    },
    {
        field: 'rev',
        headerName: 'Révision',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'nam',
        headerName: 'NAM',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'dateoccurrence',
        headerName: 'Date service',
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantpaye',
        headerName: 'Montant payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <Tooltip
                        title="La page du détaillé complet d'une réclamation sera disponible au courant des prochaines semaines!"
                        disableInteractive
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            // component={RouterLink}
                            // to={`/facture?nci=${nci}&revision=${rev}`}
                            // target="_blank"
                            onClick={() => null}
                        >
                            <OpenInNewIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];

export const getEc1721Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 100,
    },
    {
        field: 'rev',
        headerName: 'Révision',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'nam',
        headerName: 'NAM',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'dateoccurrence',
        headerName: 'Date service',
    },
    {
        field: 'valeurident',
        headerName: 'Acte',
        width: 90,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value, row }) => {
            const { CodeFact } = row;
            const { description } = CodeFact || {};

            return (
                <Tooltip title={description} arrow placement="top" disableInteractive>
                    <Button variant="text" size="small" color="info">
                        {value}
                    </Button>
                </Tooltip>
            );
        },
    },
    {
        field: 'noligne',
        headerName: 'No ligne',
        width: 90,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantprel',
        headerName: 'Montant prélim.',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantpaye',
        headerName: 'Montant payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'diff',
        headerName: 'Différence',
        valueGetter: ({ row }) => row.montantpaye - row.montantprel,
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'errors',
        headerName: 'Raisons refus',
        flex: 1,
        minWidth: 315,
        renderCell: ({ row }) => <RfpErrorStack data={row} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <Tooltip
                        title="La page du détaillé complet d'une réclamation sera disponible au courant des prochaines semaines!"
                        disableInteractive
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            // component={RouterLink}
                            // to={`/facture?nci=${nci}&revision=${rev}`}
                            // target="_blank"
                            onClick={() => null}
                        >
                            <OpenInNewIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];

export const getEc1722Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 100,
    },
    {
        field: 'rev',
        headerName: 'Révision',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'nam',
        headerName: 'NAM',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'dateoccurrence',
        headerName: 'Date service',
    },
    {
        field: 'valeurident',
        headerName: 'Acte',
        width: 90,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value, row }) => {
            const { CodeFact } = row;
            const { description } = CodeFact || {};

            return (
                <Tooltip title={description} arrow placement="top" disableInteractive>
                    <Button variant="text" size="small" color="info">
                        {value}
                    </Button>
                </Tooltip>
            );
        },
    },
    {
        field: 'noligne',
        headerName: 'No ligne',
        width: 90,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantant',
        headerName: 'Montant antérieur',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantnouv',
        headerName: 'Montant nouveau',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantpaye',
        headerName: 'Montant payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'errors',
        headerName: 'Raisons refus',
        flex: 1,
        minWidth: 315,
        renderCell: ({ row }) => <RfpErrorStack data={row} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <Tooltip
                        title="La page du détaillé complet d'une réclamation sera disponible au courant des prochaines semaines!"
                        disableInteractive
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            // component={RouterLink}
                            // to={`/facture?nci=${nci}&revision=${rev}`}
                            // target="_blank"
                            onClick={() => null}
                        >
                            <OpenInNewIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];

export const getEc1725Columns = (): GridColDefPro[] => [
    {
        field: 'nci',
        headerName: 'NCI',
        width: 100,
    },
    {
        field: 'rev',
        headerName: 'Révision',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'nam',
        headerName: 'NAM',
        width: 80,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...DATE_COL_DEFAULTS,
        field: 'dateoccurrence',
        headerName: 'Date service',
    },
    {
        field: 'valeurident',
        headerName: 'Acte',
        width: 90,
        headerAlign: 'center',
        align: 'center',
        renderCell: ({ value, row }) => {
            const { CodeFact } = row;
            const { description } = CodeFact || {};

            return (
                <Tooltip title={description} arrow placement="top" disableInteractive>
                    <Button variant="text" size="small" color="info">
                        {value}
                    </Button>
                </Tooltip>
            );
        },
    },
    {
        field: 'noligne',
        headerName: 'No ligne',
        width: 90,
        headerAlign: 'center',
        align: 'center',
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantant',
        headerName: 'Montant antérieur',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantnouv',
        headerName: 'Montant nouveau',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        ...AMT_COL_DEFAULTS,
        field: 'montantpaye',
        headerName: 'Montant payé',
        renderCell: ({ value }) => <ColoredNumber value={value} />,
    },
    {
        field: 'errors',
        headerName: 'Raisons refus',
        flex: 1,
        minWidth: 315,
        renderCell: ({ row }) => <RfpErrorStack data={row} />,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
            const { nci, rev } = params.row;

            return (
                <>
                    <Tooltip
                        title="La page du détaillé complet d'une réclamation sera disponible au courant des prochaines semaines!"
                        disableInteractive
                    >
                        <IconButton
                            size="small"
                            color="primary"
                            // component={RouterLink}
                            // to={`/facture?nci=${nci}&revision=${rev}`}
                            // target="_blank"
                            onClick={() => null}
                        >
                            <OpenInNewIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </>
            );
        },
    },
];
