import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { doctorsData, IDoctor } from 'redux/data';

export type DoctorsState = ({} & IDoctor)[];

const initialState: DoctorsState = doctorsData;

const doctorsSlice = createSlice({
    name: 'doctors',
    initialState,
    reducers: {
        addDoctor(state, action: PayloadAction<DoctorsState[0]>) {
            state.push(action.payload);
        },
        updateDoctor(state, action: PayloadAction<DoctorsState[0]>) {
            let existingDoctorIdx = state.findIndex((doctor) => {
                return doctor.id === action.payload.id;
            });
            if (existingDoctorIdx !== undefined) {
                state[existingDoctorIdx] = action.payload;
            }
        },
        deleteDoctor(state, action: PayloadAction<DoctorsState[0]['id']>) {
            return state.filter((doctor) => doctor.id !== action.payload);
        },
    },
});

export const { addDoctor, updateDoctor, deleteDoctor } = doctorsSlice.actions;

export default doctorsSlice.reducer;
