import { useState } from 'react';
import FileSaver from 'file-saver';
import { useForm } from 'react-hook-form';

// MUI Components
import { Grid, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// MUI Grid

// MUI Icons
import { FileDownload as FileDownloadIcon } from '@mui/icons-material';

// Components
import { AutocompleteControl, CheckboxControl, DateControl } from 'components/inputs';

// Apollo
import { useQuery } from '@apollo/client';
import { GET_POSTES } from '../apollo-queries';
import { Api } from 'services';

// Redux

// Utils

// Constants

export default function RapportCalculLoyerForm() {
    // Apollo queries
    const { loading: postesLoading, error: postesError, data: postesData } = useQuery(GET_POSTES);

    // Form
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        formState,
        watch,
        reset,
        getValues,
    } = useForm({
        defaultValues: {
            pst_id: null,
            start_date: null,
            end_date: null,
            exclude_forfait_cab: false,
            exclude_forfait_gap: false,
            exclude_forfait_acces_temps_opportun: false,
        },
    });

    // Report generation
    const [requestPending, setRequestPending] = useState<boolean>(false);
    const onSubmit = async (data) => {
        setRequestPending(true);

        const { pst_id } = data;

        try {
            const res = await Api.get('storage/generate-dynamic-report', {
                responseType: 'blob',
                timeout: 1000 * 20, // 20 seconds
                params: data,
            });

            const filename = `${pst_id}.pdf`;
            FileSaver.saveAs(res.data, filename);
        } catch (e) {
        } finally {
            setRequestPending(false);
        }
    };

    const inputGridXl = 2;
    const inputGridLg = 4;
    const inputGridMd = 4;
    const inputGridSm = 6;
    const inputGridXs = 12;

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <Typography variant="h5" fontWeight="bold">
                Rapport de calcul de loyer
            </Typography>

            <Divider sx={{ mt: 1.25, mb: 2 }} />

            <Grid container spacing={3} pt={1}>
                <Grid item xs={12} mb={2}>
                    <Typography
                        variant="body1"
                        // sx={(theme) => ({
                        //     color: theme.palette.primary.main,
                        // })}
                    >
                        Pour générer le rapport, veuillez sélectionner le poste, la date de début et la date de fin puis
                        cliquer sur le bouton vert. Pour exclure certains forfaits, veuillez cocher les cases à cocher
                        en question.
                    </Typography>

                    <Typography
                        variant="subtitle2"
                        sx={(theme) => ({
                            color: theme.palette.warning.main,
                            mt: 1,
                        })}
                    >
                        ** Les montants DMÉ, HUB, forfaits IPS, supervision résident/externe/IPS, heures GMF, formations
                        continues et frais de déplacement sont exclus du rapport par défaut.
                    </Typography>
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <AutocompleteControl
                        name={`pst_id`}
                        control={control as any}
                        rules={{ required: true }}
                        autocompleteOptions={{
                            options: postesData?.postes || [],
                            valuePropertyName: 'numero',
                            valueGetter: pstInputValueGetter,
                            loading: postesLoading,
                            getOptionLabel: (option: any) => {
                                const { numero, modulateur, description } = option;

                                if (option === getValues(`pst_id`)) {
                                    const selected = (postesData?.postes || []).find(
                                        (item) => pstInputValueGetter(item) === option,
                                    );

                                    return selected
                                        ? `${selected.description} (${selected.numero}-${selected.modulateur})`
                                        : '';
                                } else {
                                    return `${description} (${numero}-${modulateur})`;
                                }
                            },
                            isOptionEqualToValue: (option: any, value) => pstInputValueGetter(option) === value,
                        }}
                        textFieldOptions={{ label: 'Poste' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <DateControl
                        name={`start_date`}
                        control={control as any}
                        rules={{ required: true }}
                        options={{ label: 'Date de début' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <DateControl
                        name={`end_date`}
                        control={control as any}
                        rules={{ required: true }}
                        options={{ label: 'Date de fin' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <CheckboxControl
                        name={`exclude_forfait_cab`}
                        control={control}
                        options={{ label: 'Exclure forfaits cabinet' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <CheckboxControl
                        name={`exclude_forfait_gap`}
                        control={control}
                        options={{ label: 'Exclure forfaits GAP' }}
                    />
                </Grid>

                <Grid item xs={inputGridXs} sm={inputGridSm} md={inputGridMd} lg={inputGridLg} xl={inputGridXl}>
                    <CheckboxControl
                        name={`exclude_forfait_acces_temps_opportun`}
                        control={control}
                        options={{ label: "Exclure forfaits d'accès en temps opportun" }}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} container mt={5}>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        // size="small"
                        color="primary"
                        onClick={handleSubmit(onSubmit)}
                        endIcon={<FileDownloadIcon />}
                        loading={requestPending}
                        loadingPosition="end"
                    >
                        Générer le rapport
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );
}

const pstInputValueGetter = (option) => (option ? `${option.numero}-${option.modulateur}` : null);
