import { gql } from '@apollo/client';
import { FactVacViewFields, FactLigneVacFields, FactLigneVacDetailsFields } from 'apollo/fragments';

export const GET_FACT_VAC = gql`
    ${FactVacViewFields}
    ${FactLigneVacFields}
    ${FactLigneVacDetailsFields}

    query GetFactVac($reqt_id: Int) {
        factVac(reqt_id: $reqt_id) {
            ...FactVacViewFields

            FactLignesVac {
                ...FactLigneVacFields

                FactLigneVacDetails {
                    ...FactLigneVacDetailsFields
                }
            }
        }
    }
`;
