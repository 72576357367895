import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';

// MUI Components
import { Button, ButtonProps, IconButton, Typography } from '@mui/material';

// MUI Grid
import { GridColDef as GridColDefPro } from '@mui/x-data-grid-pro';

// MUI Icons
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

// Components
import BooleanIcon from 'components/BooleanIcon';

// Constants
import { DATE_COL_DEFAULTS } from 'constants/DataGrid';
import { SQL_DATE, SQL_DATETIME } from 'constants/Moment';

// Utils
import { formatNumber } from 'utils';

export const getPaymentsListColumns = (): GridColDefPro[] => [
    {
        field: 'id',
        headerName: 'ID',
        width: 70,
        headerAlign: 'center',
        align: 'center',
    },
    {
        field: 'txn_recorded_at',
        headerName: 'Reçu le',
        valueGetter: ({ value }) => moment(value).format(SQL_DATE),
        ...DATE_COL_DEFAULTS,
    },
    {
        field: 'METHOD_NAME',
        headerName: 'Méthode',
        headerAlign: 'center',
        align: 'center',
        width: 80,
    },
    // {
    //     field: 'ref_no',
    //     headerName: 'No. ref',
    //     headerAlign: 'center',
    //     align: 'center',
    //     width: 80,
    // },
    {
        field: 'amt',
        headerName: 'Montant',
        valueFormatter: ({ value }) => `${formatNumber((value / 100) as number)} $`,
        headerAlign: 'right',
        align: 'right',
        minWidth: 80,
        flex: 1,
    },
];
